import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import {
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  DialogContentText,
} from "@material-ui/core";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import * as firebase from "firebase";
import CreateAccount from "../Forms/AccountForm";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  titleContainer: {
    display: "flex",
  },
  actionContainer: {
    textAlign: "center",
    display: "flex",
  },
};

class UserAccountsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: [{}],
      createUserAccount: false,
      updateUserAccount: false,
      viewUserAccount: false,
      accountToUpdate: {},
      groupData: [],
      deleteConfirmationModal: false,
    };
    this.db = firebase.database();

    this.usersRef = this.db.ref(`users/`);
    this.groupRef = this.db.ref(`group/`);
  }
  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    let dataArr = [];

    this.fetchGroupDataByTableRef(this.groupRef, dataArr);
  };

  fetchGroupDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(this.fetchGroupData(child.key, child.val()));
        }
      });
      this.setState({ groupData: dataArr });
      this.fetchUsersDataByTableRef(this.usersRef, [], dataArr);
    });
  };

  fetchGroupData = (key, data) => {
    return {
      id: key,
      title: data.name,
    };
  };

  fetchUsersDataByTableRef = (tableReference, dataArr, groupData) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val() && child.val().active && child.val().id) {
          dataArr.push(this.fetchUsersData(child.key, child.val(), groupData));
        }
      });
      const dataWithId = dataArr.filter((f) => f.id != "undefined");
      this.setState({ tableValues: dataWithId });
    });
  };

  fetchUsersData = (key, data, groupData) => {
    let groupValue = groupData.find((group) => group.id === data.groupid);
    return {
      id: data.id,
      group: groupValue && groupValue.title,
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      contact: data.phoneNumber,
      actions: (
        <div>
          <div className={this.props.classes.actionContainer}>
            <Button
              onClick={() => {
                this.setState({
                  updateUserAccount: true,
                  accountToUpdate: data,
                });
              }}
              color="warning"
              className="edit"
            >
              edit
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({
                  deleteConfirmationModal: true,
                  accountToUpdate: data,
                });
              }}
              color="danger"
              className="remove"
            >
              remove
            </Button>
          </div>
        </div>
      ),
    };
  };

  removeAccount = async () => {
    const { accountToUpdate } = this.state;
    let userData = { ...accountToUpdate, active: false };
    let userRef = this.db.ref(`users/${userData.id}`);
    try {
      let result = await Promise.all([userRef.set(userData)]);
      this.setState({
        ...this.state,
        open: true,
        enableRefresh: true,
        resultMessage:
          "Success! Your user account was removed from the WAM app.",
        deleteConfirmationModal: false,
      });
      this.fetchList();
    } catch (error) {
      console.log(error);
    }
  };

  handleClose(modal) {
    this.setState({ [modal]: false });
  }

  handleCreateAccount = () => {
    this.setState({ createUserAccount: true });
  };

  onSubmit = () => {
    this.fetchList();
    this.setState({ createUserAccount: false, updateUserAccount: false });
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  render() {
    const { classes } = this.props;
    const {
      updateUserAccount,
      createUserAccount,
      accountToUpdate,
    } = this.state;
    return (
      <React.Fragment>
        {createUserAccount || updateUserAccount ? (
          <CreateAccount
            isUpdating={updateUserAccount}
            onSubmit={this.onSubmit}
            data={accountToUpdate}
          />
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Build />
                  </CardIcon>
                  <div className={classes.titleContainer}>
                    <h4 className={classes.cardIconTitle}>User Accounts</h4>
                    <Button
                      color="primary"
                      onClick={this.handleCreateAccount}
                      className="create"
                    >
                      Create User Account
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.tableValues || []}
                    filterable
                    columns={[
                      {
                        Header: "User Group",
                        accessor: "group",
                        filterMethod: this.search,
                      },
                      {
                        Header: "First Name",
                        accessor: "firstName",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Email Address",
                        accessor: "email",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Contact Number",
                        accessor: "contact",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        <Dialog
          open={this.state.deleteConfirmationModal}
          onClose={() => this.handleClose("deleteConfirmationModal")}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">Alert</DialogTitle>
          <DialogContent style={{ minWidth: "350px" }}>
            Are you sure you want to delete the user account?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.removeAccount} color="primary">
              Yes
            </Button>
            <Button
              onClick={() => this.handleClose("deleteConfirmationModal")}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(UserAccountsList);
