import React from "react";
import Button from "@material-ui/core/Button";
import { DialogTitle, DialogContent, DialogActions, Dialog, DialogContentText } from "@material-ui/core";

class AlertModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClose = () => {
        this.props.handleClose();
    }



    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleClose()} color="primary">{this.props.buttonText}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default AlertModal;
