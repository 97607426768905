import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "react-datepicker";
import MobilePreview from "components/MobilePreview/MobilePreview.jsx";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/icons
import {
  MyLocation,
  Description,
  FreeBreakfastRounded
} from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PlacesInput from "components/PlacesInput/PlacesInput.jsx";
import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import { validImgExtentions } from "../../../constants/Common";

const style = {
  headerText: {
    textDecoration: "underline",
    fontWeight: "bold"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  dateLabel: {
    color: "#AAAAAA !important",
    fontSize: "14px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    marginRight: "10px"
  }
};

class SeriesDetails extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      ...data,
      setDefaultValue: true,
      headquarter: data.headquarter || "",
      headquarterState: this.getInitialStateVal(data.headquarter),
      latitude: data.latitude || 0,
      latitudeState: this.getInitialStateVal(data.latitude),
      longitude: data.longitude || 0,
      longitudeState: this.getInitialStateVal(data.longitude),
      seriesName: data.name || "",
      seriesNameState: this.getInitialStateVal(data.name),
      seriesDescription: data.description || "",
      seriesDescriptionState: this.getInitialStateVal(data.description),
      seriesSponsor: data.sponsor || "",
      seriesSponsorState: this.getInitialStateVal(data.sponsor),
      fromDate: data.fromDate || null,
      fromDateState: this.getInitialStateVal(data.fromDate),
      toDate: data.toDate || null,
      toDateState: this.getInitialStateVal(data.toDate),
      fromDateHelpText: "",
      toDateHelpText: "",
      eventNumbers: data.numberOfEvents,
      eventNumbersState: this.getInitialStateVal(data.numberOfEvents),
      asset: data.asset,
      assetState: this.getInitialStateVal(data.asset)
    };
    this.setHeadquarterState = this.setHeadquarterState.bind(this);
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      !nextProps.editMode &&
      prevState.setDefaultValue &&
      prevState.seriesName === "" &&
      nextProps.data &&
      nextProps.data.seriesName !== undefined &&
      nextProps.data.seriesName !== prevState.seriesName
    ) {
      return {
        setDefaultValue: false,
        seriesName: nextProps.data.seriesName,
        seriesNameState: "success",
        seriesDescription: nextProps.data.seriesDescription,
        seriesDescriptionState: "success",
        seriesSponsor: nextProps.data.seriesSponsor,
        seriesSponsorState: "success"
      };
    }
  };

  getInitialStateVal(stateElement) {
    if (stateElement && stateElement !== "") {
      return "success";
    }
    return "";
  }

  setHeadquarterState(headquarterState) {
    this.setState({
      ...this.state,
      headquarterState: headquarterState
    });
  }

  choosePlace = (location_title, headquarter, latitude, longitude) => {
    this.setState({
      ...this.state,
      location_title: location_title,
      headquarter: headquarter,
      headquarterState: "success",
      latitude: latitude,
      latitudeState: "success",
      longitude: longitude,
      longitudeState: "success"
    });
  };

  isValidated = () => {
    if (
      this.state.seriesDescriptionState === "success" &&
      this.state.seriesNameState === "success" &&
      this.state.toDateState === "success" &&
      this.state.fromDateState === "success" &&
      this.state.seriesSponsorState === "success" &&
      this.state.latitudeState === "success" &&
      this.state.longitudeState === "success" &&
      this.state.eventNumbersState === "success" &&
      this.state.assetState === "success"
    ) {
      return true;
    } else {
      if (this.state.seriesSponsorState !== "success") {
        this.setState({ seriesSponsorState: "error" });
      }
      if (this.state.seriesDescriptionState !== "success") {
        this.setState({ seriesDescriptionState: "error" });
      }
      if (this.state.seriesNameState !== "success") {
        this.setState({ seriesNameState: "error" });
      }
      if (this.state.assetState !== "success") {
        this.setState({ assetState: "error" });
      }
      if (this.state.latitudeState !== "success") {
        this.setState({ latitudeState: "error" });
      }
      if (this.state.longitudeState !== "success") {
        this.setState({ longitudeState: "error" });
      }
      if (this.state.toDateState !== "success") {
        this.setState({
          toDateState: "error",
          toDateHelpText: "Date should be greater than To Date"
        });
      }
      if (this.state.fromDateState !== "success") {
        this.setState({
          fromDateState: "error",
          fromDateHelpText: "Date should be less than From Date"
        });
      }
      if (this.state.eventNumbersState !== "success") {
        this.setState({ eventNumbersState: "error" });
      }
    }
    return false;
  };

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyLatLong(value) {
    const isDecimal = value.match(/^-?\d*(\.\d+)?$/) && value.length > 0;
    return value >= -90.0 && value <= 90 && isDecimal;
  }

  verifyInteger(value) {
    return !isNaN(parseInt(value));
  }

  verifyDate(value, compareDate, lessThen) {
    if (lessThen) {
      return compareDate ? value <= compareDate : true;
    } else {
      return compareDate ? value >= compareDate : true;
    }
  }

  sendState() {
    return this.state;
  }

  change(event, stateName, type, stateNameEqualTo, lessThen, helpText) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "decimal":
        if (this.verifyLatLong(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: Number(event.target.value) });
        break;
      case "integer":
        if (this.verifyInteger(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        // eslint-disable-next-line no-case-declarations
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
          this.setState({ [stateName]: "" });
        } else {
          this.setState({ [stateName]: value });
        }
        break;
      case "date":
        if (this.verifyDate(event.valueOf(), stateNameEqualTo, lessThen)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName + "HelpText"]: ""
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "HelpText"]: helpText
          });
        }
        this.setState({ [stateName]: event.valueOf() });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  setAsset = file => {
    let valid = true;

    if (file) {
      // validate - file max size should be 500 KB
      // if (file.size > 1000 * 1024) {
      //   this.setState({
      //     assetMessage:
      //       "Invalid file. File size is too large, maximum file size should be 1MB"
      //   });
      //   valid = false;
      // } else {
        // validate file type
        var currentExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        var found = validImgExtentions.find(function(element) {
          return element == (currentExtension || "").toLowerCase();
        });
        if (!found) {
          this.setState({ assetMessage: "Invalid file type" });
          valid = false;
        }
      // }
    }
    if (valid) {
      this.setState({ assetState: "success", assetMessage: "Valid file" });
    } else {
      this.setState({ assetState: "error" });
    }
    this.setState({ ...this.state, asset: file });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CardHeader text className={classes.headerText}>
          <h4>Series Information</h4>
        </CardHeader>
        <CardBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
              <CustomInput
                success={this.state.seriesNameState === "success"}
                error={this.state.seriesNameState === "error"}
                labelText={<span>Series Name</span>}
                id="seriesName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.seriesName,
                  inputProps: {
                    maxLength: 50
                  },
                  onChange: event =>
                    this.change(event, "seriesName", "length", 3)
                }}
              />
              <CustomInput
                success={this.state.seriesDescriptionState === "success"}
                error={this.state.seriesDescriptionState === "error"}
                labelText={
                  <span>Series Description(Max of 500 characters)</span>
                }
                id="seriesDescription"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.seriesDescription,
                  inputProps: {
                    maxLength: 500
                  },
                  onChange: event =>
                    this.change(event, "seriesDescription", "length", 3),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <Description className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </GridItem>
            <GridContainer>
              <GridItem xs={8}>
                <CustomInput
                  success={this.state.seriesSponsorState === "success"}
                  error={this.state.seriesSponsorState === "error"}
                  labelText={<span>Series Sponsor(s)</span>}
                  id="seriesSponsor"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.seriesSponsor,
                    inputProps: {
                      maxLength: 50
                    },
                    onChange: event =>
                      this.change(event, "seriesSponsor", "length", 3)
                  }}
                />
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div className={classes.dateLabel}>Date Duration:</div>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      selected={this.state.fromDate}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={event =>
                        this.change(
                          event,
                          "fromDate",
                          "date",
                          this.state.toDate,
                          true,
                          "Date Should be less than To Date"
                        )
                      }
                    />
                  </div>
                  <div style={{ margin: "auto" }}>-</div>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      selected={this.state.toDate}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={event =>
                        this.change(
                          event,
                          "toDate",
                          "date",
                          this.state.fromDate,
                          false,
                          "Date Should be greater than From Date"
                        )
                      }
                    />
                  </div>
                </div>
                <CustomInput
                  success={this.state.eventNumbersState === "success"}
                  error={this.state.eventNumbersState === "error"}
                  labelText={<span>Number of Events</span>}
                  id="eventNumbers"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.eventNumbers,
                    inputProps: {
                      maxLength: 50
                    },
                    onChange: event =>
                      this.change(event, "eventNumbers", "integer", 3)
                  }}
                />
                <PlacesInput
                  classes={classes}
                  defaultAddress={this.state.headquarter}
                  choosePlace={this.choosePlace}
                  setAddressState={this.setHeadquarterState}
                  descriptionState={this.state.headquarterState}
                />
                <CustomInput
                  success={this.state.latitudeState === "success"}
                  error={this.state.latitudeState === "error"}
                  labelText={<span>Latitude</span>}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.latitude,
                    type: "number",
                    onChange: event =>
                      this.change(event, "latitude", "decimal"),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <MyLocation className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  success={this.state.longitudeState === "success"}
                  error={this.state.longitudeState === "error"}
                  labelText={<span>Longitude</span>}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.longitude,
                    type: "number",
                    onChange: event =>
                      this.change(event, "longitude", "decimal"),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <MyLocation className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <PictureUpload
                  defaultAssetUrl={this.state.asset}
                  setAsset={this.setAsset}
                  assetState={this.state.assetState}
                  assetMessage={this.state.assetMessage}
                />
              </GridItem>
              <GridItem xs={3}>
                <MobilePreview
                  previewType={"seriesDetails"}
                  data={this.state}
                />
              </GridItem>
            </GridContainer>
          </div>
        </CardBody>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(SeriesDetails);
