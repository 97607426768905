import React from "react";
import { Redirect } from "react-router-dom";
import { GeoFire } from "geofire";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Calendar from "./Calendar.jsx";
import SeriesDetails from "./SeriesDetails.jsx";
import EventDetails from "./EventDetails.jsx";
import GameMakerDetails from "./GameMakerDetails";

import * as firebase from "firebase";
import { Events } from "../../../constants/Event.js";

class GmeCardWizardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      resultMessage: "Success!",
      enableRefresh: false,
      shouldRefresh: false,
      seriesDetails: {},
      contactInfo: {}
    };
    this.db = firebase.database();
  }

  componentDidMount = async () => {
    var user = firebase.auth().currentUser;
    let sponsorsList = [];
    let data = {};
    let userId = await this.db
      .ref(`users/${user.uid}/mobileUserId`)
      .once("value");
    if (userId.val()) {
      let applications = this.db
        .ref(`event-sponsor-application/${userId.val()}`)
        .once("value");
      if (applications.val()) {
        sponsorsList = Object.values(applications.val());
        sponsorsList.sort((a, b) => {
          return b.lastModified - a.lastModified;
        });
      }
    }
    if (sponsorsList.length) {
      data = sponsorsList[0];
    }
    let seriesDetails = {
      seriesName: data.eventName,
      seriesDescription: data.eventDescription,
      seriesSponsor: data.eventSponsors
    };
    this.setState({
      seriesDetails: seriesDetails,
      contactInfo: data.contactInfo || {}
    });
  };

  uploadGameCard = (finalState, saved = false) => {
    let eventDetails = [];
    finalState.eventDetails.forEach(event => {
      eventDetails.push({
        name: event.title,
        location: event.address,
        latitude: event.lat,
        longitude: event.lng,
        eventOrder: event.eventOrder,
        eventType: event.eventType,
        fromDate: event.startDate,
        toDate: event.endDate,
        eventAction: event.eventAction,
        asset: event.asset
      });
    });
    let gameCardData = {
      status: saved ? "saved" : "pending",
      seriesDetails: {
        name: finalState.seriesDetails.seriesName,
        description: finalState.seriesDetails.seriesDescription,
        sponsor: finalState.seriesDetails.seriesSponsor,
        fromDate: finalState.seriesDetails.fromDate,
        toDate: finalState.seriesDetails.toDate,
        numberOfEvents: finalState.seriesDetails.eventNumbers,
        headquarter: finalState.seriesDetails.headquarter,
        latitude: finalState.seriesDetails.latitude,
        longitude: finalState.seriesDetails.longitude,
        asset: finalState.seriesDetails.asset
      },
      gameMaker: {
        name: finalState.gameMakerDetails.name,
        email: finalState.gameMakerDetails.email,
        phoneNumber: finalState.gameMakerDetails.phoneNumber,
        organization: finalState.gameMakerDetails.organization,
        gameCardEmail: finalState.gameMakerDetails.gameCardEmail
      }
    };
    gameCardData.eventDetails = eventDetails;
    this.uploadGameCardToDatabase(gameCardData, finalState.eventDetails);
  };

  uploadGameCardToDatabase = async (gameCard, eventDetails) => {
    let gameCardRef;
    if (this.props.editMode) {
      gameCardRef = this.db.ref(`game-cards/${this.props.data.id}`);
      gameCard.id = this.props.data.id;
    } else {
      gameCardRef = this.db.ref(`game-cards/`).push();
      gameCard.id = gameCardRef.key;
    }
    if (typeof gameCard.seriesDetails.asset !== "string") {
      let downloadUrl = await this.uploadPhotoToStorage(
        gameCard.seriesDetails.asset,
        gameCard.id
      );
      if (!downloadUrl) {
        this.setState({
          ...this.state,
          open: true,
          resultMessage: "Error uploading photo"
        });
        return;
      }
      gameCard.seriesDetails.asset = downloadUrl;
    }
    var firebaseRef = firebase.database().ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(`i:${gameCard.id}t:gamecard`, [
      parseFloat(gameCard.seriesDetails.latitude),
      parseFloat(gameCard.seriesDetails.longitude)
    ]);

    await eventDetails.forEach(async (event, key) => {
      if (typeof event.asset !== "string") {
        let downloadUrl = await this.uploadPhotoToStorage(
          event.asset,
          gameCard.id
        );
        if (!downloadUrl) {
          this.setState({
            ...this.state,
            open: true,
            resultMessage: "Error uploading photo"
          });
          return;
        }
        gameCard.eventDetails[key].asset = downloadUrl;
      }
      var firebaseRef = firebase.database().ref("geo-search/");
      var geoFire = new GeoFire(firebaseRef);
      let geoFireResult = await geoFire.set(
        `i:${gameCard.id}t:gamecard_event`,
        [
          parseFloat(gameCard.eventDetails[key].latitude),
          parseFloat(gameCard.eventDetails[key].longitude)
        ]
      );
      if (key === gameCard.eventDetails.length - 1) {
        // TODO: Switch to firebase transaction
        try {
          let result = await Promise.all([gameCardRef.set(gameCard)]);
          //if (result)
          this.uploadEventDetails(eventDetails, gameCard.id);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  uploadEventDetails = (eventDetails, id) => {
    eventDetails.forEach((event, key) => {
      if (event.eventAction === Events.QUIZ.value) {
        this.uploadQuiz(event.actionData, id, key);
      } else if (event.eventAction === Events.CHECK_IN.value) {
        this.uploadPOI(event.actionData, id, key);
      } else if (event.eventAction === Events.PHOTO_UPLOAD.value) {
        this.uploadPhoto(event.actionData, id, key);
      }
      if (key === eventDetails.length - 1) {
        this.setState({
          ...this.state,
          open: true,
          enableRefresh: true,
          resultMessage: "Success! Your Game Card was uploaded to the WAM app."
        });
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      }
    });
  };

  uploadEventPhoto = (eventDetails, gameCard) => {
    eventDetails.forEach(async (event, key) => {
      let downloadUrl = await this.uploadPhotoToStorage(
        event.asset,
        gameCard.id
      );
      if (!downloadUrl) {
        this.setState({
          ...this.state,
          open: true,
          resultMessage: "Error uploading photo"
        });
        return;
      }
      gameCard.eventDetails[key].asset = downloadUrl;

      var firebaseRef = this.db.ref("geo-search/");
      var geoFire = new GeoFire(firebaseRef);
      let geoFireResult = await geoFire.set(
        `i:${gameCard.id}t:gamecard_event`,
        [
          parseFloat(gameCard.eventDetails[key].latitude),
          parseFloat(gameCard.eventDetails[key].longitude)
        ]
      );
    });
  };

  uploadPhoto = (photoOverview, gameCardId, key) => {
    var user = firebase.auth().currentUser;
    let photo = {
      address: photoOverview.address,
      title: photoOverview.title,
      points: photoOverview.points,
      description: photoOverview.description,
      lat: photoOverview.latitude,
      lng: photoOverview.longitude,
      location_title: photoOverview.location_title,
      type: "photoUpload",
      classification: photoOverview.classification,
      asset: photoOverview.asset,
      creator: user.uid,
      id: photoOverview.id
    };
    this.uploadPhotoToDatabase(photo, gameCardId, key);
  };

  uploadPhotoToDatabase = async (photoData, gameCardId, key) => {
    let poiRef = this.db
      .ref(`game-cards/${gameCardId}/eventDetails/${key}/actionData/`)
      .push();
    photoData.id = poiRef.key;

    let downloadUrl = await this.uploadPhotoToStorage(
      photoData.asset,
      photoData.id
    );
    if (!downloadUrl) {
      this.setState({
        ...this.state,
        open: true,
        resultMessage: "Error uploading photo"
      });
      return;
    }
    photoData.asset = downloadUrl;

    var firebaseRef = this.db.ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(
      `i:${photoData.id}t:game-card_photoUpload`,
      [parseFloat(photoData.lat), parseFloat(photoData.lng)]
    );
    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([poiRef.set(photoData)]);
      
    } catch (error) {
      console.log(error);
    }
  };

  uploadPOI = (poi, gameCardId, key) => {
    var user = firebase.auth().currentUser;
    let poiOverview = poi.overview;
    let poiData = {
      address: poiOverview.address,
      title: poiOverview.title,
      points: poiOverview.points,
      description: poiOverview.description,
      lat: poiOverview.latitude,
      lng: poiOverview.longitude,
      location_title: poiOverview.location_title,
      type: "poi",
      classification: poiOverview.classification,
      asset: poiOverview.asset,
      creator: user.uid,
      id: poiOverview.id
    };
    this.uploadPOIToDatabase(poiData, gameCardId, key);
  };

  uploadPOIToDatabase = async (poiData, gameCardId, key) => {
    let poiRef = this.db
      .ref(`game-cards/${gameCardId}/eventDetails/${key}/actionData/`)
      .push();
    poiData.id = poiRef.key;

    let downloadUrl = await this.uploadPhotoToStorage(
      poiData.asset,
      poiData.id
    );
    if (!downloadUrl) {
      this.setState({
        ...this.state,
        open: true,
        resultMessage: "Error uploading photo"
      });
      return;
    }
    poiData.asset = downloadUrl;

    var firebaseRef = this.db.ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(
      `i:${poiData.id}t:game-card_${poiData.classification}`,
      [parseFloat(poiData.lat), parseFloat(poiData.lng)]
    );
    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([poiRef.set(poiData)]);
      
    } catch (error) {
      console.log(error);
    }
  };

  uploadQuiz = (quizData, gameCardId, key) => {
    var user = firebase.auth().currentUser;
    let quizOverview = quizData.overview;
    let quizQuestions = quizData.questions.questions;
    let points = 0;
    quizQuestions.forEach(question => {
      points += question.points;
    });
    let quiz = {
      address: quizOverview.address,
      title: quizOverview.title,
      totalPoints: points,
      description: quizOverview.description,
      lat: quizOverview.latitude,
      lng: quizOverview.longitude,
      location_title: quizOverview.location_title,
      type: "quiz",
      asset: quizOverview.asset,
      creator: user.uid,
      id: quizOverview.id
    };
    this.uploadQuizToDatabase(quiz, quizQuestions, gameCardId, key);
  };

  uploadQuizToDatabase = async (quiz, questions, gameCardId, key) => {
    let quizRef = this.db
      .ref(`game-cards/${gameCardId}/eventDetails/${key}/actionData/`)
      .push();
    quiz.id = quizRef.key;
    let downloadUrl = await this.uploadPhotoToStorage(quiz.asset, quiz.id);
    if (!downloadUrl) {
      this.setState({
        ...this.state,
        open: true,
        resultMessage: "Error uploading photo"
      });
      return;
    }
    quiz.asset = downloadUrl;

    var firebaseRef = this.db.ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(`i:${quiz.id}t:game-card_quizzes`, [
      parseFloat(quiz.lat),
      parseFloat(quiz.lng)
    ]);
    var questionsRef = this.db.ref(`quiz-questions/${quiz.id}`);
    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([
        questionsRef.set(questions),
        quizRef.set(quiz)
      ]);
      //if (result)
      
      // });
    } catch (error) {
      console.log(error);
    }
  };

  uploadPhotoToStorage = async (file, id) => {
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: "image/jpeg"
    };
    var uploadTask = storageRef
      .child("images/" + id + "/" + file.name)
      .put(file, metadata);
    this.setState({
      ...this.state,
      open: true,
      resultMessage: "Uploading file..."
    });
    return new Promise(resolve => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        function(error) {
          console.log("Error:", error);
          resolve(null);
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false, enableRefresh: false });
  };

  render() {
    const { editMode, data } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={10} sm={10}>
          <Wizard
            validate
            steps={[
              {
                stepName: "Calendar",
                stepComponent: Calendar,
                stepId: "calendar",
                data: {}
              },
              {
                stepName: "Series Details",
                stepComponent: SeriesDetails,
                stepId: "seriesDetails",
                data: editMode ? data.seriesDetails : this.state.seriesDetails,
                editMode: editMode
              },
              {
                stepName: "Event Details",
                stepComponent: EventDetails,
                stepId: "eventDetails",
                data: editMode ? data.eventDetails : []
              },
              {
                stepName: "Game Maker Details",
                stepComponent: GameMakerDetails,
                stepId: "gameMakerDetails",
                data: editMode ? data.gameMaker : this.state.contactInfo
              }
            ]}
            title="Events Game Card Creation"
            subtitle="Create a Game Card by providing the Event Details"
            finishButtonClick={finalState => this.uploadGameCard(finalState)}
            saveButton={true}
            saveButtonClick={finalState =>
              this.uploadGameCard(finalState, true)
            }
            color={"info"}
          />
        </GridItem>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          onClose={this.handleClose}
          open={this.state.open}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.resultMessage}</span>}
          action={[]}
        />
      </GridContainer>
    );
  }
}

export default GmeCardWizardView;
