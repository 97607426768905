import React from "react";
import img1 from "assets/img/collaboration.svg";
import img2 from "assets/img/Adaptive_Telehealth_logo1.svg";

import CreditsPageStyle from "assets/scss/credits.css";

class CreditsPage extends React.Component {
  render() {
    return (
      <section id="credits">
        <div className="title">
          <div className="img-block">
            <img src={img1} alt="" />
          </div>
          <span>Credits</span>
        </div>
        <div className="row">
          <div className="column-5">
            <div className="info">
              <h5>APP Design and Concept</h5>
              <ul>
                <li>
                  <span>Mona El Khafif</span> (UVA Architecture)
                </li>
                <li>
                  <span>Katharine Wibberly</span> (UVA Telehealth)
                </li>
                <li>
                  <span>Marcus Divers</span>{" "}
                </li>
                <li>
                  <span>Elgin Cleckley </span> (UVA Architecture)
                </li>
              </ul>
            </div>

            <div className="info">
              <h5>APP Student Research Assistants</h5>
              <ul>
                <li>
                  <span>Gabriel Andrade</span> (UVA Architecture)
                </li>
                <li>
                  <span>Shurui Zhang</span> (UVA Architecture)
                </li>
                <li>
                  <span>Gabriela Rodas</span> (UVA Architecture)
                </li>
              </ul>
            </div>

            <div className="info">
              <h5>APP Community Partners</h5>
              <ul>
                <li>
                  Martinsville Henry County Coalition for Health and Wellness
                </li>
                <li>Martinsville Office of the City Manager</li>
                <li>New College Institute Innovation Lab</li>
                <li>
                  Martinsville Henry County EDC Economic Development Corporation
                </li>
                <li>Martinsville Henry County</li>
                <li>United Way of Henry County and  Martinsville</li>
                <li>Virginia Museum of Natural History</li>
                <li>Martinsville and Henry County Family YMCA</li>
                <li>Boys and Girls Club of the Blue Ridge</li>
              </ul>
            </div>

            <div className="info left-logo" style={{ paddingBottom: "31px" }}>
              <h5>APP Development and Coding</h5>
              <img src={img2} className="logo-img" />
            </div>
          </div>

          <div className="column-5">
            <div className="info">
              <h5>APP Community Contributors</h5>
              <p>
                Many community members contributed to this project. Among them:
              </p>
              <ul>
                <li>
                  <span>Nancy Bell West </span>(Piedmont Health District)
                </li>
                <li>
                  <span>Joanie Petty </span>(Boys and Girls Clubs of the Blue
                  Ridge)
                </li>
                <li>
                  <span>Suellyn Danter </span>(Martinsville Henry County
                  Coalition for Health and Wellness)
                </li>
                <li>
                  <span>Felicia Preston </span>(Martinsville City Public School)
                </li>
                <li>
                  <span>Steve Tadem </span>(Martinsville City Public School)
                </li>
                <li>
                  <span>Dr. Zebedee Talley </span>(Martinsville City Public
                  School)
                </li>
                <li>
                  <span>Angilee M. Downing </span>(Martinsville City Public
                  School)
                </li>
                <li>
                  <span>Jill Mabe RN </span>(Martinsville Henry County Coalition
                  for Health and Wellness)
                </li>
                <li>
                  <span>Tim Martin </span>(Martin Plaza-The Showroom, Fred
                  Martin Associates)
                </li>
                <li>
                  <span>Joe Keiper, PhD </span>(VA Museum of Natural History)
                </li>
                <li>
                  <span>Aman Sawary </span>(Hugo’s Restaurant and Sports Bar)
                </li>
                <li>
                  <span>Mike Haley </span>(UpTown Pinball)
                </li>
                <li>
                  <span>Lisa Fultz </span>(Chamber of Commerce)
                </li>
                <li>
                  <span>Philip Wenkstern </span>(United Way of Henry Co and
                  Martinsville)
                </li>
                <li>
                  <span>Melody McLarty </span>(United Way, SMART Beginnings)
                </li>
                <li>
                  <span>Brad Kinkema </span>(YMCA)
                </li>
                <li>
                  <span>Pastor Keith Ritchie </span>(First United Methodist
                  Church)
                </li>
                <li>
                  <span>Dr. Mervyn and Virginia King</span> (Hugo's and Kafino's
                  Restaurants)
                </li>
                <li>
                  <span>Brian Harrison </span>(Virginia Cooperative Extension)
                </li>
                <li>
                  <span>Latala Hodges </span>(Harvest Foundation)
                </li>
                <li>
                  <span>Sheryl Agee </span>(Harvest Foundation)
                </li>
                <li>
                  <span>Mike Farley </span>(MHC Coalition for Health and
                  Wellness)
                </li>
                <li>
                  <span>Jennifer Bowles </span>(New Heights Foundation)
                </li>
                <li>
                  <span>Ana Marie Gibson </span>(Piedmont Community Service)
                </li>
                <li>
                  <span>Ken Russell </span>(New College Institute)
                </li>
                <li>
                  <span>Mark McCaskill </span>(City of Martinsville)
                </li>
                <li>
                  <span>Sarah Hodges </span>(City of Martinsville)
                </li>
                <li>
                  <span>Beth Stinnett </span>(City of Martinsville)
                </li>
                <li>
                  <span>Mary Martin </span>(e-BACKPAC)
                </li>
                <li style={{ marginBottom: "-6px" }}>
                  <span>Ken Russel </span>(Ne College Institute)
                </li>
              </ul>
            </div>
            <div className="info right-logo">
              <h5>APP Development and Coding</h5>
              <img src={img2} className="logo-img" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column-10">
            <div className="info">
              <h5>APP Funding Source</h5>
              <p>
                The concept development and pilot test for this app was
                supported by grant number H2ARH30303 from the Office for the
                Advancement of Telehealth, Health Resources and Services
                Administration, DHHS. Later development and enhancements were
                supported by grant number GR011950 GA11275-155732-101-SCC-RCN
                from the National Science Foundation.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CreditsPage;
