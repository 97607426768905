import React from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import Snackbar from "../../components/Snackbar/Snackbar";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import * as firebase from "firebase";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "admin@smartsource.com",
      password: "jaitBba-1#",
      emailState: "",
      passwordState: "",
      loggedIn: false,
      isOpen: false,
      errorMessage: "",
      errorColor: "",
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    let thisObj = this;

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var email = user.email;
        var uid = user.uid;

        thisObj.setState({ loggedIn: "true" });
      } else {
      }
    });
  }

  loginFunction = () => {
    if (
      this.state.emailState === "success" &&
      this.state.passwordState === "success"
    ) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then((result) => {
          console.log("logged in" /* ,result */);
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          this.setState({
            isOpen: true,
            errorMessage,
            errorColor: "rose",
          });
          // ...
        });
    } else {
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }

      if (this.state.passwordState !== "success") {
        this.setState({ passwordState: "error" });
      }
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName) {
    let value = event.target.value;

    switch (stateName) {
      case "email":
        if (this.verifyEmail(value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "password":
        if (value.length > 0) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
    }

    this.setState({ [stateName]: value });
  }
  render() {
    const { classes } = this.props;

    if (this.state.loggedIn) {
      return <Redirect to="/admin/wizard" push={true} />;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={this.state.emailState === "success"}
                    error={this.state.emailState === "error"}
                    labelText="Email"
                    id="email"
                    inputProps={{
                      onChange: (event) => this.change(event, "email"),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <CustomInput
                    success={this.state.passwordState === "success"}
                    error={this.state.passwordState === "error"}
                    inputProps={{
                      onChange: (event) => this.change(event, "password"),
                      type: "password",
                    }}
                    type="password"
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    color="rose"
                    simple
                    size="lg"
                    block
                    onClick={() => this.loginFunction()}
                  >
                    Enter
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc"
          color={this.state.errorColor}
          message={this.state.errorMessage}
          open={this.state.isOpen}
          close={() =>
            this.setState({
              isOpen: false,
              errorMessage: "",
              errorColor: "",
            })
          }
          autoHideDuration={5000}
          closeNotification={() =>
            this.setState({
              isOpen: false,
              errorMessage: "",
              errorColor: "",
            })
          }
        />
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(loginPageStyle)(LoginPage);
