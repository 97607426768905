import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import {
  projectAdminRoutes,
  communityAdminRoutes,
  invalidPermissionRoutes,
} from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import newlogo from "assets/img/newlogo.png";

import Wizard from "views/Forms/Wizard.jsx";
import * as firebase from "firebase";
import PhotoGallery from "views/Tables/PhotoGallery.jsx";
import { CropDinSharp } from "@material-ui/icons";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: "blue",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown",
      adminRoutes: [],
    };
    this.db = firebase.database();
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    // TODO: for permissions to work properly, anyone who can log into the admin portal
    // must have a user account with the same uid and have an appropriately set "adminStatus" field
    let currentUser = firebase.auth().currentUser;
    if (currentUser) {
      this.db
        .ref("users/" + currentUser.uid + "/adminStatus")
        .once("value")
        .then((snapshot) => {
          if (snapshot.val() === "project") {
            this.getProjectAdminRoutes();
          } else if (snapshot.val() === "community") {
            this.setState({ ...this.state, adminRoutes: communityAdminRoutes });
          } else if (snapshot.val() === "group") {
            this.getGroupAdminRoutes();
          } else {
            this.setState({
              ...this.state,
              adminRoutes: invalidPermissionRoutes,
            });
          }
        });
    }
    // perfect scroll bar setup
    if (this.refs.mainPanel) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  getProjectAdminRoutes = async () => {
    let routes = [...projectAdminRoutes];
    let hasEventPhotos = false;
    let eventPhotos = [];
    let photos = await this.db.ref("photos").once("value");
    if (photos.val()) {
      let photosList = Object.values(photos.val()) || [];
      photosList.forEach((photo) => {
        let photoDetails = Object.values(photo);
        photoDetails.forEach((data) => {
          if (data.eventId) {
            hasEventPhotos = true;
            eventPhotos.push(data);
          }
        });
      });
    }
    if (!hasEventPhotos)
      this.setState({ ...this.state, adminRoutes: projectAdminRoutes });
    else {
      let routeData = await this.getEventPhotosRoute(eventPhotos);
      let eventViews = [];
      Object.keys(routeData).forEach((key) => {
        eventViews.push({
          path: "/event-photos/" + routeData[key][0].eventId,
          name: key,
          rtlName: "لوحة القيادة",
          component: PhotoGallery,
          layout: "/admin",
          id: routeData[key][0].eventId,
        });
      });
      let newViews = [
        {
          path: "/community",
          name: "Community",
          rtlName: "لوحة القيادة",
          component: PhotoGallery,
          layout: "/admin",
        },
        // {
        //   name: "Event",
        //   rtlName: "لوحة القيادة",
        //   subMenu: true,
        //   views: eventViews
        // }
      ];
      routes[0].views[1] = {
        name: "Photo Gallery",
        rtlName: "لوحة القيادة",
        subMenu: true,
        views: newViews,
      };
      this.setState({ ...this.state, adminRoutes: routes });
    }
  };

  getEventPhotosRoute = async (eventPhotos) => {
    let routeData = {};
    let gameCardList = await this.db.ref(`game-cards`).once("value");
    gameCardList = gameCardList.val() ? Object.values(gameCardList.val()) : [];
    for (const photo of eventPhotos) {
      let gameCard =
        gameCardList.find((gameCard) => gameCard.id === photo.eventId) || {};
      if (
        gameCard.seriesDetails &&
        gameCard.seriesDetails.name &&
        routeData[gameCard.seriesDetails.name]
      ) {
        routeData[gameCard.seriesDetails.name].push(photo);
      }
      // else routeData[gameCard.seriesDetails.name] = [photo];
    }
    return routeData;
  };

  getGroupAdminRoutes = () => {
    let currentUser = firebase.auth().currentUser;
    this.db
      .ref("users/" + currentUser.uid + "/groupid")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          this.getGroupPermission(snapshot.val());
        }
      });
  };
  getGroupPermission = (groupId) => {
    let routes = [...projectAdminRoutes];
    let routess;
    let newViews = [
      {
        path: "/community",
        name: "Community",
        rtlName: "لوحة القيادة",
        component: PhotoGallery,
        layout: "/admin",
      },
    ];
    routes[0].views[1] = {
      name: "Photo Gallery",
      rtlName: "لوحة القيادة",
      subMenu: true,
      views: newViews,
    };
    this.setState({ ...this.state, adminRoutes: routes });
    //console.log("routes", routes);
    this.db
      .ref("group/" + groupId + "/permissions")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          let permissions = snapshot.val();
          Object.keys(permissions).map((key) => {
            routes.forEach((route) => {
              if (route.views) {
                route.views.forEach((view, viewIndex) => {
                  //
                  if (key === "Survey" && !permissions[key].read) {
                    routess = routes
                      .slice(0, 2)
                      .concat(routes.slice(3, 6))
                      .concat(routes.slice(7, 8));
                  } else {
                    routess = routes.slice(0, 6).concat(routes.slice(7, 8));
                  }
                  //
                  if (view.name === key) {
                    if (
                      permissions[key].create ||
                      permissions[key].read ||
                      permissions[key].update ||
                      permissions[key].archive
                    ) {
                      view = {
                        ...-view,
                        create: permissions[key].create,
                        read: permissions[key].read,
                        update: permissions[key].update,
                        archive: permissions[key].archive,
                      };
                    } else {
                      route.views.splice(viewIndex, 1);
                    }
                  }
                });
              }
            });
          });
          //routes = routes.filter((route) => !!route.views);
          /*this.setState({
            ...this.state,
            adminRoutes: routes,
          });*/
          this.setState({
            ...this.state,
            adminRoutes: routess,
          });
          /*console.log(
            "adminRoutes",
            routes
              .slice(0, 2)
              .concat(routes.slice(3, 6))
              .concat(routes.slice(7, 8))
          );*/
        }
      });
  };
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleBgColorClick = (bgColor) => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse || routes[i].subMenu) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse || prop.subMenu) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div className={classes.wrapper}>
        {!firebase.auth().currentUser ? (
          <Redirect to="/auth/login-page" />
        ) : null}
        <Sidebar
          routes={this.state.adminRoutes}
          logoText={"WAM Admin"}
          logo={newlogo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(this.state.adminRoutes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(this.state.adminRoutes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(this.state.adminRoutes)}</Switch>
            </div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
          <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleBgColorClick={this.handleBgColorClick}
            handleHasImage={this.handleHasImage}
            color={this.state["color"]}
            bgColor={this.state["bgColor"]}
            bgImage={this.state["image"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appStyle)(Dashboard);
