import IconButton from "@material-ui/core/IconButton";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../style.scss";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export default function Previews({
  files,
  setFiles,
  handleImageChange,
  onHandleRemoveFile,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      handleImageChange(acceptedFiles[0]);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone-Dimension" })}>
        <input {...getInputProps()} />
        {!files.length && (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
        {!!files.length && (
          <div className="subContainer">
            <aside style={thumbsContainer}>{thumbs}</aside>
            <IconButton
              className="align-Remove-Button"
              onClick={(event) => {
                onHandleRemoveFile(event);
              }}
            >
              <Tooltip title="Remove file" arrow>
                <HighlightOff />
              </Tooltip>
            </IconButton>
          </div>
        )}
      </div>
    </section>
  );
}
