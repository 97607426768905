import React, { useState, useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";

const CustomSkinMap = withScriptjs(
  withGoogleMap(({ getLocationData, doneDrawing, fence, searchAddress }) => {
    const [positioon, setPosition] = useState({});
    const [address, setAddress] = useState("");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
      getCurrentPosition1();
    }, []);

    const getCurrentPosition1 = () => {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        fetchDetails(pos.coords.latitude, pos.coords.longitude);
      });
    };
    const fetchDetails = (lat, lng) => {
      var latlng = new window.google.maps.LatLng(lat, lng);
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          alert(status);
        }
        if (status == window.google.maps.GeocoderStatus.OK) {
          var address = results[0].formatted_address;
          setAddress(address);
          getLocationData({
            lat: lat,
            lng: lng,
            address: address,
          });
          setPosition({ lat: lat, lng: lng });
        }
      });
    };

    return (
      <GoogleMap
        defaultZoom={13}
        center={
          searchAddress
            ? { lat: searchAddress.latitude, lng: searchAddress.longitude }
            : positioon
        }
        onClick={(ev) => {
          fetchDetails(ev.latLng.lat(), ev.latLng.lng());
          setOpenModal(true);
        }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true,
          styles: [
            {
              featureType: "water",
              stylers: [
                { saturation: 43 },
                { lightness: -11 },
                { hue: "#0088ff" },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                { hue: "#ff0000" },
                { saturation: -100 },
                { lightness: 99 },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#808080" }, { lightness: 54 }],
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.fill",
              stylers: [{ color: "#ece2d9" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry.fill",
              stylers: [{ color: "#ccdca1" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#767676" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#ffffff" }],
            },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            {
              featureType: "landscape.natural",
              elementType: "geometry.fill",
              stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
            },
            { featureType: "poi.park", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.sports_complex",
              stylers: [{ visibility: "on" }],
            },
            { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.business",
              stylers: [{ visibility: "simplified" }],
            },
          ],
        }}
      >
        {fence && (
          <DrawingManager
            defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
            }}
            onPolygonComplete={doneDrawing}
          />
        )}
        <Fab
          variant="round"
          size="small"
          color="primary"
          onClick={getCurrentPosition1}
          style={{
            bottom: 63,
            left: "80%",
          }}
        >
          <MyLocationIcon />
        </Fab>
        <Marker
          position={
            searchAddress
              ? { lat: searchAddress.latitude, lng: searchAddress.longitude }
              : positioon
          }
          draggable={true}
          onDragEnd={(loc) => {
            fetchDetails(loc.latLng.lat(), loc.latLng.lng());
            setOpenModal(true);
          }}
          onDrag={() => setOpenModal(false)}
        >
          {openModal && (
            <InfoWindow onCloseClick={() => setOpenModal(false)}>
              <div>
                <h4 style={{ textTransform: "uppercase", textAlign: "center" }}>
                  {address}
                </h4>
                <p>Longitude : {positioon.lng}</p>
                <p>Latitude : {positioon.lat}</p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      </GoogleMap>
    );
  })
);

function FullScreenMap({ getLocationData, doneDrawing, fence, address }) {
  return (
    <CustomSkinMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_MAP_API
      }&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `50vh` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      getLocationData={getLocationData}
      doneDrawing={doneDrawing}
      fence={fence}
      searchAddress={address}
    />
  );
}

export default FullScreenMap;
