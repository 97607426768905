import React, { useState, setState, useEffect } from "react";

// Material UI Core Component
import { IconButton, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// Material UI Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircle from "@material-ui/icons/AddCircle";
import Eye from "@material-ui/icons/RemoveRedEye";
import Edit from "@material-ui/icons/Edit";
import MoreVert from "@material-ui/icons/MoreVert";
import Schedule from "@material-ui/icons/Schedule";
import LeaderboardIcon from "@material-ui/icons/Star";

// Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { scrollToTop } from "../../../../utils";
import moment from "moment";
// Scss Stylesheet
import "../styles.scss";

const ActionComponent = ({
  row,
  param,
  openMission,
  setOpenMission,
  setHuntsId,
  onHandleView,
  onHandleEnableHunt,
  onHandleRemoveHunt,
  setDeleteRow,
  setOpenSchedule,
  editHuntMission,
  onHandleLeaderboard,
  setOpenScheduleEdit,
}) => {
  const {
    id,
    isSwitch,
    startTime,
    endTime,
    huntName,
    points,
    gameplayTime,
  } = row.original;
  const [disabled, setBtnDisable] = useState(false);
  //console.log("row.original", row.original);
  const disableBtn = row.original.points.then((res) => {
    if (res.id === id && !res.data) {
      setBtnDisable(true);
    }
  });

  let scheduler =
    (Date.now() >= startTime && Date.now() <= endTime) ||
    (startTime >= Date.now() && endTime >= Date.now());
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#fff",
      "&$checked": {
        color: "#fff",
      },
      "&$checked + $bar": {
        opacity: 1,
        backgroundColor: "#1BB394",
      },
    },
    icon: {
      height: 10,
      width: 10,
      borderRadius: 5,
      padding: 0,
      position: "absolute",
      top: 21,
    },
    bar: {
      height: 18,
      width: 33,
      borderRadius: 100,
      backgroundColor: "#000000",
      opacity: 1,
    },
    checked: {},
  })(Switch);
  const [openModal, setOpenModal] = useState(false);
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (openModal) {
        setOpenModal(false);
      }
    }
  };

  const onHandleSchedule = () => {
    setOpenModal(false);

    // Scheduler dates check
    let switchData =
      (Date.now() >= startTime && Date.now() <= endTime) ||
      (startTime >= Date.now() && endTime >= Date.now());
    if (switchData) {
      setDeleteRow({
        isOpen: true,
        message: "Currently scavenger Hunt is live.",
      });
      // edit Scheduler function
      setOpenScheduleEdit({
        isOpen: true,
        id,
        message: "Edit Scavenger Hunt",
        data: row.original,
      });
      scrollToTop();
    } else {
      setDeleteRow({
        isOpen: true,
        message: "You can reschedule the hunt.",
      });
      setOpenSchedule({ isOpen: true, id, message: huntName });
      scrollToTop();
    }
  };

  return (
    <div className="action-component-style" onBlur={handleBlur}>
      <IconButton
        onClick={() => {
          setOpenMission({ value: true, message: huntName });
          setHuntsId(id);
        }}
      >
        <Tooltip title="Add a new mission">
          <AddCircle />
        </Tooltip>
      </IconButton>
      <Tooltip
        title={
          gameplayTime === "Scheduled" && scheduler
            ? "From " +
              new Date(startTime).toDateString() +
              " to " +
              new Date(endTime).toDateString()
            : isSwitch
            ? "Active"
            : "Inactive"
        }
      >
        <button className="sub-action-button hun-status">
          <span className="button-text">
            {gameplayTime === "Scheduled" && scheduler
              ? "Scheduled"
              : isSwitch && gameplayTime === "Immediately"
              ? "Active"
              : "Inactive"}
          </span>
        </button>
      </Tooltip>

      <IconButton
        onClick={() => {
          setOpenModal(false);
          onHandleView();
        }}
      >
        <Tooltip title="View mission">
          <Eye />
        </Tooltip>
      </IconButton>
      <IconButton onClick={() => setOpenModal(!openModal)}>
        <Tooltip title="more">
          <MoreVert />
        </Tooltip>
      </IconButton>
      {openModal && (
        <GridContainer
          xs={2}
          className="action-popup-component-style"
          style={{
            top:
              row.viewIndex < 6
                ? row.viewIndex * 100
                : 200 + row.viewIndex * 30,
          }}
        >
          <button
            onClick={() => {
              setOpenModal(false);
              onHandleRemoveHunt(id);
            }}
            className="sub-action-button active"
          >
            <DeleteIcon />
            <span className="button-text">Delete</span>
          </button>
          <button
            onClick={() => {
              editHuntMission("edit", row.original);
              setOpenModal(true);
              setDeleteRow({
                isOpen: true,
                message: "Currently used by Player.",
              });
              scrollToTop();
            }}
            className="sub-action-button active"
          >
            <span className="button-div-style">
              <Edit />
            </span>
            <span className="button-text">Edit</span>
          </button>
          <button
            onClick={onHandleSchedule}
            className="sub-action-button active"
          >
            <Schedule />
            <span className="button-text">Scheduler</span>
          </button>
          <button
            disabled={disabled}
            onClick={onHandleLeaderboard}
            className={
              disabled
                ? "sub-action-button leadership "
                : "sub-action-button leadership active "
            }
          >
            <LeaderboardIcon />
            <span className="button-text">Leaderboard</span>
          </button>
        </GridContainer>
      )}
    </div>
  );
};

export default ActionComponent;
