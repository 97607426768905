const mobileViewStyle = {
  mainContainer: {
    height: "inherit",
    backgroundColor: "black"
  },
  headerContainer: {
    display: "flex",
    padding: "5px"
  },
  lastItem: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  iconContainer: {
    padding: "8px",
    width: "fit-content",
    margin: "10px",
    borderRadius: "50%"
  },
  footerContainer: {
    display: "flex",
    padding: "2px",
    borderRadius: "0 0 4px 4px"
  },
  buttonContainer: {
    minWidth: "80px",
    textAlign: "center",
    padding: "5px",
    margin: "10px",
    backgroundColor: "#5797df",
    borderRadius: "15px",
    fontWeight: "bold"
  },
  bodyContainer: {
    height: "265px",
    margin: "2px",
    borderRadius: "4px 4px 0 0",
    padding: "6px"
  },
  titleContainer: {
    fontSize: "21px",
    textTransform: "uppercase",
    fontWeight: "600",
    color: "#5797df",
    textAlign: "center"
  },
  dateContainer: {
    fontSize: "11px",
    fontWeight: "400",
    color: "#5797df",
    textAlign: "center"
  },
  imageContainer: {
    textAlign: "center"
  },
  descriptionContainer: {
    fontSize: "14px",
    textAlign: "center"
  },
  sponsorContainer: {
    fontSize: "13px",
    fontStyle: "italic",
    fontWeight: "500"
  },
  eventTitleContainer: {
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "500"
  },
  pointsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontSize: "13px"
  },
  answerContainer: {
    textAlign: "center",
    backgroundColor: "aquamarine",
    borderRadius: "10px",
    margin: "5px"
  }
};
export default mobileViewStyle;
