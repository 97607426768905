import React from "react";
import "./PictureUpload.css";
import defaultImage from "assets/img/default-photo.png";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  dangerColor,
  successColor,
} from "assets/jss/material-dashboard-pro-react.jsx";
import { handleImageUpload } from "../../utils";
import { Tooltip } from "@material-ui/core";

const style = {
  inputError: {
    color: dangerColor[0],
  },
  inputSuccess: {
    color: successColor[0],
  },
};

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl:
        typeof this.props.defaultAssetUrl === "object"
          ? defaultImage
          : this.props.defaultAssetUrl || defaultImage,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleImageChange(e) {
    e.preventDefault();
    try {
      let reader = new FileReader();
      let file = await handleImageUpload(e.target.files[0]);
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
        this.props.setAsset(this.state.file);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log("error =>", error);
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  getAssetStatusElement = () => {
    const { classes } = this.props;
    switch (this.props.assetState) {
      case "success":
        return <span className={classes.inputSuccess}>Valid file</span>;
      case "error":
        return (
          <span className={classes.inputError}>
            {this.props.assetMessage && this.props.assetMessage != ""
              ? this.props.assetMessage
              : "Invalid file"}
          </span>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="picture-style-cont">
        <img
          src={this.state.imagePreviewUrl}
          className="picture-src-cont"
          alt="..."
        />
        {this.props && this.props.isOptional && (
          <span className="optional-text">(Optional)</span>
        )}
        {this.props && this.props.isOptional ? (
          <Tooltip title="If no image is uploaded then the POI image will be used as default for this quiz">
            <span className="">
              <label class="custom-file-upload" style={{ marginTop: 10 }}>
                <input
                  type="file"
                  accept="image/x-png,image/jpg,image/jpeg"
                  onChange={(e) => this.handleImageChange(e)}
                />
                Choose file
              </label>
            </span>
          </Tooltip>
        ) : (
          <label class="custom-file-upload" style={{ marginTop: 10 }}>
            <input
              type="file"
              accept="image/x-png,image/jpg,image/jpeg"
              onChange={(e) => this.handleImageChange(e)}
            />
            Choose file
          </label>
        )}

        {this.getAssetStatusElement()}
      </div>
    );
  }
}

export default withStyles(style)(PictureUpload);
