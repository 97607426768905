import React, { useState } from "react";
import {
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import "./style.css";

const SurveyModal = ({
  openModal,
  setOpenModal,
  setSurveyName,
  setRedirect,
  surveyName,
  title,
  text,
  ok,
  cancel,
  fence,
  content,
  subContain,
}) => {
  return (
    <Dialog fullWidth={true} fullHeight={true} open={openModal}>
      {title && (
        <DialogTitle>
          <div className="modal-text-style">{title}</div>
          {title === "Survey Publishing Geolocation" && (
            <Button
              style={{
                backgroundColor: `#00ACC1`,
                borderRadius: 3,
                width: `100%`,
              }}
            >
              {surveyName && surveyName.original.name}
            </Button>
          )}
        </DialogTitle>
      )}
      <DialogContent>
        {content && content}
        {subContain && subContain}
      </DialogContent>
      <DialogActions>
        {cancel && cancel}
        {ok && ok}
      </DialogActions>
    </Dialog>
  );
};
export default SurveyModal;
