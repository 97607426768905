import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { red } from "@material-ui/core/colors";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  }
});

class RadioButtonsGroup extends React.Component {
  handleChange = event => {
    this.updateAnswer(event.target.value);
  };

  updateAnswer = value => {
    let newQuestion = { ...this.props.question };
    newQuestion.answer = parseInt(value);
    this.props.updateQuestion(newQuestion, this.props.questionIndex);
  };

  render() {
    const { classes } = this.props;
    if (this.props.question.answer >= this.props.question.answers.length) {
      this.updateAnswer(0);
    }
    return (
      <div className={classes.root}>
        <FormControl
          component="fieldset"
          className={classes.formControl}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <FormLabel component="legend">Correct Answer</FormLabel>
          <RadioGroup
            aria-label=""
            name="correctAnswer"
            className={classes.group}
            value={this.props.question.answer}
            onChange={this.handleChange}
            style={{ marginTop: "65px" }}
          >
            {this.props.question.answers.map((answer, index) => {
              return (
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={"Answer " + (index + 1)}
                  style={{ marginBottom: "28px" }}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RadioButtonsGroup);
