import * as firebase from "firebase";

export const fetchPOIQuizData = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const fetchQuizIdRef = db.ref(`map-items/quizzes/${id}/`);
      fetchQuizIdRef.on("value", (userSnapshot) => {
        const val = userSnapshot.val();
        resolve(val);
      });
    } catch (err) {
      console.log("Error occure during Approved mission ", err);
    }
  });
};
