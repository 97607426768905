import imageCompression from "browser-image-compression";

export const handleImageUpload = (imageFile) => {
  return new Promise(async (resolve, reject) => {
    const options = {
      maxSizeMB: 300 / 1024,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log("compressedFile instanceof Blob", compressedFile); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      resolve(compressedFile);
    } catch (error) {
      reject(error);
    }
  });
};

export const deepClone = (data) => JSON.parse(JSON.stringify(data));

export const scrollToTop = () => {
  var scrollTag = document.getElementsByClassName("ps--active-y");
  if (scrollTag[0]) {
    scrollTag[0].scroll({ top: 0, behavior: "smooth" });
  }
};

export const scrollToTopById = (id) => {
  var scrollTag = document.getElementById(id);
  if (scrollTag) {
    scrollTag.scroll({ top: 0, behavior: "smooth" });
  }
};

export const sortingAccordingCreation = (table) => {
  if (Boolean(table.length)) {
    table.sort(function (x, y) {
      return y.timestamp - x.timestamp;
    });
  }
};