import React from "react";
import * as firebase from "firebase";

// @material-ui/icons
import Title from "@material-ui/icons/Title";
import Description from "@material-ui/icons/Description";
import LocationCity from "@material-ui/icons/LocationCity";
import LocationOn from "@material-ui/icons/LocationOn";
import MyLocation from "@material-ui/icons/MyLocation";
import InsertPhoto from "@material-ui/icons/InsertPhoto";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Radio, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PlacesInput from "components/PlacesInput/PlacesInput.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { POIs } from "../../../constants/POI";
import { validImgExtentions } from "../../../constants/Common";
import Snackbar from "../../../components/Snackbar/Snackbar";

const style = {
  slackBar: {
    fontSize: 20,
    fontWeight: "400",
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  ...customSelectStyle,
};
const CustomRadio = withStyles({})(Radio);
class POIForm extends React.Component {
  constructor(props) {
    super(props);
    let poiToUpdate = this.props.data || {};
    this.db = firebase.database();
    this.accomodationRef = this.db.ref(`map-items/${POIs.ACCOMODATION.value}/`);
    this.attractionRef = this.db.ref(`map-items/${POIs.ATTRACTION.value}/`);
    this.parkRef = this.db.ref(`map-items/${POIs.PARK.value}/`);
    this.restaurantRef = this.db.ref(`map-items/${POIs.RESTAURANT.value}/`);
    this.shoppingRef = this.db.ref(`map-items/${POIs.SHOPPING.value}/`);
    this.state = {
      title: poiToUpdate.title || "",
      titleState: this.getInitialStateVal(poiToUpdate.title),
      address: poiToUpdate.address || "",
      addressState: this.getInitialStateVal(poiToUpdate.address),
      description: poiToUpdate.description || "",
      descriptionState: this.getInitialStateVal(poiToUpdate.description),
      asset: poiToUpdate.asset,
      assetState: this.getInitialStateVal(poiToUpdate.asset),
      location_title: poiToUpdate.location_title,
      latitude: poiToUpdate.lat || poiToUpdate.latitude || 0,
      latitudeState: this.getInitialStateVal(
        poiToUpdate.lat || poiToUpdate.latitude
      ),
      longitude: poiToUpdate.lng || poiToUpdate.longitude || 0,
      longitudeState: this.getInitialStateVal(
        poiToUpdate.lng || poiToUpdate.longitude
      ),
      points: poiToUpdate.points || 1,
      pointsState: this.getInitialStateVal(poiToUpdate.points || 1),
      classification: poiToUpdate.classification || "",
      classificationState: this.getInitialStateVal(poiToUpdate.classification),
      id: poiToUpdate.id || "",
      assetMessage: "",
      openCardModel: false,
      tableValues: [{}],
      isOpen: false,
      errorMessage: "",
      errorColor: "",
      quizIdList: poiToUpdate.quizIdList || [],
      reset: poiToUpdate.reset || null,
      created: poiToUpdate.created || Date.now(),
      modify: poiToUpdate.modify || Date.now(),
    };
    this.setAsset = this.setAsset.bind(this);
    this.choosePlace = this.choosePlace.bind(this);
    this.setAddressState = this.setAddressState.bind(this);
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    let dataArr = [];
    this.loadedClassificationsCount = 0;
    this.fetchPOIDataByTableRef(this.accomodationRef, dataArr);
    this.fetchPOIDataByTableRef(this.attractionRef, dataArr);
    this.fetchPOIDataByTableRef(this.parkRef, dataArr);
    this.fetchPOIDataByTableRef(this.restaurantRef, dataArr);
    this.fetchPOIDataByTableRef(this.shoppingRef, dataArr);
  };

  fetchPOIDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(child.val());
        }
      });

      this.loadedClassificationsCount += 1;
      if (this.loadedClassificationsCount === 5) {
        this.setState({ ...this.state, tableValues: dataArr });
      }
    });
  };

  getInitialStateVal(stateElement) {
    if (stateElement && stateElement !== "") {
      return "success";
    }
    return "";
  }

  sendState() {
    console.log(this.state, "STATE FINISHED");
    return this.state;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyLatLong(value) {
    const isDecimal = value.match(/^-?\d*(\.\d+)?$/) && value.length > 0;
    return value >= -90.0 && value <= 90 && isDecimal;
  }

  verifyInteger(value) {
    return !isNaN(parseInt(value));
  }

  checkUniqueLocation(latitude, longitude) {
    let isPresent = this.state.tableValues.filter(
      (ele) => ele.lat === latitude && ele.lng === longitude
    );
    return Boolean(isPresent.length) ? true : false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "decimal":
        if (this.verifyLatLong(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: Number(event.target.value) });
        break;
      case "integer":
        if (this.verifyInteger(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: parseInt(event.target.value) });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  isValidated() {
    if (
      this.state.titleState === "success" &&
      this.state.addressState === "success" &&
      this.state.descriptionState === "success" &&
      this.state.assetState === "success" &&
      this.state.latitudeState === "success" &&
      this.state.longitudeState === "success" &&
      this.state.pointsState === "success" &&
      this.state.classificationState === "success"
    ) {
      return true;
    } else {
      if (this.state.titleState !== "success") {
        this.setState({ titleState: "error" });
      }
      if (this.state.addressState !== "success") {
        this.setState({ addressState: "error" });
      }
      if (this.state.descriptionState !== "success") {
        this.setState({ descriptionState: "error" });
      }
      if (this.state.assetState !== "success") {
        this.setState({ assetState: "error" });
      }
      if (this.state.latitudeState !== "success") {
        this.setState({ latitudeState: "error" });
      }
      if (this.state.longitudeState !== "success") {
        this.setState({ longitudeState: "error" });
      }
      if (this.state.pointsState !== "success") {
        this.setState({ pointsState: "error" });
      }
      if (this.state.classificationState !== "success") {
        this.setState({ classificationState: "error" });
      }
    }
    return false;
  }

  setAsset(file) {
    let valid = true;

    if (file) {
      // validate - file max size should be 500 KB
      // if (file.size > 1000 * 1024) {
      //   this.setState({
      //     assetMessage:
      //       "Invalid file. File size is too large, maximum file size should be 1MB"
      //   });
      //   valid = false;
      // } else {
      // validate file type
      var currentExtension = file.name.substring(
        file.name.lastIndexOf(".") + 1
      );
      var found = validImgExtentions.find(function(element) {
        return element == (currentExtension || "").toLowerCase();
      });
      if (!found) {
        this.setState({ assetMessage: "Invalid file type" });
        valid = false;
      }
      // }
    }

    if (valid) {
      this.setState({ assetState: "success", assetMessage: "Valid file" });
    } else {
      this.setState({ assetState: "error" });
    }
    this.setState({ ...this.state, asset: file });
  }

  choosePlace(location_title, address, latitude, longitude) {
    let isAvailable = this.checkUniqueLocation(latitude, longitude);
    if (isAvailable) {
      this.setState({
        ...this.state,
        isOpen: true,
        errorMessage: "Already created POI in this latitude and longitude",
        errorColor: "rose",
        longitudeState: "error",
        latitudeState: "error",
      });
    } else {
      this.setState({
        ...this.state,
        location_title: location_title,
        address: address,
        latitude: latitude,
        latitudeState: "success",
        longitude: longitude,
        longitudeState: "success",
      });
    }
  }

  setAddressState(addressState) {
    this.setState({
      ...this.state,
      addressState: addressState,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Please enter the details of Place of Interest
          </h4>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            success={this.state.titleState === "success"}
            error={this.state.titleState === "error"}
            labelText={<span>Title</span>}
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.title,
              inputProps: {
                maxLength: 50,
              },
              onChange: (event) => this.change(event, "title", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Title className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            success={this.state.descriptionState === "success"}
            error={this.state.descriptionState === "error"}
            labelText={<span>Description</span>}
            id="description"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.description,
              inputProps: {
                maxLength: 100,
              },
              onChange: (event) =>
                this.change(event, "description", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Description className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl
            fullWidth
            className={classes.selectFormControl}
            error={this.state.classificationState === "error"}
          >
            <InputLabel
              htmlFor="poi-classification"
              className={classes.selectLabel}
              style={{ textTransform: "none" }}
            >
              Choose Classification
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={this.state.classification}
              onChange={(event) =>
                this.change(event, "classification", "length", 3)
              }
              inputProps={{
                name: "classification",
                id: "poi-classification",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
                value=""
              >
                Classification
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={POIs.RESTAURANT.value}
              >
                Restaurant
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={POIs.ACCOMODATION.value}
              >
                Accomodation
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={POIs.SHOPPING.value}
              >
                Shopping
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={POIs.PARK.value}
              >
                Park
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={POIs.ATTRACTION.value}
              >
                Attraction
              </MenuItem>
            </Select>
          </FormControl>

          <PictureUpload
            defaultAssetUrl={this.state.asset}
            setAsset={this.setAsset}
            assetState={this.state.assetState}
            assetMessage={this.state.assetMessage}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <PlacesInput
            classes={classes}
            defaultAddress={this.state.address}
            choosePlace={this.choosePlace}
            setAddressState={this.setAddressState}
            descriptionState={this.state.addressState}
          />
        </GridItem>
        <GridItem xs={6} sm={5}>
          <CustomInput
            success={this.state.latitudeState === "success"}
            error={this.state.latitudeState === "error"}
            labelText={<span>Latitude</span>}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.latitude,
              type: "number",
              onChange: (event) => this.change(event, "latitude", "decimal"),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <MyLocation className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={5}>
          <CustomInput
            success={this.state.longitudeState === "success"}
            error={this.state.longitudeState === "error"}
            labelText={<span>Longitude</span>}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.longitude,
              type: "number",
              onChange: (event) => this.change(event, "longitude", "decimal"),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <MyLocation className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            success={this.state.titleState === "success"}
            error={this.state.titleState === "error"}
            labelText={<span>Points</span>}
            id="points"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.points,
              type: "number",
              onChange: (event) => this.change(event, "points", "integer", 3),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <span className={classes.infoText}>
            Do you want an information card and quiz added to this place ?
          </span>
          <div>
            <span>Yes</span>
            <CustomRadio
              checked={this.props.selectedValue === "yes"}
              onChange={this.props.handleChange}
              value="yes"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <span>No</span>
            <CustomRadio
              checked={this.props.selectedValue === "no"}
              onChange={this.props.handleChange}
              value="no"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            {this.props.data && !this.props.data.informationCard && (
              <span>{`(unavailable of ${!this.props.data.informationCard &&
                "information card"} )`}</span>
            )}
          </div>
        </GridItem>
        <Snackbar
          place="tc"
          color={this.state.errorColor}
          message={this.state.errorMessage}
          open={this.state.isOpen}
          close={() =>
            this.setState({
              isOpen: false,
              errorMessage: "",
              errorColor: "",
            })
          }
          closeNotification={() =>
            this.setState({
              isOpen: false,
              errorMessage: "",
              errorColor: "",
            })
          }
        />
      </GridContainer>
    );
  }
}

export default withStyles(style)(POIForm);
