import React, { Component } from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

export default class QuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleAnswersInputChange = event => {
    let answerNumber = parseInt(event.target.id);
    let answerText = event.target.value;
    let newQuestion = { ...this.props.question };
    newQuestion.answers[answerNumber] = answerText;
    this.props.updateQuestion(newQuestion, this.props.questionIndex);
  };

  handleQuestionInputChange = event => {
    let newQuestion = { ...this.props.question };
    newQuestion.question = event.target.value;
    this.props.updateQuestion(newQuestion, this.props.questionIndex);
    this.setState({ selectedValue: event.target.value });
  };

  addAnswer = () => {
    let newQuestion = { ...this.props.question };
    newQuestion.answers = newQuestion.answers.slice().concat([""]);
    this.props.updateQuestion(newQuestion, this.props.questionIndex);
  };

  removeAnswer = () => {
    let newQuestion = { ...this.props.question };
    newQuestion.answers = newQuestion.answers.slice(
      0,
      newQuestion.answers.length - 1
    );
    this.props.updateQuestion(newQuestion, this.props.questionIndex);
  };

  render() {
    const { classes } = this.props;
    return (
      <form>
        <div style={{ width: "25vw", marginTop: "12px" }}>
          <CustomInput
            success={
              this.props.question &&
              this.props.question.questionState === "success"
            }
            error={
              this.props.question &&
              this.props.question.questionState === "error"
            }
            labelText="Question"
            id={"question"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.props.question.question,
              onChange: this.handleQuestionInputChange,
              inputProps: {
                maxLength: 120
              },
            }}
          />
          {this.props.question.answers.map((currentVal, index) => {
            return (
              <CustomInput
                success={
                  this.props.question.answersState &&
                  this.props.question.answersState[index] &&
                  this.props.question.answersState[index] === "success"
                }
                error={
                  this.props.question.answersState &&
                  this.props.question.answersState[index] &&
                  this.props.question.answersState[index] === "error"
                }
                labelText={"Answer " + (index + 1)}
                id={index}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.props.question.answers[index],
                  onChange: this.handleAnswersInputChange,
                  inputProps: {
                    maxLength: 30
                  },
                }}
              />
            );
          })}
          {this.props.question.answers.length > 2 ? (
            <Button onClick={this.removeAnswer} fullWidth color="primary">
              - Remove Answer
            </Button>
          ) : null}
          {this.props.question.answers.length < 5 ? (
            <Button onClick={this.addAnswer} fullWidth color="primary">
              + Add Answer
            </Button>
          ) : null}
        </div>
      </form>
    );
  }
}
