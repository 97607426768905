import React from "react";
import CommentRounded from "@material-ui/icons/CommentRounded";
import FormatListNumberedRounded from "@material-ui/icons/FormatListNumberedRounded";
import Photo from "@material-ui/icons/Photo";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowDropDown from "@material-ui/icons/ArrowDropDownCircle";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";

const rating = {
  type: "rating",
  title: "question",
  rateValues: [
    {
      value: 1,
      text: "1",
    },
    {
      value: 2,
      text: "2",
    },
    {
      value: 3,
      text: "3",
    },
    {
      value: 4,
      text: "4",
    },
    {
      value: 5,
      text: "5",
    },
  ],
};

const comment = {
  type: "comment",
  name: "question",
};

const image = {
  type: "image",
  name: "question1",
  imageLink:
    "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg",
};

const checkBox = {
  type: "checkbox",
  // name: "question1",
  title: "checkBox Title",
  // description: "checkBox Description",
  isRequired: false,
  choices: [
    {
      value: "checkbox_item1",
      text: "checkbox_item1",
    },
    {
      value: "checkbox_item2",
      text: "checkbox_item2",
    },
    {
      value: "checkbox_item3",
      text: "checkbox_item3",
    },
  ],
};
const radiogroup = {
  type: "radiogroup",
  // name: "question1",
  title: "radiogroup Title",
  // description: "radiogroup Description",
  choices: [
    {
      value: "radiogroup_item1",
      text: "radiogroup_item1",
    },
    {
      value: "radiogroup_item2",
      text: "radiogroup_item2",
    },
    {
      value: "radiogroup_item3",
      text: "radiogroup_item3",
    },
  ],
};

const dropdown = {
  type: "dropdown",
  // name: "question1",
  title: "dropdown Title",
  // description: "dropdown Description",
  choices: [
    {
      value: "dropdown_item1",
      text: "dropdown_item1",
    },
    {
      value: "dropdown_item2",
      text: "dropdown_item2",
    },
    {
      value: "dropdown_item3",
      text: "dropdown_item3",
    },
  ],
};

const boolean = {
  type: "boolean",
  // name: "question2",
  title: "boolean Title",
  // description: "boolean Description",
};
const file = {
  type: "file",
  // name: "question1",
};
export const TAB_MENU = [
  { name: "Rating", component: rating, icon: <FormatListNumberedRounded /> },
  { name: "Comment", component: comment, icon: <CommentRounded /> },
  { name: "Image", component: image, icon: <Photo /> },
  { name: "CheckBox", component: checkBox, icon: <CheckBoxIcon /> },
  {
    name: "Radio Button",
    component: radiogroup,
    icon: <RadioButtonCheckedIcon />,
  },
  { name: "Dropdown", component: dropdown, icon: <ArrowDropDown /> },
  { name: "Boolean", component: boolean, icon: <Photo /> },
  { name: "File", component: file, icon: <InsertDriveFile /> },
];

export const LEFT_TAB_MENU = [
  {
    id: 1,
    name: "Header",
    items: [
      {
        title: "Input Survey Title",
        Description: "Input Survey Title Description",
        checkBox: "show/hide title",
        mode: "Mode (edit/read only)",
      },
    ],
  },
];

export const header = [
  {
    title: "Input Survey Title",
    Description: "Input Survey Title Description",
  },
];

export const question = [
  {
    name: "Name",
    title: "title",
    description: "description",
  },
];
