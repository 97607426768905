import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import QuestionForm from "./QuestionForm";
import {deepClone} from '../../../utils'
import * as firebase from "firebase";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

const questionObject = {
  answers: new Array(2).fill(""),
  question: "",
  points: 1,
  answer: 0,
  answersState: new Array(2).fill(""),
  questionState: "",
  pointsState: "",
  answerState: ""
};

class QuestionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          answers: new Array(2).fill(""),
          question: "",
          points: 1,
          answer: 0,
          answersState: new Array(2).fill(""),
          questionState: "",
          pointsState: "",
          answerState: ""
        }
      ]
    };
  }

  componentDidMount() {
    let quizToUpdate = this.props.data;
    if (quizToUpdate) {
      let db = firebase.database();
      db.ref(`quiz-questions/${quizToUpdate.id}/`)
        .once("value")
        .then(snapshot => {
          if (snapshot.val()) {
            let questions = snapshot.val();
            questions = questions.map(data => {
              return {
                answers: data.answers,
                question: data.question,
                points: data.points,
                answer: data.answer,
                answersState: this.getOptionsStateVal(data.answers),
                questionState: this.getQuestionStateVal(data.question),
                pointsState: this.getPointsStateVal(data.points),
                answerState: this.getAnswerStateVal(data.answer)
              };
            });
            this.setState({ questions: questions });
          }
          console.log("questionsform after dismount: ", this.state);
        });
    }
  }

  getQuestionStateVal = (question, errorStateVal = "") => {
    if (question && question.trim() !== "") {
      return "success";
    }

    return errorStateVal;
  };

  getOptionsStateVal = (answers, errorStateVal = "") => {
    let stateVal = [];
    if (answers) {
      stateVal = answers.map((answer, index) => {
        if (answer && answer.trim() !== "") {
          let result = "success";
          for (let idx = 0; idx < index; idx++) {
            if (answers[idx] === answer) {
              result = errorStateVal;
              break;
            }
          }
          return result;
        }
        return errorStateVal;
      });
    }

    return stateVal;
  };

  getPointsStateVal = (points, errorStateVal = "") => {
    if (points && points > 0) {
      return "success";
    }

    return errorStateVal;
  };

  getAnswerStateVal = (answer, errorStateVal = "") => {
    if (answer >= 0) {
      return "success";
    }

    return errorStateVal;
  };

  isQuestionValid = question => {
    let questionStateVal = this.getQuestionStateVal(question.question);
    let answersStateVal = this.getOptionsStateVal(question.answers);
    let pointsStateVal = this.getPointsStateVal(question.points);
    let answerStateVal = this.getAnswerStateVal(question.answer);
    if (
      questionStateVal !== "success" ||
      answersStateVal.indexOf("") >= 0 ||
      pointsStateVal !== "success" ||
      answerStateVal !== "success"
    ) {
      return false;
    }

    return true;
  };

  sendState() {
    return this.state;
  }

  isValidated() {
    let isValid = this.state.questions.every(question => {
      if (!this.isQuestionValid(question)) {
        return false;
      }
      return true;
    });

    if (!isValid) {
      let validatedQuestionsArr = [];
      this.state.questions.forEach(question => {
        let validatedQuestion = {
          ...question,
          answersState: this.getOptionsStateVal(question.answers, "error"),
          questionState: this.getQuestionStateVal(question.question, "error"),
          pointsState: this.getPointsStateVal(question.points, "error"),
          answerState: this.getAnswerStateVal(question.answer, "error")
        };
        validatedQuestionsArr.push(validatedQuestion);
      });
      this.setState({ questions: validatedQuestionsArr });
    }

    return isValid;
  }

  createNewQuestion = () => {
    let newQuestions = this.state.questions;
    newQuestions.push(deepClone(questionObject));
    this.setState({
      questions: newQuestions
    });
  };

  removeLastQuestion = (index) => {
    let questionObj =  JSON.parse(JSON.stringify( this.state.questions ));
    if(questionObj.length === 1){
      questionObj = [deepClone(questionObject)]
    }else{
      questionObj.splice(index,1)
    }
    this.setState({ questions: questionObj });
  };

  updateQuestion = (newQuestion, questionIndex) => {
    let newState = { ...this.state };
    let question = {
      ...newQuestion,
      answersState: this.getOptionsStateVal(newQuestion.answers),
      questionState: this.getQuestionStateVal(newQuestion.question),
      pointsState: this.getPointsStateVal(newQuestion.points),
      answerState: this.getAnswerStateVal(newQuestion.answer)
    };
    newState.questions[questionIndex] = question;
    this.setState(newState);
  };

  // since questionObject is an object, a reference to it is passed down, so when updating
  // make a copy of the object to keep state immutability
  renderQuestions = () => {
    const { classes } = this.props;
    return this.state.questions.map((question, index) => {
      return (
        <GridItem xs={12} sm={12} md={12}>
          <QuestionForm
            question={question}
            questionIndex={index}
            updateQuestion={this.updateQuestion}
            classes={classes}
          />
          <Button onClick={ () => this.removeLastQuestion(index)} fullWidth color="primary">
              - Remove Question
            </Button>
        </GridItem>
      );
    });
  };

  render() {
    return (
      <GridContainer direction={"row"} wrap={"wrap"}>
        {this.renderQuestions()}
        <GridItem xs={12} sm={12} md={12}>
          <Button onClick={this.createNewQuestion} fullWidth color="primary">
            + Add Question
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(QuestionsForm);
