import React from "react";

// @material-ui/core components
import { withStyles, Paper, Icon } from "@material-ui/core";
import {
  HelpOutline,
  CameraAltOutlined,
  TuneOutlined
} from "@material-ui/icons";
import Navigator from "assets/img/nav_arrow.png";
import Leaders from "assets/img/Leaders_Empty.png";
import Logo from "assets/img/Logo.png";
import Play from "assets/img/Play_Full.png";
import Profile from "assets/img/Profile_Empty.png";
import { Events } from "../../constants/Event";

import mobileViewStyle from "assets/jss/material-dashboard-pro-react/components/mobileViewStyle.jsx";

function MobilePreview({ ...props }) {
  const { data, previewType, classes } = props;

  return (
    <Paper className={classes.mainContainer}>
      <Paper className={classes.headerContainer}>
        <HelpOutline />
        <div className={classes.lastItem}>
          <img src={Logo} style={{ width: "30px", height: "30px" }} />
        </div>
        <div className={classes.lastItem}>
          <CameraAltOutlined />
        </div>
      </Paper>
      <div style={{ display: "flex" }}>
        <Paper className={classes.iconContainer}>
          <img src={Navigator} style={{ width: "20px", height: "20px" }} />
        </Paper>
        <div className={classes.lastItem}>
          <Paper
            className={classes.iconContainer}
            style={{ paddingBottom: "0" }}
          >
            <TuneOutlined style={{ width: "20px", height: "20px" }} />
          </Paper>
        </div>
      </div>
      <Paper className={classes.bodyContainer}>
        {data && data.eventAction === Events.PHOTO_UPLOAD.value && (
          <div style={{ height: "calc(100% - 40px)" }}>
            <div style={{ height: "100%" }}>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ padding: "5px" }}>
                  {data.actionData.location_title}
                </div>
                <div className={classes.pointsContainer}>
                  {data.actionData.points} points
                </div>
              </div>
              <div style={{ padding: "5px" }}>{data.actionData.address}</div>

              <div className={classes.imageContainer}>
                <img src={data.actionData.asset} />
              </div>
              <div className={classes.eventTitleContainer}>
                {data.actionData.title}
              </div>
              <div className={classes.descriptionContainer}>
                {data.actionData.description}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Paper className={classes.buttonContainer}>Take Photo</Paper>

              <Paper className={classes.buttonContainer}>Close</Paper>
            </div>
          </div>
        )}
        {data && data.eventAction === Events.QUIZ.value && (
          <div style={{ height: "calc(100% - 40px)" }}>
            <div style={{ height: "100%" }}>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ padding: "5px" }}>
                  {data.actionData.overview.title}
                </div>
                <div className={classes.pointsContainer}>
                  {data.actionData.questions.questions[0].points} points
                </div>
              </div>
              <div style={{ padding: "5px" }}>
                {data.actionData.overview.address}
              </div>

              <div className={classes.imageContainer}>
                <img src={data.actionData.overview.asset} />
              </div>
              <div>{data.actionData.questions.questions[0].question}</div>
              {data.actionData.questions.questions[0].answers.map(answer => (
                <div className={classes.answerContainer}>{answer}</div>
              ))}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Paper className={classes.buttonContainer}>Finish</Paper>

              <Paper className={classes.buttonContainer}>Close</Paper>
            </div>
          </div>
        )}
        {data && data.eventAction === Events.CHECK_IN.value && (
          <div style={{ height: "calc(100% - 40px)" }}>
            <div style={{ height: "100%" }}>
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ padding: "5px" }}>
                  {data.actionData.overview.location_title}
                </div>
                <div className={classes.pointsContainer}>
                  {data.actionData.overview.points} points
                </div>
              </div>
              <div style={{ padding: "5px" }}>
                {data.actionData.overview.address}
              </div>

              <div className={classes.imageContainer}>
                <img src={data.actionData.overview.asset} />
              </div>
              <div className={classes.eventTitleContainer}>
                {data.actionData.overview.title}
              </div>
              <div className={classes.descriptionContainer}>
                {data.actionData.overview.description}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Paper className={classes.buttonContainer}>Check In</Paper>

              <Paper className={classes.buttonContainer}>Close</Paper>
            </div>
          </div>
        )}
        {previewType === "seriesDetails" && (
          <div style={{ height: "calc(100% - 40px)" }}>
            <div style={{ height: "100%" }}>
              <div style={{ padding: "5px" }}>address</div>
              <div className={classes.titleContainer}>{data.seriesName}</div>
              <div className={classes.dateContainer}>
                {data.fromDate && new Date(data.fromDate).toDateString()}
              </div>
              <div className={classes.imageContainer}>
                <img src={data.asset} />
              </div>
              <div className={classes.descriptionContainer}>
                {data.seriesDescription}
              </div>
              <div className={classes.sponsorContainer}>
                Sponsored by: {data.seriesSponsor}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Paper className={classes.buttonContainer}>Exit</Paper>

              <Paper className={classes.buttonContainer}>Join Event</Paper>
            </div>
          </div>
        )}
      </Paper>
      <Paper className={classes.footerContainer}>
        <img
          src={Leaders}
          style={{ width: "30px", height: "30px", padding: "5px" }}
        />
        <div className={classes.lastItem}>
          <img
            src={Play}
            style={{ width: "30px", height: "30px", padding: "5px" }}
          />
        </div>
        <div className={classes.lastItem}>
          <img
            src={Profile}
            style={{ width: "30px", height: "30px", padding: "5px" }}
          />
        </div>
      </Paper>
    </Paper>
  );
}

export default withStyles(mobileViewStyle)(MobilePreview);
