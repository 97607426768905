import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import * as firebase from "firebase";
import CreateGroups from "../Forms/CreateGroups";
import GroupDetails from "../Tables/GroupDetails";
import AlertModal from "../../components/AlertModal.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  titleContainer: {
    display: "flex",
  },
};

class GroupsAndFunctionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: [{}],
      createUserGroup: false,
      updateUserGroup: false,
      viewUserGroup: false,
      groupToUpdate: {},
      alertModal: false,
      deleteConfirmationModal: false,
    };
    this.db = firebase.database();

    this.groupRef = this.db.ref(`group/`);
    this.usersRef = this.db.ref(`users/`);
  }
  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    let dataArr = [];

    this.fetchGroupDataByTableRef(this.groupRef, dataArr);
  };

  fetchGroupDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(this.fetchGroupData(child.key, child.val()));
        }
      });

      this.setState({ tableValues: dataArr });
    });
  };

  fetchGroupData = (key, data) => {
    return {
      id: key,
      title: data.name,
      actions: (
        <div>
          <div className="actions-center">
            <Button
              onClick={() => {
                this.setState({ viewUserGroup: true, groupToUpdate: data });
              }}
              color="primary"
              className="edit"
            >
              view
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({ updateUserGroup: true, groupToUpdate: data });
              }}
              color="warning"
              className="edit"
            >
              edit
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({
                  deleteConfirmationModal: true,
                  groupToUpdate: data,
                });
              }}
              color="danger"
              className="remove"
            >
              archive
            </Button>
          </div>
        </div>
      ),
    };
  };

  removeGroup = () => {
    const { id } = this.state.groupToUpdate;
    let activeUser = false;
    this.usersRef.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val() && child.val().active && child.val().groupid === id) {
          activeUser = true;
        }
      });
    });
    if (!activeUser) {
      this.groupRef.child(id).transaction((transactionGroup) => {
        if (!transactionGroup) {
          return 0;
        }
        this.db
          .ref("removed-group/")
          .child(id)
          .set(transactionGroup);
        return null;
      });
    } else {
      this.handleOpen("alertModal");
    }
    this.handleClose("deleteConfirmationModal");
  };

  handleOpen(modal) {
    this.setState({ [modal]: true });
  }

  handleClose(modal) {
    this.setState({ [modal]: false });
  }

  handleCreateGroup = () => {
    this.setState({ createUserGroup: true });
  };

  onSubmit = () => {
    this.fetchList();
    this.setState({
      createUserGroup: false,
      updateUserGroup: false,
      viewUserGroup: false,
    });
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  render() {
    const { classes } = this.props;
    const {
      updateUserGroup,
      createUserGroup,
      viewUserGroup,
      groupToUpdate,
    } = this.state;
    return (
      <React.Fragment>
        {createUserGroup || updateUserGroup ? (
          <CreateGroups
            isUpdating={updateUserGroup}
            onSubmit={this.onSubmit}
            data={groupToUpdate}
          />
        ) : viewUserGroup ? (
          <GroupDetails onSubmit={this.onSubmit} data={groupToUpdate} />
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Build />
                  </CardIcon>
                  <div className={classes.titleContainer}>
                    <h4 className={classes.cardIconTitle}>
                      Groups and Functions List
                    </h4>
                    <Button
                      color="primary"
                      onClick={this.handleCreateGroup}
                      className="create"
                    >
                      Create User Group
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.tableValues || []}
                    filterable
                    columns={[
                      {
                        Header: "Group Name",
                        accessor: "title",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        <AlertModal
          open={this.state.alertModal}
          handleClose={() => this.handleClose("alertModal")}
          title={"Info"}
          message={"Failed to archive the group as It has active users"}
          buttonText={"OK"}
        />
        <Dialog
          open={this.state.deleteConfirmationModal}
          onClose={() => this.handleClose("deleteConfirmationModal")}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">Alert</DialogTitle>
          <DialogContent style={{ minWidth: "350px" }}>
            Are you sure you want to delete the user group ?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.removeGroup} color="primary">
              Yes
            </Button>
            <Button
              onClick={() => this.handleClose("deleteConfirmationModal")}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(GroupsAndFunctionsList);
