import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";

const EventTable = props => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ padding: "0px" }}>Event No.</TableCell>
          <TableCell style={{ padding: "0px" }}>Start/End Date Time</TableCell>
          <TableCell style={{ padding: "0px" }}>Event Name</TableCell>
          <TableCell style={{ padding: "0px" }}>Event Type</TableCell>
          <TableCell style={{ padding: "0px" }}>Event Location</TableCell>
          <TableCell style={{ padding: "0px" }}>Event Action</TableCell>
          <TableCell style={{ padding: "0px" }}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data &&
          props.data.length > 0 &&
          props.data.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell style={{ padding: "0px" }}>
                  {String(item.eventOrder)}
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  {item.startDate + " - " + item.endDate}
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  {String(item.title)}
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  {String(item.eventType)}
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  {String(item.address)}
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  {item.eventAction || ""}
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  <div style={{ display: "inline" }}>
                    <Button
                      style={{
                        margin: "0px"
                      }}
                      aria-label="Edit"
                      onClick={() => props.handleEditClick(item, index)}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      style={{
                        margin: "0px"
                      }}
                      aria-label="Delete"
                      onClick={() => props.handleDeleteClick(item, index)}
                    >
                      Delete
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default EventTable;
