import React from "react";
import { Redirect } from "react-router-dom";
import { GeoFire } from "geofire";

import Snackbar from "@material-ui/core/Snackbar";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import * as firebase from "firebase";
import POIForm from "./POI/POIForm.jsx";
import { POI } from "../../constants/Common.js";
import POICardDialog from "../Forms/POI/POICardDialog/POICardDialog";

// Firebase API Call
import { fetchPOIQuizData } from "./FirebaseAPI/FirebaseAPI";

class POIWizard extends React.Component {
  uploadPOI = (finalState) => {
    let informationCard =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.informationCard;
    let poiOverview = finalState.overview;
    var user = firebase.auth().currentUser;
    if (this.state.selectedValue === "no") {
      let poiData = {
        address: poiOverview.address,
        title: poiOverview.title,
        points: poiOverview.points,
        description: poiOverview.description,
        lat: poiOverview.latitude,
        lng: poiOverview.longitude,
        location_title: poiOverview.location_title,
        type: "poi",
        classification: poiOverview.classification,
        asset: poiOverview.asset,
        creator: user.uid,
        id: poiOverview.id,
        informationCard: informationCard || {},
        enableInfocard: false,
        available: Boolean(poiOverview.quizIdList.length),
        enable: Boolean(poiOverview.quizIdList.length),
        quizIdList: poiOverview.quizIdList || [],
        created: poiOverview.created || Date.now(),
        modify: Date.now(),
      };
      this.uploadPOIToDatabase(poiData);
    } else if (this.state.selectedValue === "yes") {
      this.state.infoCardData.infoData = finalState.overview;
      let fetchData = {
        id: finalState.overview.id,
        title: finalState.overview.title,
        address: finalState.overview.address,
        points: finalState.overview.points,
        classification: finalState.overview.classification,
        lat: finalState.overview.latitude,
        lng: finalState.overview.longitude,
        location_title: poiOverview.location_title,
        quizIdList: finalState.overview.quizIdList || [],
        asset: finalState.overview.asset,
      };
      let poiData = {
        address: poiOverview.address,
        title: poiOverview.title,
        points: poiOverview.points,
        description: poiOverview.description,
        lat: poiOverview.latitude,
        lng: poiOverview.longitude,
        location_title: poiOverview.location_title,
        type: "poi",
        classification: poiOverview.classification,
        asset: poiOverview.asset,
        creator: user.uid,
        id: poiOverview.id,
        informationCard: {},
        enableInfocard: true,
        available: Boolean(poiOverview.quizIdList.length),
        enable: Boolean(poiOverview.quizIdList.length),
        quizIdList: poiOverview.quizIdList || [],
        reset: true,
        created: finalState.overview.created || Date.now(),
        modify: Date.now(),
      };
      this.setState({
        openCardModel: true,
        quizId: finalState.overview.quizIdList || [],
        resetNavigate: finalState.overview.reset || null,
        fetchData,
        poiResetData: poiData,
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      resultMessage: "Success!",
      shouldRefresh: false,
      enableRefresh: false,
      poiToUpdate: this.props.location && this.props.location.state,
      openCardModel: false,
      navigateNext: false,
      infoCardData: {},
      selectedValue:
        this.props.location && this.props.location.state === undefined
          ? ""
          : this.props.location &&
            this.props.location.state &&
            this.props.location.state.informationCard &&
            (this.props.location &&
              this.props.location.state &&
              this.props.location.state.quizIdList)
          ? "yes"
          : "no",
      quizId: "",
      quizToUpdate: null,
      resetNavigate: null,
      fetchData: null,
      poiResetData: {},
    };
  }

  uploadPOIToDatabase = async (poiData) => {
    let db = firebase.database();

    let poiRef;
    if (this.state.poiToUpdate) {
      if (this.state.poiToUpdate.classification === poiData.classification) {
        poiRef = db.ref(
          `map-items/${this.state.poiToUpdate.classification}/${
            this.state.poiToUpdate.id
          }`
        );
      } else {
        await db
          .ref(
            `map-items/${this.state.poiToUpdate.classification}/${
              this.state.poiToUpdate.id
            }`
          )
          .remove();
        poiRef = db.ref(
          `map-items/${poiData.classification}/${this.state.poiToUpdate.id}`
        );
      }
    } else {
      poiRef = db.ref(`map-items/${poiData.classification}/`).push();
      poiData.id = poiRef.key;
    }

    let currentAsset = this.state.poiToUpdate
      ? this.state.poiToUpdate.asset
      : null;
    if (poiData.asset !== currentAsset) {
      if (typeof poiData.asset === "string") {
        poiData.asset = poiData.asset;
      } else {
        let downloadUrl = await this.uploadPhotoToStorage(poiData);
        if (!downloadUrl) {
          this.setState({
            ...this.state,
            open: true,
            resultMessage: "Error uploading photo",
          });
          return;
        }
        poiData.asset = downloadUrl;
      }
    }
    var firebaseRef = firebase.database().ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(
      `i:${poiData.id}t:${poiData.classification}`,
      [parseFloat(poiData.lat), parseFloat(poiData.lng)]
    );
    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([poiRef.set(poiData)]);
      console.log(result, "RESULT");
      if (poiData.reset !== true) {
        this.setState({
          open: true,
          enableRefresh: true,
          resultMessage:
            "Success! Your Place of Interest was uploaded to the WAM app.",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  uploadPhotoToStorage = async (poi) => {
    let storageRef = firebase.storage().ref();
    var file = poi.asset;
    var metadata = {
      contentType: "image/jpeg",
    };
    var uploadTask = storageRef
      .child("images/" + poi.id + "/" + file.name)
      .put(file, metadata);
    this.setState({
      ...this.state,
      open: true,
      resultMessage: "Uploading file...",
    });
    return new Promise((resolve) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        function(error) {
          console.log("Error:", error);
          resolve(null);
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log("File available at", downloadURL);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false, shouldRefresh: this.state.enableRefresh });
  };

  handleChange = (event) => {
    this.setState({
      ...this.state,
      selectedValue: event.target.value,
    });
  };

  onHandleFetchQuestionDetails = async () => {
    let fetchQuizData = {};
    let infocardDetails = {
      infoCardText: this.state.infoCardData.fieldName,
      infoCardDescription: this.state.infoCardData.descriptionState,
      infoCardFontColor: this.state.infoCardData.fontColor,
      infoCardBackgroundColor: this.state.infoCardData.backgroundColor,
      infoCardCitations: this.state.infoCardData.citations,
      address: this.state.poiResetData.address,
      classification: this.state.poiResetData.classification,
      id: "",
      lat: this.state.poiResetData.lat,
      lng: this.state.poiResetData.lng,
      location_title: this.state.poiResetData.location_title,
      points: this.state.poiResetData.points,
      title: this.state.poiResetData.title,
    };
    let fetchData = this.state.fetchData;
    if (this.state.resetNavigate === null) {
      // await this.uploadPOIToDatabase({
      //   ...this.state.poiResetData,
      //   informationCard: infocardDetails,
      // });
      this.setState({
        navigateNext: true,
      });
    } else if (
      this.state.resetNavigate === true &&
      !Boolean(this.state.quizId.length)
    ) {
      await this.uploadPOIToDatabase({
        ...this.state.poiResetData,
        informationCard: infocardDetails,
      });
      this.setState({
        navigateNext: true,
      });
    } else if (Boolean(this.state.quizId.length)) {
      await this.uploadPOIToDatabase({
        ...this.state.poiResetData,
        informationCard: infocardDetails,
      });
      fetchQuizData = await fetchPOIQuizData(this.state.quizId[0]);
      this.setState({
        ...this.state,
        quizToUpdate: {
          ...fetchQuizData,
          fetchData,
          reset: true,
        },
      });
    }
  };

  render() {
    const { fromGameCard, dataToUpdate, data } = this.props;
    if (this.state.shouldRefresh) {
      return (
        <Redirect
          to={{ pathname: "/auth/lock-screen-page", params: { isFrom: POI } }}
          push={true}
        />
      );
    } else {
      return (
        <GridContainer justify="center">
          {this.state.navigateNext ? (
            <Redirect
              to={{
                pathname: `${`/admin/wizard`}`,
                params: { name: this.state.infoCardData },
              }}
              push={true}
            />
          ) : null}
          {this.state.quizToUpdate ? (
            <Redirect
              to={{
                pathname: "/admin/wizard",
                state: this.state.quizToUpdate,
              }}
            />
          ) : null}
          <GridItem xs={8} sm={8}>
            {!this.state.openCardModel ? (
              <Wizard
                validate
                steps={[
                  {
                    stepName: "Places of Interest",
                    stepComponent: POIForm,
                    stepId: "overview",
                    data: fromGameCard
                      ? (dataToUpdate && dataToUpdate.overview) || data
                      : this.state.poiToUpdate,
                    selectedValue: this.state.selectedValue,
                    handleChange: this.handleChange,
                  },
                ]}
                selectedValue={this.state.selectedValue}
                title="Create a Place of Interest"
                subtitle="Create a Place of Interest for the WAM app"
                finishButtonClick={(finalState) =>
                  this.props.handleGameCardPOI
                    ? this.props.handleGameCardPOI(finalState)
                    : this.uploadPOI(finalState)
                }
                color={"info"}
              />
            ) : (
              <POICardDialog
                open={this.state.openCardModel}
                infoCardData={this.state.infoCardData}
                onHandleClose={this.onHandleFetchQuestionDetails}
                poiToUpdate={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.informationCard
                    ? this.props.location.state.informationCard
                    : ""
                }
              />
            )}
          </GridItem>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={this.handleClose}
            open={this.state.open}
            autoHideDuration={3000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.resultMessage}</span>}
            action={[]}
          />
        </GridContainer>
      );
    }
  }
}

export default POIWizard;
