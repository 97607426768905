import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
// @material-ui/icons
import Home from "@material-ui/icons/Build";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import * as firebase from "firebase";
import UpdateApplication from "../Forms/UpdateApplication";
import AlertModal from "../../components/AlertModal.js";
import { sortingAccordingCreation } from "../../utils";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  titleContainer: {
    display: "flex",
  },
};

class EventApplicationsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: [{}],
      createUserGroup: false,
      updateApplication: false,
      viewApplication: false,
      applicationToUpdate: {},
      userId: "",
    };
    this.db = firebase.database();

    this.eventsRef = this.db.ref(`event-sponsor-application/`);
  }
  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    let dataArr = [];

    this.fetchApplicationDataByTableRef(this.eventsRef, dataArr);
  };

  fetchApplicationDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          let userData = child.val();
          Object.keys(userData).map((key) => {
            dataArr.push(this.fetchApplicationData(userData[key], child.key));
          });
        }
      });
      sortingAccordingCreation(dataArr);
      this.setState({ tableValues: dataArr });
    });
  };

  fetchApplicationData = (data, key) => {
    return {
      timestamp: data.eventDetails.dateTime || "",
      id: data.id,
      eventName: data.eventName,
      eventDescription: data.eventDescription,
      eventSponsors: data.eventSponsors,
      eventDetails:
        data.eventDetails &&
        `${new Date(data.eventDetails.dateTime).toDateString()}\n${
          data.eventDetails.location
        }`,
      contactInfo:
        data.contactInfo &&
        data.contactInfo.name +
          "\n" +
          data.contactInfo.email +
          "\n" +
          data.contactInfo.organization +
          "\n" +
          data.contactInfo.contact,
      status:
        data.status === "pending"
          ? "New"
          : data.status === "approved"
          ? "Invited"
          : "Archived",
      actions:
        data.status === "pending" ? (
          <div>
            <div className="actions-center">
              <Button
                onClick={() => {
                  this.setState({
                    updateApplication: true,
                    applicationToUpdate: data,
                    userId: key,
                  });
                }}
                color="warning"
                className="edit"
              >
                edit
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="actions-center">
              <Button
                onClick={() => {
                  this.setState({
                    viewApplication: true,
                    applicationToUpdate: data,
                  });
                }}
                color="primary"
                className="edit"
              >
                view
              </Button>
            </div>
          </div>
        ),
    };
  };
  onSubmit = () => {
    this.fetchList();
    this.setState({ updateApplication: false, viewApplication: false });
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };
  render() {
    const { classes } = this.props;
    const {
      updateApplication,
      viewApplication,
      applicationToUpdate,
      userId,
    } = this.state;
    return (
      <React.Fragment>
        {updateApplication || viewApplication ? (
          <UpdateApplication
            userId={userId}
            onSubmit={this.onSubmit}
            data={applicationToUpdate}
            viewMode={viewApplication}
          />
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Home />
                  </CardIcon>
                  <div className={classes.titleContainer}>
                    <h4 className={classes.cardIconTitle}>
                      Events Application List
                    </h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.tableValues || []}
                    filterable
                    columns={[
                      {
                        Header: "Event Name",
                        accessor: "eventName",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Event Description",
                        accessor: "eventDescription",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Event Details",
                        accessor: "eventDetails",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Event Sponsor",
                        accessor: "eventSponsors",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Contact Information",
                        accessor: "contactInfo",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Action",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(EventApplicationsList);
