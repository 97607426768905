import React, { useState } from "react";

// Material Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ReactTable from "react-table";
import { IconButton, Tooltip, Checkbox } from "@material-ui/core";
import CardIcon from "components/Card/CardIcon.jsx";
// Material UI Icon
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import AddCircle from "@material-ui/icons/AddCircle";

// firebase functionality
import { removeCombileMission } from "../huntsFirebase";

import CreateHunt from "../CreateHunt/CreateHunt";

import "../styles.scss";

const styles = {
  headerStyle: {
    width: 100,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 15,
    color: "#555555",
    textAlign: "center",
    lineHeight: 15,
  },
};

const MissionList = ({
  tableValues,
  openMission,
  setOpenMission,
  huntsId,
  poiTable,
  resetList,
  onHandleViewAction,
  setDeleteRow,
  selectAll,
  setSelectAll,
  checked,
  setChecked,
  resetListData,
  setHuntsId,
  setResetList,
  param,
  backToMainList,
}) => {
  const [removeMission, setRemoveMission] = useState([]);
  let data = tableValues;
  const handleChange = () => {
    setSelectAll(!selectAll);
    var checkedCopy1 = removeMission;
    var checkedCopy = [];
    data.forEach(function(e, index) {
      checkedCopy.push(!selectAll);
      checkedCopy1[index] = {
        id: e.id,
        type: e.type,
        huntId: e.huntsId,
      };
    });
    if (selectAll) {
      setRemoveMission([]);
    }
    setChecked(checkedCopy);
  };

  const handleSingleCheckboxChange = (index, row) => {
    var checkedCopy = checked;
    var checkedCopy1 = removeMission;
    checkedCopy[index] = !checked[index];
    checkedCopy1[index] = {
      id: !checked[index] ? undefined : row.id,
      type: row.type,
      huntId: row.huntsId,
    };
    if (checkedCopy[index] === false) {
      setSelectAll(false);
    }
    setRemoveMission([...checkedCopy1]);
    setChecked([...checkedCopy]);
  };

  const onHandleRemoveAll = () => {
    removeMission.forEach(async (item) => {
      let result = "";
      if (item && item.id) {
        result = await removeCombileMission(item);
      }
      if (result === "success") {
        let original = {
          id: param.huntsId,
          gameplayMode: param.gameMode,
          name: param.huntName,
          huntName: param.huntName,
        };
        let row = { original };
        onHandleViewAction(row);
        setRemoveMission([]);
        setChecked([]);
        setSelectAll(false);
      }
    });
  };

  const getPageData = () => {
    let data = tableValues;
    let array = ["05", 10, 20, 25, 50, 100, 200, 500];
    let newArray = [];
    array.forEach((num) => {
      if (Number(num) <= data.length) {
        newArray.push(num);
      }
    });
    newArray.push(array[newArray.length]);
    return newArray;
  };

  return (
    <GridContainer justify="center" className="mission-list">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <div className="header-style">
              <h4 className="header-text">Mission list</h4>
              <h5 className="sub-header-text">Create and Manage Missions</h5>
            </div>
            <CardIcon
              color="primary"
              onClick={() => backToMainList()}
              className="align-button float-right"
            >
              Back
            </CardIcon>
          </CardHeader>
          <div className="hunts-container">
            <div className="hunts-name-style">Scavenger Hunt Name</div>
            <span>
              <IconButton
                onClick={() => {
                  setOpenMission({
                    value: true,
                    message: param.huntName,
                  });
                  setHuntsId(param.id);
                }}
              >
                <Tooltip title="Add a new mission">
                  <AddCircle />
                </Tooltip>
              </IconButton>
              <Tooltip title="Remove all mission">
                <span>
                  <IconButton
                    disabled={!checked.includes(true)}
                    onClick={onHandleRemoveAll}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          </div>
          <CardBody>
            <ReactTable
              data={tableValues || []}
              style={{ overflow: "inherit" }}
              columns={[
                {
                  Header: (state) => (
                    <Checkbox
                      onChange={() => handleChange(state.sortedData)}
                      checked={selectAll}
                    />
                  ),
                  Cell: (row) => (
                    <Checkbox
                      checked={checked[row.index]}
                      onChange={() => {
                        handleSingleCheckboxChange(row.index, row.original);
                      }}
                    />
                  ),
                  minWidth: 50,
                },
                {
                  Header: "Mission Name",
                  accessor: "missionName",
                  className: "mission-table-cell",
                  headerStyle: styles.headerStyle,
                  headerClassName: "header-center-align",
                  style: { whiteSpace: "normal" },
                  minWidth: 120,
                },
                {
                  Header: "Type",
                  accessor: "type",
                  className: "mission-table-cell",
                  headerStyle: styles.headerStyle,
                  headerClassName: "header-center-align",
                  style: { whiteSpace: "normal" },
                  minWidth: 120,
                },
                {
                  Header: "Points",
                  accessor: "points",
                  className: "mission-table-cell",
                  headerStyle: styles.headerStyle,
                  headerClassName: "header-center-align",
                  style: { whiteSpace: "normal" },
                  minWidth: 120,
                },
                {
                  Header: "Gameplay Mode",
                  accessor: "gameMode",
                  className: "mission-table-cell",
                  headerStyle: styles.headerStyle,
                  headerClassName: "header-center-align",
                  style: { whiteSpace: "normal" },
                  minWidth: 120,
                },
                {
                  Header: "Actions",
                  className: "mission-table-cell",
                  headerStyle: styles.headerStyle,
                  headerClassName: "header-center-align",
                  style: { whiteSpace: "normal" },
                  minWidth: 120,
                  Cell: (row) => (
                    <div>
                      <Tooltip title="Edit the mission">
                        <span>
                          <IconButton
                            disabled={checked.includes(true)}
                            onClick={() => {
                              resetListData(
                                row.original.huntsId,
                                row.original.id,
                                row.original.type,
                                row.original.gameMode,
                                param.huntName || param.name
                              );
                              setOpenMission({
                                value: true,
                                message: param.huntName || param.name,
                              });
                              setHuntsId(row.original.huntsId);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Remove the mission">
                        <span>
                          <IconButton
                            disabled={checked.includes(true)}
                            onClick={() => {
                              setDeleteRow({
                                isOpen: true,
                                message: "Mission used by players",
                              });
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  ),
                  headerStyle: styles.headerStyle,
                },
              ]}
              sortable={false}
              resizable={false}
              defaultPageSize={10}
              showPaginationTop={true}
              showPaginationBottom={false}
              className="-striped -highlight"
              autoResetHiddenColumns={true}
              minRows={0}
              showPageSizeOptions={true}
              pageSizeOptions={getPageData()}
              NoDataComponent={() => (
                <div
                  className="rt-noData"
                  style={{
                    marginTop: 120,
                  }}
                >
                  No rows found
                </div>
              )}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openMission.value && (
        <CreateHunt
          headerTitle={`${
            Boolean(Object.values(resetList).length) ? `Edit` : `Create`
          } Mission`}
          subHeaderTitle={openMission.message || ""}
          close={() => {
            setOpenMission({ value: false, message: "" });
            setResetList({});
          }}
          mission={true}
          huntsId={huntsId}
          poiList={poiTable}
          isReset={Boolean(Object.values(resetList).length) ? true : false}
          resetList={resetList}
          onHandleViewAction={onHandleViewAction}
          setDeleteRow={setDeleteRow}
          missionHuntName={param.huntName}
        />
      )}
    </GridContainer>
  );
};

export default MissionList;
