import React from "react";
import { Redirect } from "react-router-dom";
import { GeoFire } from "geofire";

import Snackbar from "@material-ui/core/Snackbar";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Step1 from "./WizardSteps/Step1.jsx";
import QuestionsForm from "./Questions/QuestionsForm";

import * as firebase from "firebase";
import { QUIZ } from "../../constants/Common.js";

class WizardView extends React.Component {
  uploadQuiz = (finalState) => {
    let poiData = {};
    let quizOverview = finalState.overview;
    let quizToUpdate = this.props.location && this.props.location["state"];
    if (Boolean(this.props.location["params"])) {
      var user = firebase.auth().currentUser;
      let poiOverview = finalState.overview;
      poiData = {
        address: poiOverview.fetchData.address,
        title: poiOverview.fetchData.title,
        points: poiOverview.fetchData.points,
        description: poiOverview.fetchData.description,
        lat: poiOverview.fetchData.lat,
        lng: poiOverview.fetchData.lng,
        location_title: poiOverview.fetchData.location_title,
        type: "poi",
        classification: poiOverview.fetchData.classification,
        asset: Boolean(
          poiOverview.asset.name === poiOverview.fetchData.asset.name
        )
          ? poiOverview.asset
          : poiOverview.fetchData.asset,
        creator: user.uid,
        id: poiOverview.fetchData.id || "",
        informationCard: {
          infoCardText: quizOverview.fetchData.infoCardText,
          infoCardDescription: quizOverview.fetchData.infoCardDescription,
          infoCardCitations: quizOverview.fetchData.infoCardCitations,
          infoCardFontColor: quizOverview.fetchData.infoCardFontColor,
          infoCardBackgroundColor:
            quizOverview.fetchData.infoCardBackgroundColor,
        },
        enableInfocard: true,
        available: true,
        enable: true,
        created: poiOverview.created || Date.now(),
        modify: Date.now(),
      };
      this.uploadPOIToDatabase(poiData);
    }
    let parentPOIDetails = { ...quizOverview.parentPOIDetails };
    var user = firebase.auth().currentUser;
    let quizQuestions = finalState.questions.questions;
    let points = 0;
    quizQuestions.forEach((question) => {
      points += question.points;
    });
    let quiz = {
      address: quizToUpdate ? quizToUpdate.address : quizOverview.address,
      title: quizToUpdate ? quizToUpdate.title : quizOverview.title,
      totalPoints: points,
      description: quizToUpdate
        ? quizToUpdate.description
        : quizOverview.description,
      lat: quizToUpdate ? quizToUpdate.lat : quizOverview.latitude,
      lng: quizToUpdate ? quizToUpdate.lng : quizOverview.longitude,
      location_title: quizToUpdate
        ? quizToUpdate.location_title
        : quizOverview.location_title,
      type: "quiz",
      asset: quizToUpdate ? quizToUpdate.asset : quizOverview.asset,
      creator: user.uid,
      id: quizOverview.id,
      poiId: quizOverview.poiId || quizOverview.fetchData.id,
      poiClassification:
        quizOverview.poiClassification || quizOverview.fetchData.classification,
      created: quizOverview.created ? quizOverview.created : Date.now(),
      modify: Date.now(),
    };
    if (!Boolean(this.props.location["params"])) {
      poiData = {
        id: quizOverview.fetchData.id,
        classification: quizOverview.fetchData.classification,
        available: true,
        enable: true,
        asset: finalState.overview.fetchData.asset || "",
      };
    }
    this.uploadQuizToDatabase(quiz, quizQuestions, poiData, parentPOIDetails);
  };

  removeQuizIdOldPOI = async (poi, parentPOIDetails) => {
    if (
      parentPOIDetails.parentPOIIdData &&
      parentPOIDetails.parentPOIIdData !== poi.id
    ) {
      let db = firebase.database();
      let poiRef;
      poiRef = db.ref(
        `map-items/${parentPOIDetails.classification}/${
          parentPOIDetails.parentPOIIdData
        }`
      );
      let poiRefValue = (await poiRef.once("value")).val();
      let poiData = {
        ...poiRefValue,
        quizIdList: [],
        available: false,
        enable: false,
      };
      try {
        let result = await Promise.all([poiRef.set(poiData)]);
        console.log(result, "RESULT");
      } catch (error) {
        console.log(error);
      }
    }
  };

  uploadQuizIdOnPOIDatabase = async (poiData, quizId, parentPOIDetails) => {
    let db = firebase.database();
    let poiRef;
    poiRef = db.ref(`map-items/${poiData.classification}/${poiData.id}`);
    if (Object.values(poiData).length === 5) {
      let poiRefValue = (await poiRef.once("value")).val();
      poiData = { ...poiRefValue, ...poiData };
    }
    // if (Boolean(poiData["quizIdList"])) {
    //   let isPresent = poiData["quizIdList"].includes(quizId);
    //   !isPresent && poiData["quizIdList"].push(quizId);
    // } else {
    //   poiData["quizIdList"] = [];
    //   poiData["quizIdList"].push(quizId);
    // }
    poiData["quizIdList"] = [quizId];
    try {
      let result = await Promise.all([poiRef.set(poiData)]);
      console.log(result, "RESULT");
      this.removeQuizIdOldPOI(poiData, parentPOIDetails);
    } catch (error) {
      console.log(error);
    }
  };

  uploadPOIToDatabase = async (poiData) => {
    let db = firebase.database();
    let poiRef;
    if (!Boolean(poiData.id)) {
      poiRef = db.ref(`map-items/${poiData.classification}/`).push();
      poiData.id = poiRef.key;
    } else {
      poiRef = db.ref(`map-items/${poiData.classification}/${poiData.id}`);
    }

    if (typeof poiData.asset === "object") {
      let downloadUrl = await this.uploadPhotoToStorage(poiData);
      if (!downloadUrl) {
        this.setState({
          ...this.state,
          open: true,
          resultMessage: "Error uploading photo",
        });
        return;
      }
      poiData.asset = downloadUrl;
    }

    var firebaseRef = firebase.database().ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(
      `i:${poiData.id}t:${poiData.classification}`,
      [parseFloat(poiData.lat), parseFloat(poiData.lng)]
    );
    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([poiRef.set(poiData)]);
      console.log(result, "RESULT");
      this.setState({
        open: true,
        enableRefresh: true,
        resultMessage:
          "Success! Your Place of Interest was uploaded to the WAM app.",
      });
    } catch (error) {
      console.log(error);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      resultMessage: "Success!",
      enableRefresh: false,
      shouldRefresh: false,
      quizToUpdate: this.props.location && this.props.location.state,
    };
  }

  uploadQuizToDatabase = async (quiz, questions, poiData, parentPOIDetails) => {
    let db = firebase.database();

    let quizRef;
    if (this.state.quizToUpdate) {
      quizRef = db.ref(`map-items/quizzes/${this.state.quizToUpdate.id}`);
    } else {
      quizRef = db.ref(`map-items/quizzes/`).push();
      quiz.id = quizRef.key;
    }

    let currentAsset = this.state.quizToUpdate
      ? this.state.quizToUpdate.asset
      : null;
    if (quiz.asset === undefined) {
      quiz.asset = poiData.asset;
    } else if (typeof quiz.asset === "object") {
      let downloadUrl = await this.uploadPhotoToStorage(quiz);
      if (!downloadUrl) {
        this.setState({
          ...this.state,
          open: true,
          resultMessage: "Error uploading photo",
        });
        return;
      }
      quiz.asset = downloadUrl;
    }
    quiz.poiId = poiData.id;
    this.uploadQuizIdOnPOIDatabase(poiData, quiz["id"], parentPOIDetails);
    var firebaseRef = firebase.database().ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);
    let geoFireResult = await geoFire.set(`i:${quiz.id}t:quizzes`, [
      parseFloat(quiz.lat),
      parseFloat(quiz.lng),
    ]);
    var questionsRef = db.ref(`quiz-questions/${quiz.id}`);

    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([
        questionsRef.set(questions),
        quizRef.set(quiz),
      ]);
      //if (result)
      console.log(result, "RESULT");
      this.setState({
        open: true,
        enableRefresh: true,
        resultMessage: "Success! Your quiz was uploaded to the WAM app.",
      });
    } catch (error) {
      console.log(error);
    }
  };

  uploadPhotoToStorage = async (quiz) => {
    let storageRef = firebase.storage().ref();
    var file = quiz.asset;
    var metadata = {
      contentType: "image/jpeg",
    };
    var uploadTask = storageRef
      .child("images/" + quiz.id + "/" + file.name)
      .put(file, metadata);
    this.setState({
      ...this.state,
      open: true,
      resultMessage: "Uploading file...",
    });
    return new Promise((resolve) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        function(error) {
          console.log("Error:", error);
          resolve(null);
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log("File available at", downloadURL);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false, shouldRefresh: this.state.enableRefresh });
  };

  render() {
    const { fromGameCard, dataToUpdate, data } = this.props;
    const { location } = this.props;
    if (this.state.shouldRefresh) {
      return (
        <Redirect
          to={{ pathname: "/auth/lock-screen-page", params: { isFrom: QUIZ } }}
          push={true}
        />
      );
    } else {
      return (
        <GridContainer justify="center">
          <GridItem xs={8} sm={8}>
            <Wizard
              validate
              steps={[
                {
                  stepName: "Quiz Overview",
                  stepComponent: Step1,
                  stepId: "overview",
                  data: fromGameCard
                    ? (dataToUpdate && dataToUpdate.overview) || data
                    : this.state.quizToUpdate,
                  historyData: location,
                },
                {
                  stepName: "Quiz Questions",
                  stepComponent: QuestionsForm,
                  stepId: "questions",
                  data: this.state.quizToUpdate,
                },
              ]}
              title="Create a Quiz"
              subtitle="Create a quiz for the WAM app"
              finishButtonClick={(finalState) =>
                this.props.handleGameCardQuiz
                  ? this.props.handleGameCardQuiz(finalState)
                  : this.uploadQuiz(finalState)
              }
              color={"info"}
            />
          </GridItem>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={this.handleClose}
            open={this.state.open}
            autoHideDuration={3000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">{this.state.resultMessage}</span>}
            action={[]}
          />
        </GridContainer>
      );
    }
  }
}

export default WizardView;
