import React, { useRef } from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactTable from "react-table";
import * as firebase from "firebase";
import "./style.scss";

const styles = {
  tablePhoto: {
    maxWidth: "200px",
    maxHeight: "200px",
  },
};

const search = (filter, row) => {
  let rowText = String(row[filter.id]).toUpperCase();
  let searchText = filter.value.toUpperCase();
  return rowText.indexOf(searchText) > -1;
};

// view approved photos
function PhotoGalleryTable({ ...props }) {
  const { classes } = props;
  const dbRef = useRef(firebase.database()).current;

  let data = props.data.map((row) => {
    row.ImgPath = row.image;
    return row;
  });

  const handleOnDelete = async (rowData) => {
    const { parentKey = "", id = "" } = rowData.original || {};

    if (parentKey && id) {
      await dbRef.ref(`photos/${parentKey}/${id}`).remove();
      await dbRef.ref(`map-items/photos/${id}`).remove();
    }
  };

  return (
    <div>
      <ReactTable
        data={data}
        filterable
        resizable={false}
        getTheadThProps={() => {
          return {
            style: {
              fontSize: 20,
              fontStyle: "normal",
              fontWeight: 400,
              paddingRight: 10,
              paddingLeft: 10,
            },
          };
        }}
        columns={[
          {
            Header: "Username",
            accessor: "username",
            filterMethod: search,
          },
          {
            Header: "Title",
            accessor: "title",
            filterMethod: search,
          },
          {
            Header: "Description",
            accessor: "description",
            filterMethod: search,
          },
          {
            Header: "Feedback",
            accessor: "feedbackObject",
            headerClassName: "header-center-align",
            className: "cell-center-align",
            sortable: false,
            filterable: false,
          },
          {
            Header: "Image",
            accessor: "image",
            Cell: (row) => (
              <div className="column-center-align">
                <img
                  className={classes.tablePhoto}
                  src={row.original.ImgPath}
                />
              </div>
            ),
            sortable: false,
            filterable: false,
            headerClassName: "header-center-align",
          },
          {
            Header: "Points",
            accessor: "points",
            headerClassName: "header-center-align",
            filterMethod: search,
            Cell: (row) => (
              <div className="column-center-align">
                <span>{row.original.points || ""}</span>
              </div>
            ),
          },
          {
            Header: "Delete",
            accessor: "points",
            sortable: false,
            filterable: false,
            headerClassName: "header-center-align",
            Cell: (row) => (
              <div className="column-center-align">
                <IconButton onClick={() => handleOnDelete(row)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ),
          },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className="-striped -highlight"
      />
    </div>
  );
}

export default withRouter(withStyles(styles)(PhotoGalleryTable));
