import Buttons from "views/Components/Buttons.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import Charts from "views/Charts/Charts.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import ErrorPage from "views/Pages/ErrorPage.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import POIMap from "views/Maps/POIMap.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Icons from "views/Components/Icons.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import CreditsPage from "views/Pages/CreditsPage.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Panels from "views/Components/Panels.jsx";
import PricingPage from "views/Pages/PricingPage.jsx";
import RTLSupport from "views/Pages/RTLSupport.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import POIMasterList from "views/Tables/POIMasterList.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import PhotoGallery from "views/Tables/PhotoGallery.jsx";
import SweetAlert from "views/Components/SweetAlert.jsx";
import TimelinePage from "views/Pages/Timeline.jsx";
import Typography from "views/Components/Typography.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import Wizard from "views/Forms/Wizard.jsx";
import POIWizard from "views/Forms/POIWizard.jsx";
import InvalidPermission from "components/InvalidPermission/InvalidPermission.jsx";
import GroupsAndFunctions from "views/Tables/GroupsAndFunctions.jsx";
import UserAccounts from "views/Tables/UserAccounts.jsx";
import Applications from "views/Tables/EventApplications.jsx";
import GameCard from "views/Forms/GameCard/GameCard.jsx";
import Approval from "views/Tables/ApprovalList.jsx";
import Leaderboard from "views/Tables/Leaderboard.jsx";
import GameCardList from "views/Tables/GameCardList.jsx";
import SurveyForm from "views/Forms/Survey";
import ScavengerHunt from "./views/Forms/ScavengerHunt/ScavengerHunt.jsx";
import MissionApprove from "./views/Forms/ScavengerHunt/MissionApprove/MissionApproved.jsx";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import Photo from "@material-ui/icons/Photo";
import Edit from "@material-ui/icons/Edit";
import House from "@material-ui/icons/Home";
import Place from "@material-ui/icons/Place";
import TableIcon from "@material-ui/icons/TableChart";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Build from "@material-ui/icons/Build";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Games from "@material-ui/icons/GamesRounded";

var authRoutes = [
  {
    path: "/login-page",
    name: "Login",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/credits",
    name: "credits",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: CreditsPage,
    layout: "/auth",
  },
  {
    path: "/lock-screen-page",
    name: "Lock Screen Page",
    rtlName: "اقفل الشاشة",
    mini: "LS",
    rtlMini: "هذاع",
    component: LockScreenPage,
    layout: "/auth",
  },
];

var projectAdminRoutes = [
  {
    name: "Points of Place",
    rtlName: "لوحة القيادة",
    icon: Photo,
    subMenu: true,
    views: [
      {
        path: "/regular-tables",
        name: "Photos Review and Approval",
        rtlName: "لوحة القيادة",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/photo-gallery",
        name: "Photo Gallery",
        rtlName: "لوحة القيادة",
        component: PhotoGallery,
        layout: "/admin",
      },
    ],
    isOpen: false,
  },
  {
    name: "Quiz",
    rtlName: "لوحة القيادة",
    icon: "content_paste",
    subMenu: true,
    views: [
      {
        path: "/wizard",
        name: "Quiz Wizard",
        rtlName: "لوحة القيادة",
        component: Wizard,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "Quiz Master List",
        rtlName: "لوحة القيادة",
        component: ReactTables,
        layout: "/admin",
      },
    ],
    isOpen: true,
  },
  {
    name: "Survey",
    rtlName: "لوحة القيادة",
    icon: "content_paste",
    subMenu: false,
    component: SurveyForm,
    isOpen: false,
    path: "/survey-at-city-level",
    layout: "/admin",
  },
  {
    name: "Scavenger Hunt",
    rtlName: "لوحة القيادة",
    icon: Games,
    subMenu: true,
    isOpen: false,
    views: [
      {
        path: "/scavengerHunt",
        name: "Scavenger Hunt",
        rtlName: "لوحة القيادة",
        component: ScavengerHunt,
        layout: "/admin",
      },
      {
        path: "/mission-approved",
        name: "Proof Approval",
        rtlName: "لوحة القيادة",
        component: MissionApprove,
        layout: "/admin",
      },
    ],
  },
  {
    name: "Place of Interest",
    rtlName: "لوحة القيادة",
    icon: Place,
    subMenu: true,
    views: [
      {
        path: "/places-of-interest",
        name: "Place of Interest",
        rtlName: "لوحة القيادة",
        component: POIWizard,
        layout: "/admin",
      },
      {
        path: "/poi-master-list",
        name: "POI Master List",
        rtlName: "لوحة القيادة",
        component: POIMasterList,
        layout: "/admin",
      },
    ],
    isOpen: false,
  },
  {
    name: "Events",
    rtlName: "لوحة القيادة",
    icon: House,
    subMenu: true,
    views: [
      {
        path: "/applications",
        name: "Applications",
        rtlName: "لوحة القيادة",
        component: Applications,
        layout: "/admin",
      },
      {
        path: "/game-card",
        name: "Game Card",
        rtlName: "لوحة القيادة",
        component: GameCard,
        layout: "/admin",
      },
      {
        path: "/gamecard-list",
        name: "Game Card List",
        rtlName: "لوحة القيادة",
        component: GameCardList,
        layout: "/admin",
      },
      {
        path: "/approval",
        name: "Approval",
        rtlName: "لوحة القيادة",
        component: Approval,
        layout: "/admin",
      },
      {
        path: "/leaderboard",
        name: "Leaderboard",
        rtlName: "لوحة القيادة",
        component: Leaderboard,
        layout: "/admin",
      },
    ],
    isOpen: false,
  },
  {
    name: "System Administration",
    rtlName: "لوحة القيادة",
    icon: Build,
    subMenu: true,
    views: [
      {
        path: "/groups-and-functions",
        name: "Groups and Functions",
        rtlName: "لوحة القيادة",
        component: GroupsAndFunctions,
        layout: "/admin",
      },
      {
        path: "/user-accounts",
        name: "User Accounts",
        rtlName: "لوحة القيادة",
        component: UserAccounts,
        layout: "/admin",
      },
    ],
    isOpen: false,
  },
  {
    name: "Log Out",
    rtlName: "لوحة القيادة",
    icon: ExitToAppIcon,
    subMenu: false,
    isOpen: false,
    isLogOut: true,
  },
];

var communityAdminRoutes = [
  {
    path: "/wizard",
    name: "Quiz Wizard",
    rtlName: "لوحة القيادة",
    icon: "content_paste",
    component: Wizard,
    layout: "/admin",
  },
  {
    path: "/regular-tables",
    name: "Photos Review and Approval",
    rtlName: "لوحة القيادة",
    icon: Photo,
    component: RegularTables,
    layout: "/admin",
  },
];

var invalidPermissionRoutes = [
  {
    path: "/wizard",
    name: "Invalid Permission",
    rtlName: "لوحة القيادة",
    icon: "error",
    component: InvalidPermission,
    layout: "/admin",
  },
];

export {
  authRoutes,
  projectAdminRoutes,
  communityAdminRoutes,
  invalidPermissionRoutes,
};
