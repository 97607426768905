import React, { useState } from "react";
import Button from "components/CustomButtons/Button.jsx";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import "../style.scss";
import AlertModal from "../Alert";

const SurveyBody = ({
  model,
  onComplete,
  onHandleClearSurvey,
  elements,
  handlOnSaveData,
}) => {
  const [openAlertModal, setOpenAlertModalsetModel] = useState(false);
  return (
    <div className="center-container-body">
      <Survey.Survey model={model} onComplete={onComplete} />
      <div className="body-button">
        {elements.length && (
          <>
            <Button
              style={{ backgroundColor: "#00acc1" }}
              onClick={() => {
                handlOnSaveData();
                onComplete();
              }}
            >
              Save
            </Button>
            <Button onClick={onHandleClearSurvey}>Clear Survey</Button>
          </>
        )}
      </div>
    </div>
  );
};
export default SurveyBody;
