import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import * as firebase from "firebase";

const localizer = BigCalendar.momentLocalizer(moment);
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: []
    };
  }
  sendState() {
    return this.state;
  }
  isValidated() {
    return true;
  }

  componentDidMount = () => {
    let db = firebase.database();

    let dataArr = [];
    let gameCardsRef = db.ref(`game-cards/`);
    gameCardsRef.on("value", snapshot => {
      snapshot.forEach(child => {
        if (child.val()) {
          dataArr.push(this.fetchApprovalData(child.key, child.val()));
        }
      });

      this.setState({ tableValues: dataArr });
    });
  };

  fetchApprovalData = (key, data) => {
    return {
      id: key,
      name: data.seriesDetails.name,
      description: data.seriesDetails.description,
      sponsor: data.seriesDetails.sponsor,
      fromDate: data.seriesDetails.fromDate,
      toDate: data.seriesDetails.toDate
    };
  };

  render() {
    return (
      <Card>
        <CardBody>
          <BigCalendar
            localizer={localizer}
            events={this.state.tableValues}
            startAccessor={event => new Date(event.fromDate)}
            endAccessor={event => new Date(event.toDate)}
            titleAccessor="name"
          />
        </CardBody>
      </Card>
    );
  }
}
export default withStyles(style)(Calendar);
