import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogActions, Button, DialogTitle } from "@material-ui/core/";

export const AlertModal = ({
  text,
  title,
  open,
  onHandleYes,
  onHandleNo,
  ok,
  cancel,
}) => {
  return (
    <div>
      <Dialog open={open}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{text}</DialogContent>
        <DialogActions>
          <Button onClick={onHandleYes}>{ok}</Button>
          {cancel && <Button onClick={onHandleNo}>{cancel}</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertModal;
