import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { dataTable } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { Redirect } from "react-router-dom";
import * as firebase from "firebase";
import { sortingAccordingCreation } from "../../utils";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizzes: [{}],
      quizToUpdate: null,
    };
    this.db = firebase.database();
    this.quizzesRef = this.db.ref("map-items/quizzes/");
  }

  componentDidMount() {
    this.quizzesRef.on("value", (snapshot) => {
      let quizzes = [];
      snapshot.forEach((child) => {
        let quiz = child.val();
        quizzes.push({
          timestamp: quiz.modify || quiz.created || "",
          id: quiz.id,
          title: quiz.title,
          address: quiz.address,
          points: quiz.totalPoints,
          actions: (
            <div>
              <div className="actions-center">
                <Button
                  onClick={() => {
                    this.editQuizFromPOI(quiz);
                  }}
                  color="warning"
                  className="edit"
                >
                  edit
                </Button>{" "}
                <Button
                  onClick={() => {
                    this.removeQuiz(quiz);
                  }}
                  color="danger"
                  className="remove"
                >
                  remove
                </Button>
              </div>
            </div>
          ),
        });
      });
      sortingAccordingCreation(quizzes);
      this.setState({ ...this.state, quizzes: quizzes });
    });
  }

  removeQuiz = (quiz) => {
    this.quizzesRef.child(quiz.id).transaction((transactionQuiz) => {
      if (!transactionQuiz) {
        return 0;
      }
      this.db
        .ref("removed-quizzes/")
        .child(transactionQuiz.id)
        .set(transactionQuiz);
      if (transactionQuiz.poiId) {
        this.removeQuizFromPOI(transactionQuiz);
      }
      return null;
    });
  };

  editQuizFromPOI = async (quiz) => {
    if (quiz.poiId) {
      let mapRef = this.db.ref(
        `map-items/${quiz.poiClassification}/${quiz.poiId}`
      );
      let mapSnapshot = await (await mapRef.once("value")).val();
      if (!Boolean(mapSnapshot)) {
        let singleQuizRef = this.db.ref(`map-items/quizzes/${quiz.id}`);
        quiz.poiClassification && delete quiz.poiClassification;
        quiz.poiId && delete quiz.poiId;
        singleQuizRef.set(quiz);
      }
    }
    this.setState({
      ...this.state,
      quizToUpdate: quiz,
    });
  };

  removeQuizFromPOI = async (derivedDate) => {
    let mapRef = this.db.ref(
      `map-items/${derivedDate.poiClassification}/${derivedDate.poiId}`
    );
    let mapSnapshot = await (await mapRef.once("value")).val();
    if (mapSnapshot) {
      if (Boolean(mapSnapshot.quizIdList)) {
        delete mapSnapshot.quizIdList;
      }
      mapSnapshot = { ...mapSnapshot, available: false, enable: false };
      mapRef.set(mapSnapshot);
    }
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.state.quizToUpdate ? (
          <Redirect
            to={{
              pathname: "/admin/wizard",
              state: this.state.quizToUpdate,
            }}
          />
        ) : null}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Quiz Master List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.quizzes}
                filterable
                columns={[
                  {
                    Header: "Title",
                    accessor: "title",
                    filterMethod: this.search,
                  },
                  {
                    Header: "Address",
                    accessor: "address",
                    filterMethod: this.search,
                  },
                  {
                    Header: "Points",
                    accessor: "points",
                    filterMethod: this.search,
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ReactTables);
