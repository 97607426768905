import React, { useState, useRef, useEffect, useCallback } from "react";
import { POIs } from "../../../constants/POI";
import { sortingAccordingCreation } from "../../../utils";

// Material Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Avatar from "@material-ui/core/Avatar";
import * as firebase from "firebase";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Snackbar,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";

//Download report
import * as Excel from "exceljs";
import download from "downloadjs";

// User Component
import CreateHunt from "./CreateHunt/CreateHunt.jsx";
import EditHunt from "./EditHunt/EditHunt.jsx";
import ActionComponent from "./ActionComponent/ActionComponent";
import MissionList from "./MissionList/MissionList";
import HuntsLeaderboard from "./HuntsLeaderboard/HuntsLeaderboard";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";

//Hunt firebase functionaity
import {
  getUserDetails,
  enableHuntMission,
  removeHunt,
  rescheduleHunt,
  editHuntMissionData,
} from "./huntsFirebase";

// Material UI Icon
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import ReactTable from "react-table";
import "./styles.scss";

const ScavengerHunt = () => {
  const [tableValues, setTableValues] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openMission, setOpenMission] = useState({ value: false, message: "" });
  const [openMissionList, setOpenMissionList] = useState({
    value: false,
    param: {},
  });

  const [huntsId, setHuntsId] = useState(false);
  const [userList, setUserList] = useState([]);
  const [missionList, setMissionList] = useState([]);
  const [resetList, setResetList] = useState({});
  const [isOpenLeaderboard, setIsOpenLeaderboard] = useState({
    isOpen: false,
    param: {},
  });
  const [deleteRow, setDeleteRow] = useState({
    isOpen: false,
    message: "",
    isOpenSchedule: false,
  });
  const [openSchedule, setOpenSchedule] = useState({
    isOpen: false,
    id: "",
    message: "",
  });
  const [openScheduleEdit, setOpenScheduleEdit] = useState({
    isOpen: false,
    id: "",
    message: "",
    data: null,
  });
  const [resetData, setResetData] = useState({});
  const [downloadReport, setDownloadReport] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [checked, setChecked] = useState([]);

  const [poiTable, setPoiTable] = React.useState([]);

  const db = useRef(firebase.database()).current;

  const accomodationRef = db.ref(`map-items/${POIs.ACCOMODATION.value}/`);
  const attractionRef = db.ref(`map-items/${POIs.ATTRACTION.value}/`);
  const parkRef = db.ref(`map-items/${POIs.PARK.value}/`);
  const restaurantRef = db.ref(`map-items/${POIs.RESTAURANT.value}/`);
  const shoppingRef = db.ref(`map-items/${POIs.SHOPPING.value}/`);

  let loadedClassificationsCount = 0;
  let loadedCount = 0;
  const search = (filter, row) => {
    if (typeof row[filter.id] === "string") {
      let rowText = String(row[filter.id]).toUpperCase();
      let searchText = filter.value.toUpperCase();
      return rowText.indexOf(searchText) > -1;
    } else {
      let rowText = String(
        row[filter.id]["props"]["children"]["props"]["children"]["props"][
          "children"
        ]
      ).toUpperCase();
      let searchText = filter.value.toUpperCase();
      return rowText.indexOf(searchText) > -1;
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchPOIList();
  }, []);

  const filterUserName = useCallback(async (event) => {
    /*if (!verifyLength(event.target.value, 2)) {
      setUserList([]);
      return;
    }*/
    let dataArr = [];
    let profileRef = db.ref(`profiles/`);
    profileRef
      .orderByChild("firstname")
      .startAt(event.target.value)
      .endAt(event.target.value + "\uf8ff")
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          let userData = snapshot.val();
          Object.keys(userData).map((key) => {
            let dataObj = {};
            dataObj = {
              ...userData[key],
              id: key,
              name: userData[key].firstname + " " + userData[key].lastname,
            };
            dataArr.push(dataObj);
          });
        }
        setUserList(dataArr);
      });
  }, []);

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const fetchPOIList = () => {
    let dataArr = [];
    fetchPOIDataByTableRef(accomodationRef, dataArr);
    fetchPOIDataByTableRef(attractionRef, dataArr);
    fetchPOIDataByTableRef(parkRef, dataArr);
    fetchPOIDataByTableRef(restaurantRef, dataArr);
    fetchPOIDataByTableRef(shoppingRef, dataArr);
  };

  const fetchPOIDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(fetchPOIData(child.val()));
        }
      });

      loadedClassificationsCount += 1;
      if (loadedClassificationsCount === 5) {
        setPoiTable(dataArr || []);
      }
    });
  };

  const fetchPOIData = (data) => {
    return {
      id: data.id,
      title: data.title,
      address: data.address,
      points: data.points,
      classification: data.classification,
      lat: data.lat,
      lng: data.lng,
    };
  };

  const fetchData = useCallback(async () => {
    let dataArr = [];
    let userData = {};
    let scavengerHuntRef = db.ref(`scavenger-hunt-items/hunts-list/`);
    await scavengerHuntRef.once("value", (snapshot) => {
      if (snapshot.val()) {
        userData = snapshot.val();
        Object.keys(userData).map((key) => {
          dataArr.push(fetchApplicationData(userData[key], key));
        });
      }
    });
    sortingAccordingCreation(dataArr);
    setTableValues(dataArr || []);
  }, []);

  // Get leadership points to disable enable leaderboard button
  const fetchPoints = async (id) => {
    let scavengerRef;
    scavengerRef = db.ref(`scavenger-hunt-items/GPS-checkin/${id}`);
    const pointsSnapshot = await scavengerRef.once("value");
    if (pointsSnapshot) {
      let huntData = pointsSnapshot.val();
      return { id: id, data: huntData };
    }
  };

  const fetchApplicationData = (userData, key) => {
    let enable =
      Date.now() >= userData.startTime && Date.now() <= userData.endTime;
    return {
      id: key || userData.id,
      owner: userData.owner || "Default",
      timestamp: userData.modified || userData.created || "",
      created: moment(userData.created).format("MM-DD-YYYY HH:mm:ss") || "",
      modified: userData.modified
        ? moment(userData.modified).format("MM-DD-YYYY HH:mm:ss")
        : "",
      city: userData.city || "Martinsville",
      gameplayMode: userData.gameplayMode || "",
      isSwitch: enable && userData.isVisiable ? true : false,
      startTime: userData.startTime,
      endTime: userData.endTime,
      huntName: userData.huntName,
      gameplayTime: userData.gameplayTime,
      points: fetchPoints(userData.id),
      thumbnail: (
        <Avatar
          style={{
            borderRadius: 5,
            height: 50,
            width: 50,
          }}
        >
          {!userData.huntThumbnailUrl ? (
            <img
              src={require("../../../assets/img/surveyThumbnail.png")}
              alt="..."
              style={{
                height: 50,
                width: 50,
              }}
            />
          ) : (
            <img
              src={userData.huntThumbnailUrl}
              alt="..."
              style={{
                height: 50,
                width: 50,
              }}
            />
          )}
        </Avatar>
      ),
      name: (
        <li
          style={{ listStyleType: "none" }}
          onClick={() => {
            let huntName = userData.huntName;
            let key = key || userData.id;
            let gameplayMode = userData.gameplayMode;
            let original = { id: key, gameplayMode, name: huntName };
            let row = { original };
            onHandleViewAction(row, true);
          }}
        >
          <Tooltip title="Leaderboard">
            <span className="hunt-header-name-style">{userData.huntName}</span>
          </Tooltip>
        </li>
      ),
    };
  };

  const onHandleViewAction = async (row, isLeaderboard = false) => {
    const { id, gameplayMode, name, huntName } = row.original;
    const gpsCheckinRef = db.ref(`scavenger-hunt-items/GPS-checkin/${id}/`);
    const photoMissionRef = db.ref(`scavenger-hunt-items/photo-mission/${id}/`);
    fetchMissionList(
      photoMissionRef,
      gpsCheckinRef,
      gameplayMode,
      name,
      isLeaderboard,
      huntName
    );

    if (!isLeaderboard) {
      setOpenMissionList({ value: true, param: { ...row.original } });
    }
  };

  const fetchMissionList = (
    photoMissionRef,
    gpsCheckinRef,
    gameplayMode,
    name,
    isLeaderboard,
    huntName
  ) => {
    let dataArr = [];
    fetchMissionDataByTableRef(
      gpsCheckinRef,
      dataArr,
      gameplayMode,
      name,
      isLeaderboard,
      huntName
    );
    fetchMissionDataByTableRef(
      photoMissionRef,
      dataArr,
      gameplayMode,
      name,
      isLeaderboard,
      huntName
    );
  };

  const fetchMissionDataByTableRef = (
    tableReference,
    dataArr,
    gameplayMode,
    name,
    isLeaderboard,
    huntName
  ) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(fetchMissionData(child.val(), gameplayMode, huntName));
        }
      });

      if (isLeaderboard) {
        onHandleCount(dataArr, name);
      }
      loadedCount += 1;
      if (loadedCount === 2) {
        sortingAccordingCreation(dataArr);
        setMissionList(dataArr);
      }
    });
  };

  const onHandleCount = (leaderBoardData, name) => {
    let dataArray = [];
    leaderBoardData.map((item) => {
      if (item.pointTable) {
        dataArray.push(...Object.values(item.pointTable));
      }
    });
    leaderboardCount(dataArray || [], name);
  };

  const leaderboardCount = (data, name) => {
    let point = {};
    let count = {};
    let main = {};
    data.forEach((x) => {
      if (x.status === "Approved") {
        main[x.userId] = {
          points: (point[x.userId] = (point[x.userId] || 0) + x.points),
          approved: (count[x.userId] = ++count[x.userId] || 1),
          userId: x.userId,
          date: x.date,
        };
      }
    });
    mergeProfileData(Object.values(main), name);
  };

  const mergeProfileData = (leaderData, name) => {
    let dataArray = [];
    let userData;
    leaderData &&
      leaderData.map(async (member) => {
        userData = await getUserDetails(member.userId, member, name);
        dataArray.push(userData);
        sortingAccordingCreation(dataArray);
        setIsOpenLeaderboard({ isOpen: true, param: dataArray });
      });
  };

  const fetchMissionData = (data, gameplayMode, huntName) => {
    return {
      timestamp: data.modify || data.created || "",
      id: data.missionId,
      huntsId: data.huntsId,
      missionName: data.missionName || "",
      type: data.type || "",
      points: data.missionPoint || 0,
      gameMode: gameplayMode || "",
      missionDescription: data.missionDescription,
      missionPoint: data.missionPoint,
      pointTable: data.pending,
      huntsName: huntName,
      actions: (
        <div>
          <Tooltip title="Edit the mission">
            <IconButton
              disabled={Boolean(checked.length)}
              onClick={() => {
                resetListData(data.huntsId, data.missionId, data.type);
                setOpenMission({ value: true, message: data.huntsName });
                setHuntsId(data.huntsId);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove the mission">
            <IconButton
              disabled={!selectAll}
              onClick={() => {
                setDeleteRow({
                  isOpen: true,
                  message: "Mission used by players",
                });
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
    };
  };

  const resetListData = (huntsKey, key, type, mode, huntName) => {
    const resetListRef = db.ref(
      `scavenger-hunt-items/${type}/${huntsKey}/${key}`
    );
    let resetData = {};
    resetListRef.on("value", (snapshot) => {
      resetData = snapshot.val();
    });
    setResetList({ ...resetData, mode, huntName });
  };

  const onHandleEnableHunt = async (rowData, value) => {
    const { id, startTime, endTime } = rowData;
    let switchData = Date.now() >= startTime && Date.now() <= endTime;
    if (switchData) {
      let data = await enableHuntMission(id, value);
      if (data === "success") {
        fetchData();
      }
    } else {
      setDeleteRow({ isOpen: true, message: `Please reschedule the event` });
    }
  };

  const onHandleRemoveHunt = async (id) => {
    await removeHunt(id);
    fetchData();
    setDeleteRow({
      isOpen: true,
      message: "Scavenger hunt game deleted successfully.",
    });
  };

  const onHandleReschedule = (startGame, startDate, endDate) => {
    let data = startGame === "Immediately" ? new Date() : startDate;
    let second = Date.parse(new Date(endDate).toUTCString());
    let first = Date.parse(new Date(data).toUTCString());
    if (!Boolean(data) || !Boolean(endDate) || second < first) {
      setDeleteRow({
        isOpen: true,
        message: "Please select From and To date",
      });
      return;
    }
    if (!Boolean(startGame) || !Boolean(data) || !Boolean(endDate)) return;
    rescheduleHunt(openSchedule.id, data, endDate);
    setOpenSchedule({ isOpen: false, id: "", message: "" });
    fetchData();
  };

  const editHuntMission = async (edit, id) => {
    let data = await editHuntMissionData(id.id);
    let selectTeam = [];
    if (data.gameplayMode === "Team") {
      data.teamList.filter((item) => selectTeam.push(item.id));
    }
    data = {
      ...data,
      missionName: data.huntName,
      missionDescription: data.huntDescription,
      huntWelcomeMessage: data.huntWelcomeMessage,
      selectTeam,
    };
    setResetData(data);
    setOpenModal(true);
  };

  const handleChangeDownloadReport = (event) => {
    let result = [];
    switch (event.target.value) {
      case "weekly":
        const weekly = Date.now() - 7 * 24 * 60 * 60 * 1000;
        result = isOpenLeaderboard.param.filter(
          (number) => number.attentDate > weekly
        );
        break;
      case "monthly":
        const monthly = Date.now() - 30 * 24 * 60 * 60 * 1000;
        result = isOpenLeaderboard.param.filter(
          (number) => number.attentDate > monthly
        );
        break;
      case "total":
        result = isOpenLeaderboard.param;
        break;
      default:
        setDeleteRow({
          isOpen: true,
          message:
            "Nothing to download. Please select a proper download format.",
        });
        break;
    }
    setDownloadReport(event.target.value);
    generateXlsxReport(result, event.target.value);
  };

  const generateXlsxReport = async (result, name) => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`${name} Report`);
    for (const item of result) {
      worksheet.columns = [
        { header: "User Name", key: "userName", width: 20 },
        { header: "Name", key: "name", width: 20 },
        { header: "User Type", key: "userType", width: 20 },
        { header: "Mission Completed", key: "completed", width: 10 },
        { header: "Points", key: "points", width: 10 },
        { header: "Scavenger Hunts Title", key: "huntName", width: 20 },
        { header: "Last Attended Date", key: "attentDate", width: 10 },
      ];
      worksheet.addRow({
        userName: item.userName,
        userType: item.userType,
        name: item.name,
        completed: item.completed,
        huntName: item.huntName,
        points: item.points,
        attentDate: new Date(item.attentDate),
      });
    }
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      download(
        blob,
        `${name} Report` +
          new Date()
            .toLocaleDateString()
            .split("/")
            .join("") +
          ".xlsx"
      );
    });
  };

  const getPageData = () => {
    let data = tableValues;
    let array = ["05", 10, 20, 25, 50, 100, 200, 500];
    let newArray = [];
    array.forEach((num) => {
      if (Number(num) <= data.length) {
        newArray.push(num);
      }
    });
    newArray.push(array[newArray.length]);
    return newArray;
  };
  return (
    <React.Fragment>
      {openMissionList.value ? (
        <MissionList
          tableValues={missionList}
          openMission={openMission}
          setOpenMission={setOpenMission}
          huntsId={huntsId}
          poiTable={poiTable}
          resetList={resetList}
          onHandleViewAction={onHandleViewAction}
          setDeleteRow={setDeleteRow}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          checked={checked}
          setChecked={setChecked}
          resetListData={resetListData}
          setHuntsId={setHuntsId}
          setResetList={setResetList}
          param={openMissionList.param}
          backToMainList={() =>
            setOpenMissionList({
              ...openMissionList,
              value: false,
            })
          }
        />
      ) : (
        <GridContainer justify="center">
          <GridItem item xs={12}>
            {isOpenLeaderboard.isOpen ? (
              <>
                <HuntsLeaderboard
                  param={isOpenLeaderboard}
                  handleChangeDownloadReport={handleChangeDownloadReport}
                  downloadReport={downloadReport}
                  backToMainList={() =>
                    setIsOpenLeaderboard({
                      ...isOpenLeaderboard,
                      isOpen: false,
                    })
                  }
                />
              </>
            ) : (
              <>
                <Card>
                  <CardHeader
                    color="primary"
                    icon
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CardIcon color="rose">
                        <Assignment />
                      </CardIcon>
                      <h4
                        className={{
                          ...cardTitle,
                          marginTop: "15px",
                          marginBottom: "0px",
                        }}
                        style={{
                          color: "#3C4858",
                        }}
                      >
                        Scavenger Hunt
                      </h4>
                    </div>
                    <CardIcon
                      color="primary"
                      onClick={() => setOpenModal(true)}
                      className="align-button"
                      // style={{ marginRight: 0 }}
                    >
                      Add new game
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={tableValues || []}
                      filterable
                      resizable={false}
                      // getTheadThProps={() => {
                      //   return {
                      //     style: {
                      //       fontSize: 16,
                      //       fontStyle: "normal",
                      //       fontWeight: 400,
                      //       paddingRight: 10,
                      //       paddingLeft: 10,
                      //     },
                      //   };
                      // }}
                      // getTbodyProps={() => {
                      //   return {
                      //     style: {
                      //       overflowX: "hidden",
                      //       display: "block",
                      //     },
                      //   };
                      // }}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "name",
                          filterMethod: search,
                          // headerClassName: "header-center-align",
                          // className: "cell-center-align",
                          // style: { whiteSpace: "normal" },
                          //minWidth: 150,
                        },
                        {
                          Header: "Created",
                          accessor: "created",
                          filterMethod: search,
                          // headerClassName: "header-center-align",
                          // className: "cell-center-align",
                          // style: { whiteSpace: "normal" },
                          //minWidth: 100,
                        },
                        {
                          Header: "Modified",
                          accessor: "modified",
                          filterMethod: search,
                          // headerClassName: "header-center-align",
                          // className: "cell-center-align",
                          // style: { whiteSpace: "normal" },
                          //minWidth: 100,
                        },
                        {
                          Header: "City",
                          accessor: "city",
                          filterMethod: search,
                          // headerClassName: "header-center-align",
                          // className: "cell-center-align",
                          // style: { whiteSpace: "normal" },
                          //minWidth: 100,
                        },
                        {
                          Header: "Thumbnail",
                          accessor: "thumbnail",
                          sortable: false,
                          filterable: false,
                          // headerClassName: "header-center-align",
                          // className: "cell-center-align",
                          // style: { whiteSpace: "normal" },
                          //minWidth: 100,
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          // headerClassName: "header-center-align",
                          // className: "cell-center-align",
                          minWidth: 220,
                          // style: { whiteSpace: "normal" },
                          Cell: (row) => (
                            <ActionComponent
                              row={row}
                              openMission={openMission}
                              setOpenMission={setOpenMission}
                              setHuntsId={setHuntsId}
                              onHandleView={() => onHandleViewAction(row)}
                              onHandleEnableHunt={onHandleEnableHunt}
                              onHandleRemoveHunt={onHandleRemoveHunt}
                              setDeleteRow={setDeleteRow}
                              setOpenSchedule={setOpenSchedule}
                              editHuntMission={editHuntMission}
                              onHandleLeaderboard={() =>
                                onHandleViewAction(row, true)
                              }
                              setOpenScheduleEdit={setOpenScheduleEdit}
                            />
                          ),
                        },
                      ]}
                      // PaginationComponent={CustomPagination}
                      // minRows={0}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      // autoResetHiddenColumns={true}
                      // showPageSizeOptions={true}
                      // pageSizeOptions={getPageData()}
                      // NoDataComponent={() => (
                      //   <div
                      //     className="rt-noData"
                      //     style={{
                      //       marginTop: 120,
                      //     }}
                      //   >
                      //     No rows found
                      //   </div>
                      // )}
                      // style={{
                      //   height: 750,
                      //   paddingLeft: 10,
                      //   paddingRight: 10,
                      //   paddingBottom: 20,
                      // }}
                    />
                  </CardBody>
                </Card>
              </>
            )}
          </GridItem>
          {openModal && (
            <CreateHunt
              headerTitle={`${
                Boolean(Object.values(resetData).length) ? `Edit` : `Create`
              } Scavenger Hunt`}
              subHeaderTitle={`${
                Boolean(Object.values(resetData).length)
                  ? resetData.huntName || ""
                  : ""
              }`}
              close={() => {
                setOpenModal(false);
                setResetData([]);
              }}
              accept={fetchData}
              userList={userList}
              filterUserName={filterUserName}
              setUserList={setUserList}
              setDeleteRow={setDeleteRow}
              resetList={resetData}
              isReset={Boolean(Object.values(resetData).length) ? true : false}
            />
          )}
          {openMission.value && (
            <CreateHunt
              headerTitle={`Create Mission`}
              subHeaderTitle={openMission.message || ""}
              close={() => setOpenMission({ value: false, message: "" })}
              mission={true}
              huntsId={huntsId}
              poiList={poiTable}
              setDeleteRow={setDeleteRow}
              onHandleViewAction={onHandleViewAction}
            />
          )}
          {openSchedule.isOpen && (
            <CreateHunt
              headerTitle={`Create Scavenger Hunt`}
              subHeaderTitle={openSchedule.message || ""}
              close={() => setOpenSchedule({ isOpen: false, id: "" })}
              openSchedule={openSchedule}
              onHandleReschedule={onHandleReschedule}
            />
          )}
          {openScheduleEdit.isOpen && (
            <EditHunt
              accept={fetchData}
              data={openScheduleEdit.data}
              close={() =>
                setOpenScheduleEdit({ isOpen: false, id: "", data: null })
              }
            />
          )}
        </GridContainer>
      )}
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        message={deleteRow.message}
        open={deleteRow.isOpen}
        onClose={() => setDeleteRow({ isOpen: false, message: "" })}
        autoHideDuration={5000}
      />
    </React.Fragment>
  );
};

export default ScavengerHunt;
