import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";

class InvalidPermission extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <h3 className={classes.title}>Invalid Permission</h3>
        <h5 className={classes.subtitle}>Error</h5>
        <br />
        The permissions on your administrative account have not been configured
        correctly. Please contact the site administrator to resolve this issue.
      </div>
    );
  }
}

export default withStyles(wizardStyle)(InvalidPermission);
