import React, { useState, useRef } from "react";
import { validImgExtentions } from "../../../../constants/Common";

// Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// Material Core Component
import { IconButton, Tooltip } from "@material-ui/core";
import * as firebase from "firebase";

// Material UI Icon
import { Close } from "@material-ui/icons";
// User Component
import HuntComponent from "../HuntComponent/HuntComponent";

//Hunt firebase functionaity
import { getTeamList } from "../huntsFirebase";

// style sheet
import "../styles.scss";

export const CreateHunt = ({
  headerTitle,
  subHeaderTitle = "",
  close,
  mission = false,
  huntsId,
  poiList,
  userList,
  accept,
  filterUserName,
  setUserList,
  isReset = false,
  resetList = [],
  setDeleteRow,
  onHandleViewAction,
  openSchedule,
  onHandleReschedule,
  missionHuntName,
}) => {
  //console.log("resetList", resetList);
  const [title, setTitle] = useState(isReset ? resetList.missionName : "");
  const [created, setCreated] = useState(resetList.created || Date.now());
  const [titleState, setTitleState] = useState(isReset ? "success" : "");
  const [points, setPoints] = useState(isReset ? resetList.missionPoint : "");
  const [pointStatus, setPointStatus] = useState(isReset ? "success" : "");
  const [description, setDescription] = useState(
    isReset ? resetList.missionDescription : ""
  );
  const [descriptionState, setDescriptionState] = useState(
    isReset ? "success" : ""
  );
  const [welcomeMessage, setWelcomeMessage] = useState(
    isReset ? resetList.huntWelcomeMessage : ""
  );
  const [welcomeMessageState, setWelcomeMessageState] = useState(
    isReset ? "success" : ""
  );
  const [assetState, setAssetState] = useState(isReset ? "success" : "");
  const [assetMessage, setAssetMessage] = useState(isReset ? "Valid file" : "");
  const [switchTab, setSwitchTab] = useState(
    isReset ? (resetList.type === "GPS-checkin" ? false : true) : true
  );
  const [participants, setParticipants] = useState(
    isReset ? resetList.gameplayMode : ""
  );
  const [imageFile, setImageFile] = useState(
    isReset ? resetList.imageFile || {} : {}
  );
  const [startGame, setStartGame] = useState("");
  const [poiParticipants, setPoiParticipants] = useState("");
  const [no, setNo] = useState("");
  const [scheduleComponent, setScheduleComponent] = useState(
    Boolean(openSchedule) ? true : false
  );
  const [fromDate, setFromDate] = useState(
    isReset ? new Date(resetList.startTime) : ""
  );
  const [toDate, setToDate] = useState(
    isReset ? new Date(resetList.endTime) : ""
  );
  const [selectPOI, setSelectPOI] = useState(
    isReset ? resetList.poiData || [] : []
  );
  const [selectTeam, setselectTeam] = useState(
    isReset ? resetList.selectTeam || [] : []
  );
  const [selectTeamId, setselectTeamId] = useState(
    isReset ? resetList.teamList || [] : []
  );
  const [asset, setAsset] = useState(
    isReset ? resetList.huntThumbnailUrl || resetList.missionThumbnailUrl : ""
  );
  const db = useRef(firebase.database()).current;

  const change = (
    event,
    setStateName,
    setStateStatus,
    type,
    stateNameEqualTo
  ) => {
    switch (type) {
      case "length":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          setStateStatus("success");
        } else {
          setStateStatus("error");
        }
        setStateName(event.target.value);
        break;
      case "integer":
        if (verifyInteger(event.target.value)) {
          setStateStatus("success");
        } else {
          setStateStatus("error");
        }
        setStateName(parseInt(event.target.value));
        break;
      default:
        setStateName(event.target.value);
        break;
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyInteger = (value) => {
    return !isNaN(parseInt(value));
  };

  const setAsset1 = async (file) => {
    let valid = true;
    if (file) {
      if (file.size > 1024 * 1000) {
        setAssetMessage(
          "Invalid file. File size is too large, maximum file size should be 1MB"
        );
        valid = false;
      } else {
        // validate file type
        var currentExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        var found = validImgExtentions.find(function(element) {
          return element == (currentExtension || "").toLowerCase();
        });
        if (!found) {
          setAssetMessage("Invalid file type");
          valid = false;
        }
      }
    }

    if (valid) {
      setAssetState("success");
      setAssetMessage("Valid file");
      let size = (file.size / 1024 / 1024).toFixed(2) + " MB";
      let type = getExtension(file.type);
      setImageFile({ ...file, size, type });
    } else {
      setAssetState("error");
    }
    setAsset(file);
  };

  const uploadPhotoToStorage = async (file) => {
    let storageRef = firebase.storage().ref();
    var metadata = {
      contentType: "image/jpeg",
    };
    var uploadTask = storageRef
      .child("images/" + Date.now() + "/" + file.name)
      .put(file, metadata);
    return new Promise((resolve) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        function(error) {
          resolve(null);
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const onClearVariables = (switchTab) => {
    setTitle("");
    setCreated("");
    setTitleState("");
    setPoints("");
    setPointStatus("");
    setDescription("");
    setDescriptionState("");
    setWelcomeMessage("");
    setWelcomeMessageState("");
    setAssetState("");
    setAssetMessage("");
    setSwitchTab(switchTab);
    setNo("");
    setScheduleComponent(false);
    setParticipants("");
    setSelectPOI("");
    setStartGame("");
    setselectTeam([]);
  };

  const handleChange = (event) => {
    setParticipants(event.target.value);
    if (event.target.value !== "Team") {
      setselectTeam([]);
      setselectTeamId([]);
    }
    if (setUserList) setUserList([]);
  };

  const handleChangeStartGame = (event) => {
    setStartGame(event.target.value);
    setFromDate("");
    setToDate("");
  };

  const isValidated = () => {
    if (
      titleState === "success" &&
      descriptionState === "success" &&
      welcomeMessageState === "success" &&
      assetState === "success"
    ) {
      return true;
    } else {
      if (titleState !== "success") {
        setTitleState("error");
      }
      if (descriptionState !== "success") {
        setDescriptionState("error");
      }
      if (welcomeMessageState !== "success") {
        setWelcomeMessageState("error");
      }
      if (assetState !== "success") {
        setAssetState("error");
      }
    }
    return false;
  };

  const isValidated1 = () => {
    if (
      titleState === "success" &&
      descriptionState === "success" &&
      pointStatus === "success" &&
      assetState === "success"
    ) {
      return true;
    } else {
      if (titleState !== "success") {
        setTitleState("error");
      }
      if (descriptionState !== "success") {
        setDescriptionState("error");
      }
      if (pointStatus !== "success") {
        setPointStatus("error");
      }
      if (assetState !== "success") {
        setAssetState("error");
      }
    }
    return false;
  };

  const onHandleStoreHuntData = () => {
    if (!Boolean(mission) && isValidated()) {
      onHandleNext();
      retriveTeamMember();
    }
  };

  const onHandleNext = () => {
    setNo("1");
    setScheduleComponent(false);
  };

  const onHandleSelectAddress = (event) => {
    const { location_title, address, lat, lng } = event.target.value;
    setSelectPOI({ title: event.target.value });
  };

  const handleOnSave = (err) => {
    if (err) {
      console.log("alert");
    }
    console.log("success");
  };

  const onHandleCreateMissionOne = async () => {
    let downloadUrl = "";
    setDeleteRow({
      isOpen: true,
      message: "Please wait ... Uploading data ...",
    });
    if (typeof asset === "string") {
      downloadUrl = asset;
    } else {
      downloadUrl = await uploadPhotoToStorage(asset);
    }
    if (mission && isValidated1()) {
      if (!switchTab) {
        setNo("1");
      } else if (switchTab) {
        if (isReset) {
          onHandleSaveResetData(downloadUrl);
        } else {
          onHandleMissionSave(downloadUrl);
        }
      }
    }
  };

  const onHandleGPSCheckin = async () => {
    let downloadUrl = "";
    setDeleteRow({
      isOpen: true,
      message: "Please wait ... Uploading data ...",
    });
    if (typeof asset === "string") {
      downloadUrl = asset;
    } else {
      downloadUrl = await uploadPhotoToStorage(asset);
    }
    //if (!Boolean(participants)) return;
    if (!Boolean(Object.values(selectPOI).length)) return;
    if (isReset) {
      onHandleSaveResetData(downloadUrl);
    } else {
      onHandleMissionSave(downloadUrl);
    }
  };

  const onHandleSaveResetData = (downloadUrl) => {
    const photoMissionRef = db.ref(
      `scavenger-hunt-items/${resetList.type}/${huntsId}/${resetList.missionId}`
    );
    let missionData = {};
    missionData = {
      missionName: title,
      missionDescription: description,
      missionPoint: points,
      missionThumbnailUrl: downloadUrl,
      missionId: resetList.missionId,
      huntsId,
      poiData: selectPOI,
      type: resetList.type,
      imageFile,
      created,
      modify: Date.now(),
    };
    close();
    let original = {
      id: resetList.huntsId,
      gameplayMode: resetList.mode,
      name: resetList.huntName,
    };
    let row = { original };
    onHandleUpdateHuntsData(points, huntsId, 0);
    photoMissionRef.set(missionData, handleOnSave);
    onHandleViewAction(row);
  };

  const onHandleMissionSave = (downloadUrl) => {
    let missionSelect = switchTab ? `photo-mission` : `GPS-checkin`;
    const missionId = db.ref(`scavenger-hunt-items/${missionSelect}`).push();
    const photoMissionRef = db.ref(
      `scavenger-hunt-items/${missionSelect}/${huntsId}/${missionId.key}`
    );
    let missionData = {};
    missionData = {
      missionName: title,
      missionDescription: description,
      missionPoint: points,
      missionThumbnailUrl: downloadUrl,
      missionId: missionId.key,
      huntsId,
      poiData: selectPOI,
      type: missionSelect,
      imageFile,
      created,
      modify: Date.now(),
    };
    close();
    onHandleUpdateHuntsData(points, huntsId, 1);
    photoMissionRef.set(missionData, handleOnSave);
    let original = {
      id: huntsId,
      gameplayMode: missionSelect,
      name: missionHuntName,
      huntName: missionHuntName,
    };

    let row = { original };
    onHandleViewAction(row, true);
  };

  const onHandleUpdateHuntsData = async (points, huntsId, missionCount) => {
    try {
      const huntsRef = db.ref(`scavenger-hunt-items/hunts-list/${huntsId}/`);
      let pointsSnapshot = await huntsRef.once("value");

      let objectResult = {};
      if (pointsSnapshot) {
        let huntData = pointsSnapshot.val();
        objectResult = {
          ...huntData,
          points: (huntData.points || 0) + points,
          mission: (huntData.mission || 0) + missionCount,
        };
        huntsRef.set(objectResult, handleOnSave);
        setDeleteRow({
          isOpen: true,
          message: `Successfully ${
            missionCount === 0 ? `reset` : `add`
          } the mission.`,
        });
      } else {
        console.log("No user mission point update completed. ");
      }
    } catch (error) {
      console.log("Error getting update mission point and count", error);
    }
  };

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setselectTeam(value);
  };

  const onHandleAcceptfirst = () => {
    if (!Boolean(participants)) return;
    switch (participants) {
      case "Team":
        if (participants === "Team" && !Boolean(selectTeam.length)) {
          return;
        } else {
          setNo("2");
          setScheduleComponent(true);
        }
        break;
      case "Individual Participants":
        setNo("2");
        setScheduleComponent(true);
        break;
    }
  };

  const onHandleAcceptSecond = async () => {
    let downloadUrl = "";
    setDeleteRow({
      isOpen: true,
      message: "Please wait ... Uploading data ...",
    });
    if (typeof asset === "string") {
      downloadUrl = asset;
    } else {
      downloadUrl = await uploadPhotoToStorage(asset);
    }
    let data = startGame === "Immediately" ? new Date() : fromDate;
    if (!Boolean(startGame) || !Boolean(data) || !Boolean(toDate)) return;
    onHandleHuntStore(data, isReset, downloadUrl);
  };

  const onHandleHuntStore = async (data, isReset, downloadUrl) => {
    const huntRef = db.ref(`scavenger-hunt-items/hunts-list/`).push();
    const scavengerHuntRef = db.ref(
      `scavenger-hunt-items/hunts-list/${isReset ? resetList.id : huntRef.key}`
    );
    let originalData = {};
    let active =
      new Date() >= Date.parse(new Date(data).toUTCString()) &&
      new Date() <= Date.parse(new Date(toDate).toUTCString());
    originalData = {
      id: isReset ? resetList.id : huntRef.key,
      huntName: title,
      huntDescription: description,
      huntWelcomeMessage: welcomeMessage,
      huntThumbnailUrl: downloadUrl,
      gameplayMode: participants,
      gameplayTime: startGame,
      isVisiable: active ? true : false,
      teamList: selectTeamId || [],
      created: isReset ? resetList.created : Date.now(),
      modified: isReset ? Date.now() : "",
      startTime: Date.parse(new Date(data).toUTCString()) || "",
      endTime: Date.parse(new Date(toDate).toUTCString()) || "",
      imageFile,
    };

    accept();
    close();
    setDeleteRow({
      isOpen: true,
      message: `Successfully ${
        isReset ? `edit` : `added new`
      }  scavenger hunt.`,
    });
    scavengerHuntRef.set(originalData, handleOnSave);
  };

  const onHandleSelectPlayer = (name, member) => {
    let index = selectTeam.indexOf(name) > -1;
    let uniqueArray = [];
    let uniqueArray1 = [];
    if (index) {
      var result = arrayRemove(selectTeam, name, true);
      var result1 = arrayRemove(selectTeamId, member.id);
      setselectTeam([...result]);
      setselectTeamId([...result1]);
    } else {
      uniqueArray.push(...selectTeam, name);
      setselectTeam([...uniqueArray]);
      uniqueArray1.push(...selectTeamId, {
        id: member.id,
        username: member.username,
        name: member.name,
      });
      setselectTeamId([...uniqueArray1]);
    }
  };

  const arrayRemove = (arr, value, check) => {
    return arr.filter(function(ele) {
      if (check) {
        return ele != value;
      } else {
        return ele.id != value;
      }
    });
  };

  const onHandleRescheduleFunction = () => {
    onHandleReschedule(startGame, fromDate, toDate);
  };

  const getExtension = (exe) => {
    if (exe === "image/png") {
      return "PNG";
    } else if (exe === "image/jpg") {
      return "JPG";
    } else if (exe === "image/jpeg") {
      return "JPEG";
    }
  };

  const retriveTeamMember = async () => {
    let data = await getTeamList();
    let team = [];
    data.forEach((item) => team.push(item.id));
    setselectTeamId(data);
    setselectTeam(team);
  };

  return (
    <GridContainer
      item
      xs={8}
      justify="center"
      className="create-hunt-details"
      style={{ paddingTop: 21, marginTop: 45 }}
    >
      <span className="close-button">
        <Tooltip title="close">
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Tooltip>
      </span>
      <GridItem item xs={11} style={{ marginBottom: !mission ? 40 : 0 }}>
        <div className="header-container">
          <span className="heading-text sanju">{headerTitle}</span>
          {Boolean(subHeaderTitle.length) && (
            <span className="heading-sub-text">{subHeaderTitle}</span>
          )}
        </div>
      </GridItem>
      {mission && (
        <GridContainer item xs={12} className="button-container-flex">
          <li
            className="button-container-flex-button"
            style={{
              backgroundColor: !switchTab ? "#EFEFEF" : "#00acc1",
              color: !switchTab ? "black" : "white",
              left: "-2.5%",
              pointerEvents: isReset ? "none" : "auto",
            }}
            onClick={() => {
              setSwitchTab(true);
              onClearVariables(true);
            }}
          >
            Photo Mission
          </li>
          <li
            className="button-container-flex-button"
            style={{
              backgroundColor: switchTab ? "#EFEFEF" : "#00acc1",
              color: switchTab ? "black" : "white",
              right: "-2.5%",
              pointerEvents: isReset ? "none" : "auto",
            }}
            onClick={() => {
              setSwitchTab(false);
              onClearVariables(false);
            }}
          >
            GPS Checkin Mission
          </li>
        </GridContainer>
      )}

      {no === "" && !Boolean(scheduleComponent) && (
        <GridItem item xs={11}>
          <CustomInput
            success={titleState === "success"}
            error={titleState === "error"}
            labelText={
              <span>{mission ? `Mission Name *` : `Hunt Name *`}</span>
            }
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: title,
              inputProps: {
                maxLength: 100,
              },
              onChange: (event) =>
                change(event, setTitle, setTitleState, "length", 3),
            }}
          />
          <CustomInput
            success={descriptionState === "success"}
            error={descriptionState === "error"}
            labelText={<span>Description *</span>}
            id="description"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: description,
              inputProps: {
                maxLength: 220,
              },
              onChange: (event) =>
                change(event, setDescription, setDescriptionState, "length", 3),
            }}
          />
          <CustomInput
            disabled
            success={
              !mission
                ? welcomeMessageState === "success"
                : pointStatus === "success"
            }
            error={
              !mission
                ? welcomeMessageState === "error"
                : pointStatus === "error"
            }
            labelText={
              <span>{!mission ? `Welcome Message *` : `Points *`}</span>
            }
            id="welcomeMessage"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: !mission ? welcomeMessage : points,
              type: !mission ? `text` : `number`,
              inputProps: {
                maxLength: !mission ? 100 : 10,
              },
              onChange: (event) => {
                if (!mission) {
                  change(
                    event,
                    setWelcomeMessage,
                    setWelcomeMessageState,
                    "length",
                    3
                  );
                } else if (mission) {
                  change(event, setPoints, setPointStatus, "integer", 3);
                }
              },
            }}
          />
          <CustomInput
            success={assetState === "success"}
            error={assetState === "error"}
            labelText={<span>Image upload *</span>}
            id="image upload"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: isReset ? imageFile.name || "" : imageFile.name || "",
              disabled: true,
            }}
          />

          <div className="photo-container">
            <PictureUpload
              defaultAssetUrl={asset}
              setAsset={setAsset1}
              assetState={assetState}
              assetMessage={assetMessage}
            />
            {assetState === "success" && (
              <div className="photo-details">
                <span className="photo-details-text">{`File name :- ${
                  isReset ? imageFile.name || "" : imageFile.name || ""
                }`}</span>
                {
                  <span className="photo-details-text">{`File size :- ${
                    isReset ? imageFile.size || "" : imageFile.size || ""
                  }`}</span>
                }
                <span className="photo-details-text">{`File extension :- ${
                  isReset ? imageFile.type || "" : imageFile.type || ""
                } (PNG,JPG,JPEG) format supported.`}</span>
              </div>
            )}
          </div>
        </GridItem>
      )}

      {no == "1" && (
        <HuntComponent
          switchTab={switchTab}
          header={switchTab ? `Participants` : `Checkin Mode`}
          participants={participants ? participants : `POI Checkin`}
          handleChange={handleChange}
          firstOption={switchTab ? `Individual Participants` : `POI Checkin`}
          secondOption={switchTab ? `Team` : null}
          poiList={poiList || []}
          onHandleSelectAddress={onHandleSelectAddress}
          selectPOI={selectPOI}
          cancelButtonTitle={`Cancel`}
          cancelButtonClick={close}
          acceptButtonTitle={"Next"}
          acceptButtonClick={
            switchTab ? onHandleAcceptfirst : onHandleGPSCheckin
          }
          filterUserName={filterUserName}
          teamList={userList || []}
          selectTeam={selectTeam}
          selectTeamId={selectTeamId || []}
          setselectTeamId={setselectTeamId}
          onHandleSelectPlayer={onHandleSelectPlayer}
        />
      )}

      {scheduleComponent && (
        <HuntComponent
          header={`Start/Stop Game`}
          participants={startGame ? startGame : resetList.gameplayTime}
          handleChange={handleChangeStartGame}
          firstOption={`Immediately`}
          secondOption={`Scheduled`}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          cancelButtonTitle={`Cancel`}
          cancelButtonClick={close}
          acceptButtonTitle={"Create"}
          acceptButtonClick={
            openSchedule ? onHandleRescheduleFunction : onHandleAcceptSecond
          }
          disabled={!Boolean(startGame)}
        />
      )}

      {poiParticipants && (
        <HuntComponent
          switchTab={switchTab}
          participants={participants}
          handleChange={handleChange}
          header={`Participants POI`}
          firstOption={`Individual`}
          secondOption={`Team`}
          cancelButtonTitle={`Cancel`}
          cancelButtonClick={close}
          acceptButtonTitle={"Create"}
          acceptButtonClick={onHandleAcceptfirst}
          teamList={userList || []}
          selectTeam={selectTeam}
        />
      )}

      {!Boolean(no) && !Boolean(scheduleComponent) && (
        <GridItem
          item
          xs={6}
          sm={5}
          style={{ marginTop: 20, marginBottom: 20 }}
          className="button-style"
        >
          <Button
            variant="contained"
            color={`danger`}
            onClick={close}
            style={{ borderRadius: 10 }}
          >
            {`Cancel`}
          </Button>
          <Button
            variant="contained"
            color={!scheduleComponent ? `info` : "primary"}
            onClick={() => {
              if (mission) {
                onHandleCreateMissionOne();
              } else if (!mission) {
                onHandleStoreHuntData();
              }
            }}
            style={{ borderRadius: 10 }}
          >
            {!scheduleComponent ? `Next` : `Create`}
          </Button>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default CreateHunt;
