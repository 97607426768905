import React, { useState } from "react";
import { LEFT_TAB_MENU } from "../constants";
import "../style.scss";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Collapse, Input, TextField, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    background: "black",
  },
  input: {
    color: "white",
  },
};

const SurveyEditPanel = ({ model, setTitle, setDescription, classes }) => {
  const [open, setOpen] = useState();
  const [open1, setOpen1] = useState();
  const handleClick = (index) => {
    setOpen(index);
    setOpen1(!open1);
  };

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      {model &&
        LEFT_TAB_MENU.map((tabItem, index) => (
          <div>
            <div
              onClick={() => handleClick(index)}
              className="left-container-botton-style"
            >
              <h5 className="left-container-botton-style-text">
                {tabItem.name}
              </h5>
              {tabItem.id - 1 === open && open1 ? (
                <ExpandLess fill="red" />
              ) : (
                <ExpandMore fill="red" />
              )}
            </div>
            <Collapse
              in={tabItem.id - 1 === open && open1}
              timeout="auto"
              unmountOnExit
            >
              {tabItem.items.map((subItem) => (
                <div>
                  <div>
                    <span className="narmalText">{subItem.title}</span>
                    <TextField
                      id="TextField1"
                      type="text"
                      variant="outlined"
                      fullWidth={true}
                      name={subItem.title}
                      InputProps={{
                        className: classes.input,
                      }}
                      fullWidth={true}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        model.title = e.target.value;
                      }}
                    />
                    <span className="narmalText">{subItem.Description}</span>
                    <TextField
                      id="TextField2"
                      type="text"
                      variant="outlined"
                      multiline
                      fullWidth={true}
                      InputProps={{
                        className: classes.input,
                      }}
                      rows={3}
                      fullWidth={true}
                      name={subItem.Description}
                      fullWidth={true}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        model.description = e.target.value;
                      }}
                    />
                  </div>
                </div>
              ))}
              <div>
                <Button
                  style={{ backgroundColor: "#2c2c2c" }}
                  fullWidth
                  onClick={() => {
                    setOpen1(!open1);
                  }}
                >
                  <span className="background-Color">Save</span>
                </Button>
              </div>
            </Collapse>
          </div>
        ))}
    </div>
  );
};
export default withStyles(styles)(SurveyEditPanel);
