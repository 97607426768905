import React from "react";

// @material-ui/core components
import { withStyles, Tabs, Tab, TextField } from "@material-ui/core";

// material ui icons
import { Home } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import axios from "axios";
import * as firebase from "firebase";

// style for this view
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  headerContainer: {
    display: "flex",
  },
  titleContainer: {
    fontWeight: "bold",
    marginBottom: "15px",
    marginLeft: "40px",
  },
  fieldsetTitle: {
    fontWeight: "bold",
    marginBottom: "15px",
    marginLeft: "-10px",
  },
  subTitleContainer: {
    fontWeight: "bold",
    marginBottom: "20px",
    textDecoration: "underline",
  },
  bodyContainer: {
    marginBottom: "15px",
    marginLeft: "-45px",
  },
  commentBoxContainer: {
    marginBottom: "15px",
    marginLeft: "-45px",
    height: "150px",
  },
  fieldsetBody: {
    marginBottom: "15px",
    marginLeft: "-19px",
  },
  dataContainer: {
    marginLeft: "15px",
  },
  userCardContainer: {
    background: "#eef0f1",
  },
  buttonContainer: {
    direction: "rtl",
    marginTop: "auto",
    marginBottom: "20px",
  },
  buttonClasses: {
    marginLeft: "10px",
  },
  fieldsetStyle: {
    padding: "5px",
    paddingInlineStart: "16px",
    maxWidth: "95%",
  },
  tablePhoto: {
    maxWidth: "120px",
    maxHeight: "120px",
  },
};

class ApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRefresh: false,
      enableRefresh: false,
      selectedEventTabIndex: 0,
      selectedEvent: this.props.data.eventDetails[0],
      approvalComment: "",
    };
    this.db = firebase.database();
  }

  submitClick = async (archive) => {
    let eventsRef = this.db.ref(`game-cards/${this.props.data.id}`);
    let eventData = { ...this.props.data };
    eventData.approvalComment = this.state.approvalComment;
    if (archive) eventData.status = "rejected";
    else eventData.status = "approved";
    try {
      let result = await Promise.all([eventsRef.set(eventData)]);
      console.log(result, "RESULT");
      this.setState({
        ...this.state,
        open: true,
        enableRefresh: true,
        resultMessage: "Success! Your Response was uploaded to the WAM app.",
      });
      this.props.onSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  onRevisionClick = () => {
    let url = new URL(
      `https://us-central1-we-are-martinsville-8433f.cloudfunctions.net/sendNewUserInvite?dest=
      ${this.props.data.gameMaker.email}&data=${this.state.approvalComment}`
    );
    axios
      .post(url, "", { headers: { Accept: "*/*" } })
      .then((response) => {
        console.log(response);
        this.setState({
          ...this.state,
          open: true,
          enableRefresh: true,
          resultMessage: "Success! Your Response was sent to the User.",
        });
        this.props.onSubmit();
      })
      .catch((error) => console.log(error));
  };

  handleChangeEventTab = (event, value) => {
    if (value !== this.state.selectedEventTabIndex) {
      this.setState({
        selectedEventTabIndex: value,
        selectedEvent: this.props.data.eventDetails[value],
      });
    }
  };
  goBack = () => {
    this.props.onSubmit();
  };
  render() {
    const { classes, data } = this.props;
    const { selectedEventTabIndex, selectedEvent } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <div style={{ textAlign: "right" }}>
              <Button
                color="danger"
                className={this.finishButtonClasses}
                onClick={this.goBack}
              >
                Back
              </Button>
            </div>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Home />
              </CardIcon>
              <div className={classes.headerContainer}>
                <h4 className={classes.cardIconTitle}>Event | Approval</h4>
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer className={classes.dataContainer}>
                <GridItem xs={12} className={classes.subTitleContainer}>
                  Series Details
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Series Name
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.seriesDetails.name}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Series Description
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.seriesDetails.description}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Series Sponsor(s)
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.seriesDetails.sponsor}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Date Duration
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {new Date(data.seriesDetails.fromDate).toDateString() +
                    " " +
                    new Date(data.seriesDetails.fromDate).toLocaleTimeString() +
                    " to " +
                    new Date(data.seriesDetails.toDate).toDateString() +
                    " " +
                    new Date(data.seriesDetails.toDate).toLocaleTimeString()}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Number of Events
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.seriesDetails.numberOfEvents}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Series Headquarters
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.seriesDetails.headquarter}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Official Logo/Theme
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  <div>
                    <img
                      className={classes.tablePhoto}
                      src={data.seriesDetails.asset}
                    />
                  </div>
                </GridItem>
              </GridContainer>

              <GridContainer className={classes.dataContainer}>
                <GridItem xs={12} className={classes.subTitleContainer}>
                  Event Details
                </GridItem>
                <GridItem xs={12}>
                  <fieldset className={classes.fieldsetStyle}>
                    <legend style={{ maxWidth: "35%" }}>
                      <Tabs
                        value={selectedEventTabIndex}
                        onChange={this.handleChangeEventTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        {data.eventDetails.map((event, index) => {
                          return (
                            <Tab
                              key={index}
                              style={{ maxWidth: "5%" }}
                              label={`Event ${index + 1}`}
                            />
                          );
                        })}
                      </Tabs>
                    </legend>
                    <GridContainer className={classes.dataContainer}>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Event Name
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {selectedEvent.name}
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Event Location
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {selectedEvent.location}
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Start/End DateTime
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {new Date(data.seriesDetails.fromDate).toDateString() +
                          " " +
                          new Date(
                            data.seriesDetails.fromDate
                          ).toLocaleTimeString() +
                          " to " +
                          new Date(data.seriesDetails.toDate).toDateString() +
                          " " +
                          new Date(
                            data.seriesDetails.toDate
                          ).toLocaleTimeString()}
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Event Photo
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        <div>
                          <img
                            className={classes.tablePhoto}
                            src={selectedEvent.asset}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Event Action
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {selectedEvent.eventAction}
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Task
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {Object.keys(selectedEvent.actionData).map((key) => {
                          return selectedEvent.actionData[key].title;
                        })}
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Requirement
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {Object.keys(selectedEvent.actionData).map((key) => {
                          return selectedEvent.actionData[key].description;
                        })}
                      </GridItem>
                      <GridItem xs={3} className={classes.fieldsetTitle}>
                        Points
                      </GridItem>
                      <GridItem xs={9} className={classes.fieldsetBody}>
                        {Object.keys(selectedEvent.actionData).map((key) => {
                          return selectedEvent.actionData[key].points;
                        })}
                      </GridItem>
                    </GridContainer>
                  </fieldset>
                </GridItem>
              </GridContainer>

              <GridContainer className={classes.dataContainer}>
                <GridItem
                  xs={12}
                  className={classes.subTitleContainer}
                  style={{ marginTop: "10px" }}
                >
                  Game Maker Details
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Name
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.gameMaker.name}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Email Address
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.gameMaker.email}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Contact Number
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.gameMaker.phoneNumber}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Organization
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.gameMaker.organization}
                </GridItem>
                <GridItem xs={3} className={classes.titleContainer}>
                  Email Game Card
                </GridItem>
                <GridItem xs={9} className={classes.bodyContainer}>
                  {data.gameMaker.gameCardEmail}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3} className={classes.titleContainer}>
                  Approval Comments
                </GridItem>

                {!this.props.viewMode ? (
                  <GridItem xs={9} className={classes.bodyContainer}>
                    <TextField
                      id="approvalComment"
                      fullWidth
                      rows="4"
                      variant="outlined"
                      multiline
                      rowsMax="10"
                      onChange={(event) =>
                        this.setState({ approvalComment: event.target.value })
                      }
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={9} className={classes.bodyContainer}>
                    {data.approvalComment}
                  </GridItem>
                )}
              </GridContainer>

              <GridContainer style={{ justifyContent: "flex-end" }}>
                {!this.props.viewMode && (
                  <GridItem xs={12} className={classes.buttonContainer}>
                    <Button
                      color="info"
                      className={classes.buttonClasses}
                      onClick={() => this.submitClick(false)}
                    >
                      Approved
                    </Button>
                    <Button
                      disabled
                      color="info"
                      className={classes.buttonClasses}
                      onClick={() => this.onRevisionClick()}
                    >
                      For Revision
                    </Button>
                    <Button color="info" onClick={() => this.submitClick(true)}>
                      Rejected
                    </Button>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ApprovalForm);
