import React, { Component } from "react";
// @material-ui/core components
import {
  withStyles,
  Select,
  InputLabel,
  FormControl,
  MenuItem
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/icons
import { MyLocation } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PlacesInput from "components/PlacesInput/PlacesInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import GameCardModal from "components/GameCardModal/GameCardModal.jsx";
import MobilePreview from "components/MobilePreview/MobilePreview.jsx";
import WizardView from "../../../views/Forms/Wizard.jsx";
import POIWizard from "../../../views/Forms/POIWizard.jsx";
import PhotoUploadWizard from "../../../views/Forms/PhotoUploadWizard.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { validImgExtentions } from "../../../constants/Common";
import EventTable from "./EventTable";
import { Events } from "../../../constants/Event";

const style = {
  headerText: {
    textDecoration: "underline",
    fontWeight: "bold"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  dateLabel: {
    color: "#AAAAAA !important",
    fontSize: "14px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    marginRight: "10px",
    float: "left"
  },
  ...customSelectStyle
};

class EventDetails extends Component {
  constructor(props) {
    super(props);
    const data = this.formatData(props.data || []);
    this.state = {
      title: data.title || "",
      titleState: this.getInitialStateVal(data.title),
      address: data.address || "",
      addressState: this.getInitialStateVal(data.address),
      lat: data.lat || "",
      latState: this.getInitialStateVal(data.lat),
      lng: data.lng || "",
      lngState: this.getInitialStateVal(data.lng),
      eventOrder: data.eventOrder || "",
      eventOrderState: this.getInitialStateVal(data.eventOrder),
      eventType: data.eventType || "completion-based",
      eventTypeState: "success",
      asset: data.asset || "",
      assetState: this.getInitialStateVal(data.asset),
      startDate: data.startDate || null,
      startDateState: this.getInitialStateVal(data.startDate),
      endDate: data.endDate || null,
      endDateState: this.getInitialStateVal(data.endDate),
      startDateHelpText: data.startDateHelpText || "",
      endDateHelpText: data.endDateHelpText || "",
      eventAction: data.eventAction || "",
      eventActionState: this.getInitialStateVal(data.eventAction),
      actionData: data.actionData || [],
      addEvent: true,
      eventArray: data || [],
      photoUploadModal: false,
      quizModal: false,
      checkInModal: false,
      showPreview: false
    };
  }

  formatData = data => {
    let formatedData = [];
    data.forEach(item => {
      formatedData.push({
        title: item.name,
        address: item.location,
        lat: item.latitude,
        lng: item.longitude,
        eventOrder: item.eventOrder,
        eventType: item.eventType,
        asset: item.asset,
        startDate: item.fromDate,
        endDate: item.toDate,
        actionData: (item.actionData && Object.values(item.actionData)) || [],
        eventAction: item.eventAction
      });
    });
    return formatedData;
  };

  getInitialStateVal(stateElement) {
    if (stateElement && stateElement !== "") {
      return "success";
    }
    return "";
  }

  resetEventForm = () => {
    const { allStates } = this.props;
    this.setState({
      title: "",
      address: "",
      lat: "",
      lng: "",
      eventOrder: "",
      eventType: "completion-based",
      eventTypeState: "success",
      startDate:
        (allStates.seriesDetails && allStates.seriesDetails.fromDate) || null,
      startDateState:
        allStates.seriesDetails && allStates.seriesDetails.fromDate
          ? "success"
          : "",
      endDate:
        (allStates.seriesDetails && allStates.seriesDetails.toDate) || null,
      endDateState:
        allStates.seriesDetails && allStates.seriesDetails.toDate
          ? "success"
          : "",
      startDateHelpText: "",
      endDateHelpText: "",
      eventAction: "",
      actionData: [],
      addEvent: true,
      photoUploadModal: false,
      quizModal: false,
      checkInModal: false
    });
  };

  setAddressState = addressState => {
    this.setState({
      ...this.state,
      addressState: addressState
    });
  };

  choosePlace = (location_title, address, lat, lng) => {
    this.setState({
      ...this.state,
      location_title: location_title,
      address: address,
      lat: lat,
      latState: "success",
      lng: lng,
      lngState: "success"
    });
  };

  isValidated = checkLocally => {
    if (!checkLocally && this.state.eventArray.length) {
      return true;
    }
    if (
      this.state.titleState === "success" &&
      this.state.eventOrderState === "success" &&
      this.state.endDateState === "success" &&
      this.state.startDateState === "success" &&
      this.state.eventTypeState === "success" &&
      this.state.latState === "success" &&
      this.state.lngState === "success" &&
      this.state.addressState === "success" &&
      this.state.assetState === "success" &&
      this.state.eventActionState === "success"
    ) {
      if (!checkLocally && !this.state.eventArray.length) {
        return false;
      }
      return true;
    } else {
      if (this.state.titleState !== "success") {
        this.setState({ titleState: "error" });
      }
      if (this.state.eventOrderState !== "success") {
        this.setState({ eventOrderState: "error" });
      }
      if (this.state.eventTypeState !== "success") {
        this.setState({ eventTypeState: "error" });
      }
      if (this.state.assetState !== "success") {
        this.setState({ assetState: "error" });
      }
      if (this.state.latState !== "success") {
        this.setState({ latState: "error" });
      }
      if (this.state.lngState !== "success") {
        this.setState({ lngState: "error" });
      }
      if (this.state.endDateState !== "success") {
        this.setState({
          endDateState: "error",
          endDateHelpText: "Date should be greater than End Date"
        });
      }
      if (this.state.startDateState !== "success") {
        this.setState({
          startDateState: "error",
          startDateHelpText: "Date should be less than Start Date"
        });
      }
      if (this.state.addressState !== "success") {
        this.setState({ addressState: "error" });
      }
      if (this.state.eventActionState !== "success") {
        this.setState({ eventActionState: "error" });
      }
    }
    return false;
  };

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyLatLong(value) {
    const isDecimal = value.match(/^-?\d*(\.\d+)?$/) && value.length > 0;
    return value >= -90.0 && value <= 90 && isDecimal;
  }

  photoUploadClick = () => {
    this.setState({ photoUploadModal: true });
  };

  quizButtonClick = () => {
    this.setState({ quizModal: true });
  };

  checkInButtonClick = () => {
    this.setState({ checkInModal: true });
  };

  verifyInteger(value) {
    return !isNaN(parseInt(value));
  }

  verifyDate(value, compareDate, lessThen) {
    if (lessThen) {
      return compareDate ? value <= compareDate : true;
    } else {
      return compareDate ? value >= compareDate : true;
    }
  }

  sendState() {
    return this.state.eventArray;
  }

  verifyEventOrder = value => {
    const {
      allStates: {
        seriesDetails: { eventNumbers }
      }
    } = this.props;

    if (value <= eventNumbers) {
      if (
        !this.state.eventArray.find(
          event => Number(event.eventOrder) === Number(value)
        )
      ) {
        return true;
      } else if (this.state.eventArray === value) {
        return true;
      }
    }

    return false;
  };

  change(event, stateName, type, stateNameEqualTo, lessThen, helpText) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "decimal":
        if (this.verifyLatLong(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: Number(event.target.value) });
        break;
      case "integer":
        if (this.verifyInteger(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        // eslint-disable-next-line no-case-declarations
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
          this.setState({ [stateName]: "" });
        } else {
          this.setState({ [stateName]: value });
        }
        break;
      case "date":
        if (this.verifyDate(event.valueOf(), stateNameEqualTo, lessThen)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName + "HelpText"]: ""
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "HelpText"]: helpText
          });
        }
        this.setState({ [stateName]: event.valueOf() });
        break;
      case "eventOrder":
        if (
          this.verifyInteger(event.target.value) &&
          this.verifyEventOrder(event.target.value)
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        // eslint-disable-next-line no-case-declarations
        const value1 = parseInt(event.target.value);
        if (isNaN(value1)) {
          this.setState({ [stateName]: "" });
        } else {
          this.setState({ [stateName]: value1 });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  setAsset = file => {
    let valid = true;

    if (file) {
      // validate - file max size should be 500 KB
      // if (file.size > 1000 * 1024) {
      //   this.setState({
      //     assetMessage:
      //       "Invalid file. File size is too large, maximum file size should be 1MB"
      //   });
      //   valid = false;
      // } else {
        // validate file type
        var currentExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        var found = validImgExtentions.find(function(element) {
          return element == (currentExtension || "").toLowerCase();
        });
        if (!found) {
          this.setState({ assetMessage: "Invalid file type" });
          valid = false;
        }
      // }
    }
    if (valid) {
      this.setState({ assetState: "success", assetMessage: "Valid file" });
    } else {
      this.setState({ assetState: "error" });
    }
    this.setState({ ...this.state, asset: file });
  };

  handleDeleteClick = item => {
    this.setState({
      eventArray: this.state.eventArray.filter(
        event => event.eventOrder !== item.eventOrder
      ),
      addEvent: true
    });
  };

  handleEditClick = item => {
    this.setState({
      ...item,
      titleState: this.getInitialStateVal(item.title),
      addressState: this.getInitialStateVal(item.address),
      latState: this.getInitialStateVal(item.lat),
      lngState: this.getInitialStateVal(item.lng),
      eventOrderState: this.getInitialStateVal(item.eventOrder),
      eventTypeState: this.getInitialStateVal(item.eventType),
      assetState: this.getInitialStateVal(item.asset),
      startDateState: this.getInitialStateVal(item.startDate),
      endDateState: this.getInitialStateVal(item.endDate),
      eventActionState: this.getInitialStateVal(item.eventAction),
      addEvent: true
    });
  };

  handleEventSave = () => {
    if (this.isValidated(true)) {
      let event = { ...this.state };
      delete event.eventArray;

      let eventArray = this.state.eventArray.filter(
        evt => evt.eventOrder !== event.eventOrder
      );
      eventArray.push(event);
      this.setState({ eventArray: eventArray, showPreview: true });
      this.resetEventForm();
    }
  };

  handleGameCardQuiz = quiz => {
    this.setState({
      eventAction: Events.QUIZ.value,
      actionData: quiz,
      quizModal: false,
      eventActionState: "success"
    });
  };

  handleGameCardPOI = poi => {
    this.setState({
      eventAction: Events.CHECK_IN.value,
      actionData: poi,
      checkInModal: false,
      eventActionState: "success"
    });
  };

  handleGameCardPhotoUpload = photoUpload => {
    this.setState({
      eventAction: Events.PHOTO_UPLOAD.value,
      actionData: photoUpload.photoUpload,
      photoUploadModal: false,
      eventActionState: "success"
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CardBody>
          {this.state.addEvent && (
            <React.Fragment>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
                  <CustomInput
                    success={this.state.titleState === "success"}
                    error={this.state.titleState === "error"}
                    labelText={<span>Event Name</span>}
                    id="title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.title,
                      inputProps: {
                        maxLength: 50
                      },
                      onChange: event =>
                        this.change(event, "title", "length", 3)
                    }}
                  />
                  <PlacesInput
                    classes={classes}
                    defaultAddress={this.state.address}
                    choosePlace={this.choosePlace}
                    setAddressState={this.setAddressState}
                    descriptionState={this.state.addressState}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer direction="row">
                <GridItem xs={8}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.latState === "success"}
                        error={this.state.latState === "error"}
                        labelText={<span>Latitude</span>}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.lat,
                          type: "number",
                          onChange: event =>
                            this.change(event, "lat", "decimal"),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              <MyLocation
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        success={this.state.lngState === "success"}
                        error={this.state.lngState === "error"}
                        labelText={<span>Longitude</span>}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.lng,
                          type: "number",
                          onChange: event =>
                            this.change(event, "lng", "decimal"),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              <MyLocation
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        success={this.state.eventOrderState === "success"}
                        error={this.state.eventOrderState === "error"}
                        labelText={<span>Event Order</span>}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.eventOrder,
                          type: "eventOrder",
                          onChange: event => {
                            this.change(event, "eventOrder", "eventOrder");
                          }
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        error={this.state.eventTypeState === "error"}
                      >
                        <InputLabel
                          htmlFor="ed-eventType"
                          className={classes.selectLabel}
                          style={{ textTransform: "none" }}
                        >
                          Event Type
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.eventType}
                          onChange={event =>
                            this.change(event, "eventType", "length", 3)
                          }
                          inputProps={{
                            name: "eventType",
                            id: "ed-eventType"
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                            value="completion-based"
                          >
                            Completion-Based
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={"time-based"}
                          >
                            Time-Based
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: "10px" }}>
                      <div className={classes.dateLabel}>Start/End Date:</div>
                      <div
                        style={{
                          display: "flex",
                          float: "left"
                        }}
                      >
                        <DatePicker
                          selected={this.state.startDate}
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          onChange={event =>
                            this.change(
                              event,
                              "startDate",
                              "date",
                              this.state.endDate,
                              true,
                              "Date Should be less than To Date"
                            )
                          }
                        />
                      </div>
                      <div
                        style={{
                          float: "left",
                          width: "30px",
                          textAlign: "center"
                        }}
                      >
                        -
                      </div>
                      <div style={{ display: "flex" }}>
                        <DatePicker
                          selected={this.state.endDate}
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          onChange={event =>
                            this.change(
                              event,
                              "endDate",
                              "date",
                              this.state.startDate,
                              false,
                              "Date Should be less than To Date"
                            )
                          }
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12}>
                      <PictureUpload
                        defaultAssetUrl={this.state.asset}
                        setAsset={this.setAsset}
                        assetState={this.state.assetState}
                        assetMessage={this.state.assetMessage}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={3} style={{ height: "400px" }}>
                  <MobilePreview
                    showPreview={this.state.showPreview}
                    data={
                      this.state.eventArray[this.state.eventArray.length - 1]
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ paddingTop: "10px" }}>
                <GridItem xs={8}>
                  <Button
                    color={
                      this.state.eventAction === Events.PHOTO_UPLOAD.value
                        ? "info"
                        : ""
                    }
                    onClick={() => this.setState({ photoUploadModal: true })}
                  >
                    Photo Upload
                  </Button>
                  <Button
                    color={
                      this.state.eventAction === Events.QUIZ.value ? "info" : ""
                    }
                    onClick={() => this.setState({ quizModal: true })}
                  >
                    Quiz
                  </Button>
                  <Button
                    color={
                      this.state.eventAction === Events.CHECK_IN.value
                        ? "info"
                        : ""
                    }
                    onClick={() => this.setState({ checkInModal: true })}
                  >
                    Check-in
                  </Button>
                </GridItem>
                <GridItem xs={4} style={{ textAlign: "right" }}>
                  <Button
                    color="info"
                    className={this.finishButtonClasses}
                    onClick={() => this.handleEventSave()}
                  >
                    Save Event
                  </Button>
                </GridItem>
              </GridContainer>
            </React.Fragment>
          )}
          <GridContainer>
            <GridItem xs={12}>
              <EventTable
                data={this.state.eventArray}
                handleEditClick={this.handleEditClick}
                handleDeleteClick={this.handleDeleteClick}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <GameCardModal
          handelModalCallback={() => {
            this.setState({ photoUploadModal: !this.state.photoUploadModal });
          }}
          open={this.state.photoUploadModal}
        >
          <PhotoUploadWizard
            fromGameCard={true}
            data={this.state}
            dataToUpdate={
              this.state.eventAction === Events.PHOTO_UPLOAD.value
                ? this.state.actionData
                : {}
            }
            handleGameCardPhotoUpload={this.handleGameCardPhotoUpload}
          />
        </GameCardModal>
        <GameCardModal
          handelModalCallback={() => {
            this.setState({ quizModal: !this.state.quizModal });
          }}
          open={this.state.quizModal}
        >
          <WizardView
            fromGameCard={true}
            data={this.state}
            dataToUpdate={
              this.state.eventAction === Events.QUIZ.value
                ? this.state.actionData
                : {}
            }
            handleGameCardQuiz={this.handleGameCardQuiz}
          />
        </GameCardModal>
        <GameCardModal
          handelModalCallback={() => {
            this.setState({ checkInModal: !this.state.checkInModal });
          }}
          open={this.state.checkInModal}
        >
          <POIWizard
            fromGameCard={true}
            data={this.state}
            dataToUpdate={
              this.state.eventAction === Events.CHECK_IN.value
                ? this.state.actionData
                : {}
            }
            handleGameCardPOI={this.handleGameCardPOI}
          />
        </GameCardModal>
      </React.Fragment>
    );
  }
}
export default withStyles(style)(EventDetails);
