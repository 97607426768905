import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import { Checkbox, Switch, Snackbar, IconButton } from "@material-ui/core";
// core components
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { dataTable } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { Redirect } from "react-router-dom";
import * as firebase from "firebase";
import { POIs } from "../../constants/POI";
import "./styles.scss";
import { sortingAccordingCreation } from "../../utils";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const CustomSwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $bar": {
      opacity: 1,
      backgroundColor: "#1BB394",
    },
  },
  icon: {
    height: 12,
    width: 12,
    borderRadius: 6,
    padding: 0,
    position: "absolute",
    top: 21,
  },
  bar: {
    height: 20,
    width: 34,
    borderRadius: 10,
    backgroundColor: "#DC143C",
    opacity: 1,
  },
  checked: {},
})(Switch);
class POIMasterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: [{}],
      poiToUpdate: null,
      isRedirect: false,
      defaultSwitch: false,
      quizBoolean: true,
      openNotification: false,
    };
    this.loadedClassificationsCount = 0;

    this.db = firebase.database();
    this.quizzArray = [];
    this.accomodationRef = this.db.ref(`map-items/${POIs.ACCOMODATION.value}/`);
    this.attractionRef = this.db.ref(`map-items/${POIs.ATTRACTION.value}/`);
    this.parkRef = this.db.ref(`map-items/${POIs.PARK.value}/`);
    this.restaurantRef = this.db.ref(`map-items/${POIs.RESTAURANT.value}/`);
    this.shoppingRef = this.db.ref(`map-items/${POIs.SHOPPING.value}/`);
    this.quizzesRef = this.db.ref(`map-items/quizzes`);
  }

  componentDidMount() {
    this.fetchQuizList();
    // this.setState({ ...this.state, tableValues: dataArr });
    // this.fetchList();
  }
  fetchQuizList = () => {
    let dataArr1 = [];
    this.quizzesRef.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr1.push(child.val());
        }
      });
      this.quizzArray = dataArr1;
      this.fetchList();
    });
  };
  fetchList = () => {
    let dataArr = [];
    this.loadedClassificationsCount = 0;

    this.fetchPOIDataByTableRef(this.accomodationRef, dataArr);
    this.fetchPOIDataByTableRef(this.attractionRef, dataArr);
    this.fetchPOIDataByTableRef(this.parkRef, dataArr);
    this.fetchPOIDataByTableRef(this.restaurantRef, dataArr);
    this.fetchPOIDataByTableRef(this.shoppingRef, dataArr);
  };

  fetchPOIDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(this.fetchPOIData(child.val(), tableReference));
        }
      });
      sortingAccordingCreation(dataArr);
      this.loadedClassificationsCount += 1;
      if (this.loadedClassificationsCount === 5) {
        this.setState({
          ...this.state,
          tableValues: dataArr,
          openNotification: !Boolean(dataArr.quizAvailable),
        });
      }
    });
  };

  onHandleQuizAvailable = async (boolean, data, fieldname) => {
    if (!Boolean(data.original.quizIdList.length)) return;
    try {
      let updateClassificationRef = this.db.ref(
        `map-items/${data.original.classification}/${data.original.id}`
      );
      let classificationData = (await updateClassificationRef.once(
        "value"
      )).val();
      classificationData[fieldname] = boolean;
      let result = await Promise.all([
        updateClassificationRef.set(classificationData),
      ]);
      this.fetchQuizList();
      console.log(result, "RESULT");
    } catch (error) {
      console.log("error", error);
    }
  };

  fetchPOIData = (data, tableReference) => {
    return {
      timestamp: data.modify || data.created || "",
      id: data.id,
      title: data.title,
      address: data.address,
      points: data.points,
      classification: data.classification,
      quizAvailable: Boolean(data["available"]) ? true : false,
      enableDisableQuiz: Boolean(data["enable"]) ? true : false,
      quizIdList: Boolean(data["quizIdList"]) ? data["quizIdList"] : [],
      actions: (
        <div>
          <div className="actions-center">
            <Button
              onClick={() => {
                let isCheckId = data["quizIdList"] && data["quizIdList"][0];
                let newData = this.quizzArray.filter(
                  (ele) => ele.id === isCheckId
                );
                this.setState({
                  ...this.state,
                  poiToUpdate: {
                    ...data,
                    reset: true,
                    quizIdList: Boolean(newData.length)
                      ? data["quizIdList"]
                      : [],
                  },
                });
              }}
              color="warning"
              className="edit"
            >
              Edit
            </Button>{" "}
            <Button
              onClick={() => {
                this.removePOI(data, tableReference);
                this.fetchList();
              }}
              color="danger"
              className="remove"
            >
              Remove
            </Button>
          </div>
        </div>
      ),
    };
  };

  removePOI = (data, tableReference) => {
    tableReference.child(data.id).transaction((transactionPOI) => {
      if (!transactionPOI) {
        return 0;
      }

      this.db
        .ref("removed-" + data.classification + "/")
        .child(transactionPOI.id)
        .set(transactionPOI);
      if (
        Boolean(transactionPOI.quizIdList && transactionPOI.quizIdList.length)
      ) {
        this.removePOIFromQuiz(transactionPOI.quizIdList[0]);
      }
      return null;
    });
  };

  removePOIFromQuiz = async (quizId) => {
    let quizRef = this.db.ref(`map-items/quizzes/${quizId}`);
    let quizSnapshot = await (await quizRef.once("value")).val();
    if (quizSnapshot) {
      quizSnapshot.poiId && delete quizSnapshot.poiId;
      quizSnapshot.poiClassification && delete quizSnapshot.poiClassification;
      quizSnapshot = { ...quizSnapshot, modify: Date.now() };
      quizRef.set(quizSnapshot);
    }
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  onHandleDefaultSwitch = (boolean) => {
    this.setState({
      ...this.state,
      defaultSwitch: boolean,
      quizBoolean: boolean,
    });
  };

  action = (
    <div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => this.handleClose()}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  handleClose = () => {
    this.setState({ ...this.state, openNotification: false });
  };

  render() {
    const { classes } = this.props;
    if (this.state.isRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/admin/places-of-interest",
          }}
          push={true}
        />
      );
    }
    return (
      <GridContainer>
        {this.state.poiToUpdate ? (
          <Redirect
            to={{
              pathname: "/admin/places-of-interest",
              state: { ...this.state.poiToUpdate, reset: true },
            }}
          />
        ) : null}
        <Toolbar
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1100,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span className="top-text-highlight">
            Want to Make Quizzes Default?
          </span>
          <CustomSwitch
            checked={this.state.quizBoolean}
            onChange={() => this.onHandleDefaultSwitch(!this.state.quizBoolean)}
          />
        </Toolbar>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon className="POIMasterListMainStyle">
              <div style={{ display: "flex" }}>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>POI Master List </h4>
              </div>
              <CardIcon
                color="primary"
                className="addPOIButtonStyle"
                style={{ borderRadius: 10 }}
                onClick={() => {
                  this.setState({
                    isRedirect: true,
                  });
                }}
              >
                <span className="bottonTextStyle">Add POI</span>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.tableValues || []}
                resizable={false}
                filterable
                // getTheadThProps={() => {
                //   return {
                //     style: {
                //       fontSize: 16,
                //       fontStyle: "normal",
                //       fontWeight: 400,
                //       paddingRight: 10,
                //       paddingLeft: 10,
                //     },
                //   };
                // }}
                columns={[
                  {
                    Header: "Title",
                    accessor: "title",
                    filterMethod: this.search,
                    // headerClassName: "header-center-align",
                    // minWidth: 120,
                  },
                  {
                    Header: "Address",
                    accessor: "address",
                    filterMethod: this.search,
                    // headerClassName: "header-center-align",
                    // minWidth: 150,
                  },
                  {
                    Header: "Classification",
                    accessor: "classification",
                    // headerClassName: "header-center-align",
                    // className: "cell-align",
                    // minWidth: 180,
                    Cell: (props) =>
                      (props.value &&
                        POIs[props.value.toUpperCase()] &&
                        POIs[props.value.toUpperCase()].label) ||
                      "",
                    filterMethod: this.search,
                  },
                  {
                    Header: "Points",
                    accessor: "points",
                    filterMethod: this.search,
                    // headerClassName: "header-center-align",
                    // className: "cell-align",
                    // minWidth: 100,
                  },
                  {
                    Header: "Quiz Available Status",
                    accessor: "quizAvailable",
                    sortable: false,
                    filterable: false,
                    // minWidth: 200,
                    // headerClassName: "header-center-align",
                    // className: "cell-align",
                    Cell: (row) => {
                      return (
                        <div>
                          <Checkbox
                            disabled={this.state.quizBoolean}
                            checked={row.original.quizAvailable}
                            color="default"
                            onChange={() => {
                              this.onHandleQuizAvailable(
                                !row.original.quizAvailable,
                                row,
                                "available"
                              );
                            }}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Enable/Disable Quiz",
                    accessor: "enableDisableQuiz",
                    sortable: false,
                    filterable: false,
                    // minWidth: 200,
                    // headerClassName: "header-center-align",
                    // className: "cell-align",
                    Cell: (row) => {
                      return (
                        <div>
                          <CustomSwitch
                            disabled={
                              !row.original.quizAvailable ||
                              this.state.quizBoolean
                            }
                            checked={row.original.enableDisableQuiz}
                            onChange={() =>
                              this.onHandleQuizAvailable(
                                !row.original.enableDisableQuiz,
                                row,
                                "enable"
                              )
                            }
                          />
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    // headerClassName: "header-center-align",
                    // minWidth: 220,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          message="Please complete quizzes on missing POIs"
          open={this.state.openNotification}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          action={this.action}
        />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(POIMasterList);
