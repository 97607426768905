import React, { useState, useEffect, useCallback, useRef } from "react";
import "survey-react/survey.css";
import Assignment from "@material-ui/icons/Assignment";
import ReactTable from "react-table";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Avatar from "@material-ui/core/Avatar";
import Button from "components/CustomButtons/Button.jsx";
import { TextField, Snackbar, Tooltip, IconButton } from "@material-ui/core";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { v4 as uuidv4 } from "uuid";
import SurveyModal from "./SurveyModal";
import { Redirect } from "react-router-dom";
import * as firebase from "firebase";
import moment from "moment";
import "./style.scss";
import Dehaze from "@material-ui/icons/Dehaze";
import FullScreenMap from "../../Maps/FullScreenMap";
import { handleImageUpload } from "../../../utils";
import PlacesInput from "components/PlacesInput/PlacesInput.jsx";
import DateTimePicker from "react-datetime-picker";
import SweetAlert from "react-bootstrap-sweetalert";
import { withStyles } from "@material-ui/core/styles";
import Previews from "./PerviewImage/PreviewImage";
import ActionComponent from "./ActionComponent";
import { sortingAccordingCreation } from "../../../utils";

const styles = {
  root: {
    background: "black",
  },
  input: {
    color: "#fff",
  },
  icon: {
    background: "white",
  },

  root1: {
    background: "#2c2c2c",
    border: 0,
    borderRadius: 3,
    color: "#1ab394",
    height: 30,
  },
  colorSwitchBase: {
    color: "#fff",
  },
  track: { backgroundColor: "green" },
  bar: {
    height: 20,
    width: 34,
    borderRadius: 10,
    // background: "#000000",
  },
  icon: {
    height: 12,
    width: 12,
    borderRadius: 6,
    padding: 0,
    position: "absolute",
    top: 21,
  },
  iconChecked: {
    color: "#20b2aa",
    height: 12,
    width: 12,
    borderRadius: 6,
    position: "absolute",
    top: 21,
  },
};

const SurveyForm = ({ match, classes }) => {
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [surveyName, setSurveyName] = useState("");
  const [tableValues, setTableValues] = useState([{}]);
  const [redirect, setRedirect] = useState(false);
  const [darkMode, setDarkMode] = useState({ boolean: false, id: "" });
  const [moreVert, setMoreVert] = useState({ boolean: false, rowData: "" });
  const [thumbnailUrl, setThumbnailUrl] = React.useState("");
  const [deleteRow, setDeleteRow] = React.useState(false);
  const [address, setAddress] = React.useState({});
  const [addressState, setAddressState] = React.useState("");
  const [currentLocation, setCurrentLocation] = React.useState({});
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");

  const [showAlert, setShowAlert] = React.useState({
    boolean: false,
    message: "",
  });

  const [fence, setFence] = useState([]);
  const uuid = uuidv4();
  const db = useRef(firebase.database()).current;

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchData = useCallback(async () => {
    let dataArr = [];
    const surveyRef = await db.ref(`surveys/`).once("value");

    if (surveyRef.val()) {
      let userData = surveyRef.val();
      Object.keys(userData).map((key) => {
        dataArr.push(fetchApplicationData(userData[key], key));
      });
    }
    sortingAccordingCreation(dataArr);
    setTableValues(dataArr || []);
  }, []);

  const handleImageChange = async (e) => {
    let reader = new FileReader();
    var avatarImg = "";
    reader.addEventListener("load", function() {
      avatarImg = new Image();
      var src = reader.result;
      avatarImg.src = src;
    });

    let file = await handleImageUpload(e);
    reader.onloadend = () => {
      setThumbnailUrl(avatarImg.src);
    };
    reader.readAsDataURL(file);
  };

  const onHandleRemoveFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setFiles([]);
    setThumbnailUrl("");
  };

  const handleOnDelete = async (rowData) => {
    const { id = "" } = rowData.original || {};
    // return;
    if (id) {
      await db.ref(`surveys/${id}/`).remove();
      await db.ref(`publishedSurvey/${id}/`).remove();
      fetchData();
      setDeleteRow(true);
    }
  };
  const handleOnSwitch = async (rowData) => {
    const { id = "" } = rowData.original || {};
    let boolean;
    let active;
    if (id) {
      let publishSurveyRef = await db.ref(`publishedSurvey/${id}`);
      let saveSurveyRef = (await publishSurveyRef.once("value")).val();
      if (saveSurveyRef) {
        Object.values(saveSurveyRef).map((k) => {
          active = Date.now() >= k["startTime"] && Date.now() <= k["endTime"];
          if (active) {
            k["active"] = active ? !k["active"] : false;
            setDarkMode({
              boolean: active ? !k["active"] : false,
              id: k["surveyId"],
            });
            boolean = active ? !k["active"] : false;
          } else {
            setShowAlert({
              boolean: true,
              message: "Survey expired. Please Publish again with future dates",
            });
          }
        });
      } else {
        setShowAlert({ boolean: true, message: "Survey is not published" });
      }
      if (active) await handleModifiedDate(id, boolean);
      publishSurveyRef.set(saveSurveyRef);
    }
    fetchData();
  };

  const fetchApplicationData = (userData, key) => {
    return {
      id: key || "Default",
      name: userData.name || "Default",
      owner: userData.owner || "Default",
      timestamp: userData.modified || userData.created || "",
      created: moment(userData.created).format("MM-DD-YYYY HH:mm:ss") || "",
      modified: userData.modified
        ? moment(userData.modified).format("MM-DD-YYYY HH:mm:ss")
        : "",
      city: userData.city || "Martinsville",
      pages: userData.pages || [],
      activeSurvey: userData.active,
      thumbnail: (
        <Avatar style={{ borderRadius: 5, height: 50, width: 50 }}>
          {!userData.thumbnail || userData.thumbnail === "url" ? (
            <img
              src={require("../../../assets/img/surveyThumbnail.png")}
              alt="..."
              style={{ height: 50, width: 50 }}
            />
          ) : (
            <img
              src={userData.thumbnail}
              alt="..."
              style={{ height: 50, width: 50 }}
            />
          )}
        </Avatar>
      ),
    };
  };

  const search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  const doneDrawing = (polygon) => {
    var coordinates = polygon.getPath().getArray();
    setFence(coordinates);
    // new window.google.maps.Polygon({
    //   paths: polygon.getPaths(),
    // }).latLngs.sd[0].sd
  };

  const onHandlePublish = async (rowData, fromDate, toDate) => {
    const { id = "", pages: pages, name: name } = rowData.original || {};
    try {
      const publishDataSchema = {
        publishDate: Date.now(),
        locationData: JSON.parse(JSON.stringify(fence)),
        surveyId: id,
      };
      let publishDataList = [];
      const publishSurveyRef = await db.ref(`publishedSurvey/${id}`);
      await publishSurveyRef.once("value", (snapshot) => {
        if (snapshot.val()) {
          publishDataList = snapshot.val();
        }
      });
      publishDataSchema["startTime"] = Date.parse(
        new Date(fromDate).toUTCString()
      );
      publishDataSchema["endTime"] = Date.parse(new Date(toDate).toUTCString());
      let startTime;
      await db.ref(`publishedSurvey/${id}`).once("value", function(snapshot) {
        snapshot.forEach(function(childSnapshot) {
          startTime =
            publishDataSchema["startTime"] >= childSnapshot.val().startTime &&
            publishDataSchema["startTime"] <= childSnapshot.val().endTime;
          return startTime;
        });
      });
      if (startTime) {
        setShowAlert({
          boolean: true,
          message: "Already publish survey in this date",
        });
        return;
      }
      setDarkMode({
        boolean:
          Date.now() >= publishDataSchema["startTime"] &&
          Date.now() <= publishDataSchema["endTime"],
        id: publishDataSchema["surveyId"],
      });
      publishDataSchema["active"] =
        Date.now() >= publishDataSchema["startTime"] &&
        Date.now() <= publishDataSchema["endTime"];
      publishSurveyRef.set([...publishDataList, publishDataSchema]);
      setFromDate("");
      setToDate("");
      await handlOnSaveData(id);
      setFence([]);
    } catch (error) {
      console.log("Error: ", error);
      throw new Error(error);
    }
    setMoreVert({ boolean: false, rowData: "" });
  };

  const handlOnSaveData = async (id) => {
    const surveyRef = db.ref(`surveys/${id}`);
    let originalData = {};
    await surveyRef.once("value", (snapshot) => {
      if (snapshot.val()) {
        originalData = snapshot.val();
      }
    });
    originalData.modified = Date.now();
    surveyRef.set(originalData, handleOnSave);
  };

  const handleModifiedDate = async (id, boolean) => {
    const surveyRef = db.ref(`surveys/${id}`);
    let originalData = {};
    await surveyRef.once("value", (snapshot) => {
      if (snapshot.val()) {
        originalData = snapshot.val();
      }
    });
    originalData["active"] = boolean;
    surveyRef.set(originalData, handleOnSave);
  };

  const handleOnSave = (err) => {
    if (err) {
      console.log("alert");
    }
    console.log("success");
    fetchData();
  };
  const getLocationData = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          choosePlace(
            `location_title`,
            "search address",
            position.coords.latitude,
            position.coords.longitude
          );
        },
        () => {
          console.log("error");
        }
      );
    } else {
      console.log(`Browser doesn't support Geolocation`);
    }
  };
  const choosePlace = (location_title, address, latitude, longitude) => {
    setAddress({
      location_title: location_title,
      address: address,
      latitude: latitude,
      latitudeState: "success",
      longitude: longitude,
      longitudeState: "success",
    });
  };
  const handleAddressState = (addressState) => {
    setAddressState(addressState);
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader
              color="primary"
              icon
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4
                  className={{
                    ...cardTitle,
                    marginTop: "15px",
                    marginBottom: "0px",
                  }}
                  style={{ color: "#3C4858" }}
                >
                  Survey
                </h4>
              </div>
              <CardIcon
                color="primary"
                onClick={() => {
                  setOpenModal(true);
                }}
                className="align-Add-button"
              >
                Add
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={tableValues || []}
                filterable
                style={{ overflow: "inherit" }}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    filterMethod: search,
                    headerClassName: "header-center-align",
                    className: "cell-center-align",
                    minWidth: 75,
                  },
                  {
                    Header: "Owner",
                    accessor: "owner",
                    filterMethod: search,
                    headerClassName: "header-center-align",
                    className: "cell-center-align",
                    minWidth: 50,
                  },
                  {
                    Header: "Created",
                    accessor: "created",
                    filterMethod: search,
                    headerClassName: "header-center-align",
                    className: "cell-center-align",
                    minWidth: 75,
                  },
                  {
                    Header: "Modified",
                    accessor: "modified",
                    filterable: search,
                    headerClassName: "header-center-align",
                    className: "cell-center-align",
                    minWidth: 75,
                  },
                  {
                    Header: "City",
                    accessor: "city",
                    filterable: search,
                    headerClassName: "header-center-align",
                    className: "cell-center-align",
                    minWidth: 60,
                  },
                  {
                    Header: "Thumbnail",
                    accessor: "thumbnail",
                    sortable: false,
                    filterable: false,
                    headerClassName: "header-center-align",
                    className: "cell-center-align",
                    minWidth: 60,
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    minWidth: 120,
                    Cell: (row) => (
                      <ActionComponent
                        row={row}
                        deleteIconOnHandle={() => handleOnDelete(row)}
                        moreVertIconOnHandle={() =>
                          setMoreVert({
                            boolean: true,
                            rowData: row,
                          })
                        }
                      />
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
                autoResetHiddenColumns={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <SurveyModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setSurveyName={setSurveyName}
        setRedirect={setRedirect}
        surveyName={surveyName}
        title={`Create New Survey`}
        cancel={
          <Button
            variant="contained"
            color={`danger`}
            onClick={() => {
              setOpenModal(false);
              setThumbnailUrl("");
              setSurveyName("");
            }}
          >
            {`Cancel`}
          </Button>
        }
        ok={
          <Button
            disabled={!surveyName || surveyName.length < 3}
            variant="contained"
            color={`primary`}
            onClick={() => {
              setRedirect(true);
              setOpenModal(false);
            }}
          >
            {`Create`}
          </Button>
        }
        content={
          <TextField
            label="Survey title"
            fullWidth
            autoFocus
            type="text"
            variant="standard"
            id="standard-basic"
            required
            onChange={(e) => {
              setSurveyName(e.target.value);
            }}
          />
        }
        subContain={
          <div>
            <Previews
              files={files}
              setFiles={setFiles}
              handleImageChange={handleImageChange}
              onHandleRemoveFile={onHandleRemoveFile}
            />
          </div>
        }
      />

      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        message="Survey deleted successfully."
        open={deleteRow}
        onClose={() => setDeleteRow(false)}
        autoHideDuration={2000}
      />
      <SurveyModal
        openModal={moreVert.boolean}
        setOpenModal={setOpenModal}
        setSurveyName={setSurveyName}
        setRedirect={setRedirect}
        surveyName={moreVert.rowData}
        title={`Survey Publishing Geolocation`}
        ok={
          <Button
            disabled={!fromDate || !toDate ? true : false}
            style={{
              backgroundColor: !fromDate || !toDate ? "#E0E0E0" : `#942CAE`,
              borderRadius: 10,
            }}
            onClick={() => onHandlePublish(moreVert.rowData, fromDate, toDate)}
          >
            <span /* className='modal-text-style' */>{`Publish`}</span>
          </Button>
        }
        cancel={
          <Button
            style={{ backgroundColor: "#FF9800", borderRadius: 10 }}
            onClick={() => {
              setMoreVert({ boolean: false, rowData: "" });
              setFence([]);
            }}
          >{`cancel`}</Button>
        }
        fence={fence}
        content={
          <>
            <div
              style={{
                height: "100%",
                width: "100%",
                paddingTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>From</span>
              <DateTimePicker
                onChange={setFromDate}
                value={fromDate}
                required={true}
                minDate={new Date()}
              />
              <span>to</span>
              <DateTimePicker
                onChange={setToDate}
                value={toDate}
                required={true}
                minDate={new Date(fromDate)}
              />
            </div>
            <PlacesInput
              defaultAddress={""}
              choosePlace={choosePlace}
              setAddressState={handleAddressState}
            />

            <FullScreenMap
              getLocationData={() => getLocationData()}
              doneDrawing={doneDrawing}
              fence={true}
              address={address}
              currentLocation={currentLocation}
            />
          </>
        }
        subContain={
          <>
            {fence &&
              JSON.parse(JSON.stringify(fence)).map((item) => (
                <div>
                  <span className="modalLatlngText">
                    <span style={{ paddingRight: 10 }}>Latitude</span>
                    <TextField
                      inputProps={{ readOnly: true }}
                      type="text"
                      value={item.lat}
                      // InputProps={{
                      //   endAdornment: <MyLocationIcon />,
                      // }}
                    />
                    <span style={{ paddingRight: 10 }}>Longitute</span>
                    <TextField
                      inputProps={{ readOnly: true }}
                      type="text"
                      value={item.lng}
                      // InputProps={{
                      //   endAdornment: <MyLocationIcon />,
                      // }}
                    />
                  </span>
                </div>
              ))}
          </>
        }
      />
      {showAlert.boolean && (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={showAlert.message}
          onConfirm={() => setShowAlert({ boolean: false, message: "" })}
          onCancel={() => setShowAlert({ boolean: false, message: "" })}
        />
      )}
      {redirect ? (
        <Redirect
          to={{
            pathname: `${`/survey/form/` + uuid}`,
            params: { name: surveyName, id: uuid, thumbnail: thumbnailUrl },
          }}
          push={true}
        />
      ) : null}
    </div>
  );
};

export default withStyles(styles)(SurveyForm);
