import React, { useState, useRef } from "react";
import * as Survey from "survey-react";
import * as firebase from "firebase";
import TabButton from "./TabButton";
import SurveyBody from "./SurveyBody";
import SurveyEditPanel from "./SurveyEditPanel";
import { TAB_MENU } from "./constants";
import "./style.scss";
import "survey-react/survey.css";
import { Input, Button, TextField } from "@material-ui/core";
import { handleImageUpload } from "../../../utils";
import Switch from "@material-ui/core/Switch";
import CancelIcon from "@material-ui/icons/Cancel";
import Delete from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
import AlertModal from "./Alert";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { blue, green, red } from "@material-ui/core/colors";

const styles = {
  root: {
    background: "black",
  },
  input: {
    color: "#fff",
  },
  root1: {
    background: "#2c2c2c",
    border: 0,
    borderRadius: 3,
    color: "#1ab394",
    height: 30,
  },
  switchBase: {
    padding: 1,
    "&$checked + $bar": {
      background: blue[500],
    },
  },
  bar: {
    height: 20,
    width: 34,
    borderRadius: 10,
    background: "#000000",
    "&$checked + $bar": {
      background: blue[500],
    },
  },
  icon: {
    height: 12,
    width: 12,
    borderRadius: 6,
    padding: 0,
    position: "absolute",
    top: 21,
  },
  iconChecked: {
    color: "#20b2aa",
    height: 12,
    width: 12,
    borderRadius: 6,
    position: "absolute",
    top: 21,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid red`,
    backgroundColor: "red",
    opacity: 1,
  },
  checked: {},
};

function SurveyCreator({ match, history, classes }) {
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#fff",
      "&$checked": {
        color: "#fff",
      },
      "&$checked + $bar": {
        backgroundColor: "#1BB394",
      },
    },
    icon: {
      height: 12,
      width: 12,
      borderRadius: 6,
      padding: 0,
      position: "absolute",
      top: 21,
    },
    bar: {
      height: 20,
      width: 34,
      borderRadius: 10,
      backgroundColor: "#000000",
    },
    checked: {},
  })(Switch);

  const db = useRef(firebase.database()).current;
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState("");
  const [elements, setElements] = useState([]);
  const [objectData, setObjectData] = useState({ type: "" });
  const [changeText, setChangeText] = useState("");
  const [saveJson, setSaveJson] = useState("");
  const [title, setTitle] = useState("Title");
  const [description, setDescription] = useState("Description");
  const [checked, setChecked] = useState(false);
  const [openAlertModal, setOpenAlertModalsetModel] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleOnSave = (err) => {
    if (err) {
      console.log("alert");
    }
    console.log("success");
    setOpenAlertModalsetModel(true);
  };

  let json = [];
  const combineJson = (value, boolean) => {
    if (boolean === "true") {
      elements.filter((f) => f.id !== value.id).concat([value]);
      json.push(...elements);
    } else if (boolean === "item") {
      const elementsCopy = [...elements];
      const targetIndex = elements.findIndex((f) => f.title === value.title);
      elementsCopy[targetIndex] = value;
      json = elementsCopy;
    } else {
      json.push(...elements, {
        ...value,
        title: "question " + elements.length,
        id: `${elements.length}`,
      });
    }
    setElements(json);
    setModel(
      new Survey.Model({
        title: title,
        description: description,
        pages: [
          {
            name: "page",
            elements: json,
          },
        ],
      })
    );
    setSaveJson({
      title: title,
      description: description,
      pages: [
        {
          name: "page",
          elements: json,
        },
      ],
    });
  };

  const handleChange = (event, data, elements) => {
    let index = elements.findIndex((e) => e.title === data.title);
    setChecked(event.target.checked);
    elements[index].isRequired = event.target.checked;
    combineJson(elements[index], "true");
  };

  const handleImageChange = async (e, objectData) => {
    e.preventDefault();
    let reader = new FileReader();
    reader.addEventListener("load", function() {
      var avatarImg = new Image();
      var src = reader.result;
      avatarImg.src = src;
    });

    let file = await handleImageUpload(e.target.files[0]);
    reader.onloadend = () => {
      changeQuestion(reader.result, objectData, elements);
      setOpen(false);
    };
    reader.readAsDataURL(file);
  };

  const onHandleAddOption = (type, data) => {
    let index = elements.findIndex((e) => e.title === data);
    let value = {
      value: `${type}_item${elements[index]["choices"].length + 1}`,
      text: `${type}_item${elements[index]["choices"].length + 1}`,
    };
    elements[index].choices = [...elements[index].choices, value];
    combineJson(elements[index], "true");
  };

  const onHandleEditOption = (data, object, elements) => {
    let freshData = JSON.parse(JSON.stringify(elements));
    let index = freshData.findIndex((e) => e.title === object["question"]);

    let itemIndex = freshData[index].choices.findIndex(
      (itemId) => itemId.text === object.title
    );
    freshData[index].choices[itemIndex].text = data;
    freshData[index].choices[itemIndex].value = data;
    combineJson(freshData[index], "item");
  };

  if (!!model) {
    model.showNavigationButtons = false;
    model.onUpdateQuestionCssClasses.add(function(survey, options) {
      var classes = options.cssClasses;

      if (options.question.getType() === "rating") {
        classes.title = "divclass";
      } else if (options.question.getType() === "checkbox") {
        classes.itemControl += " check-box";
      } else if (options.question.getType() === "image") {
        classes.root = "survey_Image_Alignment";
      } else if (options.question.getType() === "radiogroup") {
        classes.itemControl += " check-box";
      } else if (options.question.getType() === "dropdown") {
        classes.control += " dropdown-style";
      }
    });

    let editableText = document.getElementsByClassName("sv-string-viewer");
    model.onAfterRenderQuestionInput.add(function(survey, options) {
      options.htmlElement.draggable = true;
    });

    // model.onUpdateChoiceItemCss.add(function(survey, options) {

    model.onValueChanging.add(function(survey, options) {
      if (options.question.getType() === "dropdown") {
        if (!Boolean(options.value.length)) {
          setObjectData({});
          setOpen(false);
          setChangeText("");
          return;
        }
        setObjectData({
          title: options.value,
          type: "item",
          question: options.question.title,
        });
        setOpen(true);
        setChangeText(options.value);
      } else if (options.question.getType() === "radiogroup") {
        setObjectData({
          title: options.value,
          type: "item",
          question: options.question.title,
        });
        setOpen(true);
        setChangeText(options.value);
      }
    });

    model.onAfterRenderQuestion.add(function(survey, options) {
      let title = options.question.imageHeight
        ? options.question.title
        : options.question.title;
      let imageLink = options.question.imageHeight
        ? options.question.imageLink
        : null;

      const inputTag = options.question.imageHeight ? "img" : "div";

      if (options.question.getType() === "checkbox") {
        var button = document.createElement("button");
        button.innerHTML = "+";
        button.onclick = function() {
          onHandleAddOption("checkbox", options.question.title);
        };
        let array1 = Object.values(
          document.getElementsByClassName("sv_q sv_qstn")
        );
        let indexOfButton = array1.findIndex(
          (id) => id.dataset.name === options.question.name
        );
        document
          .querySelectorAll('[role="presentation"][class=""]')
          [indexOfButton].appendChild(button);
        let chooseOption;
        chooseOption = document.querySelectorAll(
          '[class="sv_q_checkbox_label"]'
        );
        Object.values(chooseOption).map((tag) => {
          tag.onclick = () => {
            setObjectData({
              title: tag.innerText,
              type: "item",
              question: tag.getElementsByTagName("input")[0].ariaLabel,
            });
            setOpen(true);
            setChangeText(tag.innerText);
          };
        });
      } else if (options.question.getType() === "radiogroup") {
        var button = document.createElement("button");
        button.innerHTML = "+";
        button.onclick = function() {
          onHandleAddOption("radiogroup", options.question.title);
        };
        let array1 = Object.values(
          document.getElementsByClassName("sv_q sv_qstn")
        );
        let indexOfButton = array1.findIndex(
          (id) => id.dataset.name === options.question.name
        );
        document
          .querySelectorAll('[role="presentation"][class=""]')
          [indexOfButton].appendChild(button);
        // let chooseOption;
        // chooseOption = document.querySelectorAll(
        //   '[class="sv_q_radiogroup_label"]'
        // );
        // Object.values(chooseOption).map((tag, index) => {
        //   tag.onclick = () => {
        //     setObjectData({
        //       title: tag.innerText,
        //       type: "item",
        //       question: options.question.title,
        //     });
        //     setOpen(true);
        //     setChangeText(tag.innerText);
        //   };
        // });
      } else if (options.question.getType() === "dropdown") {
        var selectTag = document.getElementsByClassName(
          "sv_q_dropdown_control"
        );
        Object.values(selectTag).map((selectTag) => {
          selectTag.setAttribute("size", selectTag.childElementCount);
        });
        Object.values(document.getElementsByTagName("option")).map((tag) => {
          tag.setAttribute("class", "dropdown-Item-style");
        });

        var button = document.createElement("button");
        button.innerHTML = "+";
        button.onclick = function() {
          onHandleAddOption("dropdown", options.question.title);
        };
        let array1 = Object.values(
          document.getElementsByClassName("sv_q sv_qstn")
        );
        let indexOfButton = array1.findIndex(
          (id) => id.dataset.name === options.question.name
        );
        document
          .querySelectorAll('[role="presentation"][class=""]')
          [indexOfButton].appendChild(button);
      }
      var tag = options.htmlElement.querySelector(inputTag);
      if (inputTag.toString() === "img") {
        tag.onclick = () => {
          setObjectData({ type: `image`, title, imageLink });
          setOpen(true);
        };
      } else {
        tag.onclick = () => {
          setObjectData({ title, type: `other` });
          setOpen(true);
          setChangeText(title);
        };
      }
    });
  }

  const changeQuestion = (text, data, elements) => {
    let index = elements.findIndex((e) => e.title === data.title);
    if (elements[index].type === data.type && elements[index]) {
      elements[index].imageLink = text;
      combineJson(elements[index], "true");
    } else if (elements[index].id) {
      elements[index].title = text;
      elements[index].name = text;
      combineJson(elements[index], "true");
    }
  };

  const onHandleDelete = (data, array) => {
    let index = array.findIndex((e) => e.title === data.title);
    if (index > -1) {
      array.splice(index, 1);
      combineJson(array);
    }
    setOpen(false);
  };

  const onHandleClearSurvey = () => {
    elements.splice(0, elements.length);
    setModel(new Survey.Model({}));
    setSaveJson({});
    setOpen(false);
  };

  const handlOnSaveData = (data, title, desc) => {
    data = {
      ...data,
      title: title,
      description: desc,
      name: history.location.params.name,
      owner: "",
      created: Date.now(),
      city: "",
      thumbnail: history.location.params.thumbnail,
    };
    const surveyRef = db.ref(`surveys/${match.params.id}`);
    surveyRef.set(data, handleOnSave);
  };

  return (
    <div className="root-container">
      <div className="left-container">
        <div className="header-container">
          <span className="text-style">TOOLBOX</span>
        </div>
        <div className="tab-button">
          {TAB_MENU.map((tabItem) => (
            <TabButton
              name={tabItem.name}
              handleOnClick={() => combineJson(tabItem.component)}
              icon={tabItem.icon}
            />
          ))}
        </div>
      </div>
      <div className="center-container">
        {!!model && (
          <SurveyBody
            model={model}
            onComplete={() => {}}
            onHandleClearSurvey={onHandleClearSurvey}
            elements={elements}
            handlOnSaveData={() => {
              handlOnSaveData(saveJson, title, description);
              setModel(false);
            }}
          />
        )}
      </div>
      <div className="right-container">
        <div className="header-container">
          <span className="text-style">PROPERTIES</span>
        </div>
        <SurveyEditPanel
          model={model}
          active={open}
          objectData={objectData}
          setObjectData={setObjectData}
          elements={elements}
          changeQuestion={changeQuestion}
          setTitle={setTitle}
          setDescription={setDescription}
          changeText={changeText}
        />
        {open && (
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            {Object.keys(objectData).length && objectData.type === "image" && (
              <>
                <div style={{ alignSelf: "center" }}>
                  <img
                    src={`https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg`}
                    alt="question 0"
                    width="100%"
                  />
                </div>
                <Input
                  type="file"
                  accept="image/x-png,image/jpg,image/jpeg"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  onChange={(e) => handleImageChange(e, objectData, elements)}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: 20,
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      className={classes.root1}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <Button
                    variant="contained"
                    className={classes.root1}
                    onClick={() => setOpen(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.root1}
                    onClick={() => onHandleDelete(objectData, elements)}
                  >
                    Delete
                  </Button>
                </div>
              </>
            )}
            {Object.keys(objectData).length && objectData["type"] == "item" && (
              <>
                <span className="narmalText">Option Title</span>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  name="Title"
                  id="standard-basic"
                  value={changeText}
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={(e) => {
                    setChangeText(e.target.value);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    paddingTop: 30,
                  }}
                >
                  <Button
                    className={classes.root1}
                    onClick={() => {
                      onHandleEditOption(changeText, objectData, elements);
                      setOpen(false);
                    }}
                  >
                    <Save />
                    <span style={{ color: "white" }}>Save</span>
                  </Button>
                  <Button
                    className={classes.root1}
                    onClick={() => setOpen(false)}
                  >
                    <CancelIcon />
                    <span style={{ color: "white" }}>Cancel</span>
                  </Button>
                  <Button
                    className={classes.root1}
                    onClick={() => onHandleDelete(objectData, elements)}
                  >
                    <Delete />
                    <span style={{ color: "white" }}>Delete</span>
                  </Button>
                </div>
              </>
            )}
            {Object.keys(objectData).length && objectData.type === "other" && (
              <>
                <span className="narmalText">Title</span>
                <TextField
                  type="text"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  name="Title"
                  id="standard-basic"
                  value={changeText}
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={(e) => {
                    setChangeText(e.target.value);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    paddingTop: 30,
                  }}
                >
                  <Button
                    className={classes.root1}
                    onClick={() => {
                      changeQuestion(changeText, objectData, elements);
                      setOpen(false);
                      setChecked(false);
                    }}
                  >
                    <Save />
                    <span style={{ color: "white" }}>Save</span>
                  </Button>
                  <Button
                    className={classes.root1}
                    onClick={() => setOpen(false)}
                  >
                    <CancelIcon />
                    <span style={{ color: "white" }}>Cancel</span>
                  </Button>
                  <Button
                    className={classes.root1}
                    onClick={() => onHandleDelete(objectData, elements)}
                  >
                    <Delete />
                    <span style={{ color: "white" }}>Delete</span>
                  </Button>
                </div>
                <div className="narmalText">
                  <CustomSwitch
                    disableRipple
                    checked={checked}
                    onChange={(event) =>
                      handleChange(event, objectData, elements)
                    }
                  />
                  <span>is required ?</span>
                </div>
              </>
            )}
          </div>
        )}
        <AlertModal
          text={`Successfully created visitor survey`}
          open={openAlertModal}
          onHandleYes={() => setRedirect(true)}
          ok={`ok`}
        />
        {redirect ? (
          <Redirect
            to={{
              pathname: "/admin/wizard",
            }}
            push={true}
          />
        ) : null}
      </div>
    </div>
  );
}

export default withStyles(styles)(SurveyCreator);
