import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
// @material-ui/icons
import Home from "@material-ui/icons/Home";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import * as firebase from "firebase";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  titleContainer: {
    display: "flex",
  },
  actionContainer: {
    textAlign: "center",
    display: "flex",
  },
  cardHeaderContainer: {
    dispatchEventdisplay: "flex",
    justifyContent: "space-between",
  },
};

class LeaderboardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: [{}],
      eventLeaderboardData: [],
      profileData: [],
      leaderboardData: [],
      pathname: "",
    };
    this.db = firebase.database();

    this.leaderboardRef = this.db.ref("leaderboard/");
    this.eventLeaderboardRef = this.db.ref("event-leaderboard/");
    this.profilesRef = this.db.ref("profiles/");
    this.gameCardRef = this.db.ref("game-cards/");
  }
  componentDidMount() {
    this.fetchProfilesDataRef();
  }

  fetchProfilesDataRef = () => {
    let profileData = [];
    this.profilesRef.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          profileData.push({
            id: child.key,
            username: child.val().username,
            name: child.val().firstname + " " + child.val().lastname,
            userType: child.val().type,
            generation: child.val().generation,
            location: child.val().address,
          });
        }
      });
      this.fetchEventLeaderboardTableRef(profileData);
    });
  };

  fetchEventLeaderboardTableRef = (profileData) => {
    let eventLeaderboardData = [];
    this.eventLeaderboardRef.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          let clientData = child.val();
          Object.keys(clientData).map((key) => {
            eventLeaderboardData.push({
              gameCardId: child.key,
              clientId: key,
              points: clientData[key].points,
            });
          });
        }
      });
      eventLeaderboardData.sort((a, b) => {
        return b.points - a.points;
      });
      eventLeaderboardData.forEach((data, index) => {
        data.rank = index + 1;
      });
      this.fetchLeaderboardDataByTableRef(profileData, eventLeaderboardData);
    });
  };

  fetchLeaderboardDataByTableRef = async (
    profileData,
    eventLeaderboardData
  ) => {
    let leaders = [];
    let leaderboardData = await this.leaderboardRef
      .orderByChild("points")
      .once("value");
    if (leaderboardData.val()) {
      Object.keys(leaderboardData.val()).forEach((key) => {
        leaders.push({
          ...leaderboardData.val()[key],
          id: key,
        });
      });
      leaders.sort((a, b) => {
        return b.points - a.points;
      });
      let rank = 1;
      leaders.forEach((child) => {
        child.rank = rank++;
      });
    }
    this.getTableData(profileData, eventLeaderboardData, leaders);
  };

  getTableData = async (profileData, eventLeaderboardData, leaderboardData) => {
    let tableData = [];
    let gameCardData = {};
    try {
      let eventName = await this.db.ref(`game-cards`).once("value");
      if (eventName.val()) gameCardData = eventName.val();
    } catch (error) {
      console.log("error");
    }
    leaderboardData.forEach(async (data) => {
      let profile = profileData.find((profile) => profile.id === data.id);
      let eventLeaderboardDataProfile = eventLeaderboardData.find(
        (profile) => profile.clientId === data.id
      );
      let leaderboard = leaderboardData.find(
        (leaderboard) => leaderboard.id === data.id
      );
      tableData.push({
        ...profile,
        ...eventLeaderboardDataProfile,
        leaderboardRank: leaderboard && leaderboard.rank,
        leaderboardPoints: leaderboard && leaderboard.points,
        eventLeaderboardPoints:
          eventLeaderboardDataProfile && eventLeaderboardDataProfile.points,
        rank: eventLeaderboardDataProfile && eventLeaderboardDataProfile.rank,
        seriesName:
          gameCardData[data.gameCardId] &&
          gameCardData[data.gameCardId].seriesDetails.name,
      });
    });
    let optimisetableData = tableData.filter(
      (ele) => ele.username !== undefined
    );
    this.setState({ tableValues: optimisetableData });
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader
                color="primary"
                className={styles.cardHeaderContainer}
                icon
              >
                <div>
                  <CardIcon color="primary">
                    <Home />
                  </CardIcon>
                  <div className={classes.titleContainer}>
                    <h4 className={classes.cardIconTitle}>Leaderboard</h4>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={this.state.tableValues || []}
                  filterable
                  columns={[
                    {
                      Header: "User Name",
                      accessor: "username",
                      filterMethod: this.search,
                    },
                    {
                      Header: "First & Last Name",
                      accessor: "name",
                      filterMethod: this.search,
                    },
                    {
                      Header: "User Type",
                      accessor: "userType",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Generation",
                      accessor: "generation",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Location",
                      accessor: "location",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Series Name",
                      accessor: "seriesName",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Leaderboard Score",
                      accessor: "leaderboardPoints",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Leaderboard Rank",
                      accessor: "leaderboardRank",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Series Score",
                      accessor: "eventLeaderboardPoints",
                      filterMethod: this.search,
                    },
                    {
                      Header: "Series Rank",
                      accessor: "rank",
                      filterMethod: this.search,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  defaultSorted={[
                    {
                      id: "rank",
                      desc: false,
                    },
                  ]}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(LeaderboardList);
