import * as firebase from "firebase";

export const approvedMission = async (param) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const pendingMissionRef = db.ref(
        `scavenger-hunt-items/pending-hunt-mission-photos/${param.id}/status/`
      );
      pendingMissionRef.set("Approved");
      uploadHuntsToDatabase(param, db, "Approved");
    } catch (err) {
      console.log("Error occure during Approved mission ", err);
    }
  });
};

export const rejectMission = async (param) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const pendingMissionRef = db.ref(
        `scavenger-hunt-items/pending-hunt-mission-photos/${param.id}/status/`
      );
      pendingMissionRef.set("Rejected");
      uploadHuntsToDatabase(param, db, "Rejected");
    } catch (err) {
      console.log("Error occure during Rejected mission ", err);
    }
  });
};

const uploadHuntsToDatabase = (param, db, status) => {
  return new Promise(async (resolve, reject) => {
    const { huntId, type, missionId, userId } = param;
    try {
    } catch (err) {
      console.log("Error occure during upload hunt to database ", err);
    }
    const approvedMissionRef = db.ref(
      `scavenger-hunt-items/${type}/${huntId}/${missionId}/pending/${userId}/status/`
    );
    approvedMissionRef.set(status);
  });
};

export const getUserDetails = (id, member, name) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      let dataArray = [];
      let userProfileRef = db.ref(`profiles/${id}/`);
      userProfileRef.on("value", (userSnapshot) => {
        const val = userSnapshot.val();
        if (val) {
          dataArray.push(mergeProfileData1(val, member, name));
          resolve(mergeProfileData1(val, member, name));
        }
      });
    } catch (err) {
      console.log("Getting error during fetch user profile", err);
    }
  });
};

const mergeProfileData1 = (userData, member, name) => {
  return {
    userName: userData.username || "",
    name: userData.firstname + " " + userData.lastname,
    userType: userData.personType,
    completed: member.approved,
    points: member.points,
    huntName: name,
    attentDate: member.date,
    timestamp: member.date,
  };
};

export const enableHuntMission = (id, value) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const enableHuntRef = db.ref(
        `scavenger-hunt-items/hunts-list/${id}/isVisiable`
      );
      enableHuntRef.set(value);
      resolve("success");
    } catch (err) {
      console.log("Getting error during enable disable hunt", err);
    }
  });
};

export const removeHunt = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const removeHuntRef = db.ref(`scavenger-hunt-items/hunts-list/`);
      const removeGPSMissionRef = db.ref(`scavenger-hunt-items/GPS-checkin/`);
      const removephotoMissionRef = db.ref(
        `scavenger-hunt-items/photo-mission/`
      );

      let result = await Promise.all([
        removeHuntRef.child(id).transaction((transactionHunt) => {
          if (!transactionHunt) {
            return null;
          } else {
            db.ref("scavenger-hunt-items/remove-hunts/")
              .child(transactionHunt.id)
              .set(transactionHunt);
            return null;
          }
        }),
        removeGPSMissionRef.child(id).transaction((transactionHunt) => {
          if (!transactionHunt) {
            return null;
          } else {
            db.ref("scavenger-hunt-items/remove-GPS-Mission/")
              .child(id)
              .set(transactionHunt);
            return null;
          }
        }),
        removephotoMissionRef.child(id).transaction((transactionHunt) => {
          if (!transactionHunt) {
            return null;
          } else {
            db.ref("scavenger-hunt-items/remove-photo-mission/")
              .child(id)
              .set(transactionHunt);
            return null;
          }
        }),
      ]);
      resolve("success remove");
    } catch (err) {
      console.log("Getting error during enable disable hunt", err);
    }
  });
};

export const rescheduleHunt = (id, startTime, endTime) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const rescheduleHuntRef = db.ref(`scavenger-hunt-items/hunts-list/${id}`);
      let pointsSnapshot = await rescheduleHuntRef.once("value");
      let active =
        new Date() >= Date.parse(new Date(startTime).toUTCString()) &&
        new Date() <= Date.parse(new Date(endTime).toUTCString());
      let reschedule = {
        isVisiable: active ? true : false,
        startTime: Date.parse(new Date(startTime).toUTCString()) || "",
        endTime: Date.parse(new Date(endTime).toUTCString()) || "",
      };
      let value = { ...pointsSnapshot.val(), ...reschedule };
      rescheduleHuntRef.set(value);
      resolve("success");
    } catch (err) {
      console.log("Getting error during enable disable hunt", err);
    }
  });
};

export const editHuntMissionData = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      const rescheduleHuntRef = db.ref(`scavenger-hunt-items/hunts-list/${id}`);
      let pointsSnapshot = await rescheduleHuntRef.once("value");
      if (pointsSnapshot.val()) resolve(pointsSnapshot.val());
    } catch (err) {
      console.log("Getting error during enable disable hunt", err);
    }
  });
};

export const removeCombileMission = (item) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { huntId, id, type } = item;
      let removeType = `photo-mission`
        ? `remove-photo-mission`
        : `remove-GPS-Mission`;
      let db = firebase.database();
      const photoMissionRef = db.ref(
        `scavenger-hunt-items/${type}/${huntId}/${id}`
      );
      photoMissionRef.transaction((transaction) => {
        if (!transaction) {
          return null;
        } else {
          db.ref(`scavenger-hunt-items/${removeType}/`)
            .child(id)
            .set(transaction);
          return null;
        }
      });
      resolve("success");
    } catch (err) {
      console.log("Getting error during enable disable hunt", err);
    }
  });
};

export const getTeamList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let db = firebase.database();
      let userDataArr = [];
      const teamRef = db.ref(`scavenger-hunt-items/hunts-list`);
      teamRef
        .orderByChild("gameplayMode")
        .equalTo("Team")
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            let userDataArray = Object.values(snapshot.val());
            userDataArr = userDataArray[userDataArray.length - 1]["teamList"];
          }
          resolve(userDataArr);
        });
    } catch (error) {
      console.log("error", error);
    }
  });
};
