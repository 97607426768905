import React from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  FormLabel,
  Snackbar,
} from "@material-ui/core";

// material ui icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import * as firebase from "firebase";

// style for this view
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";
import { Permissions } from "../../constants/Permissions";

class CreateGroupsForms extends React.Component {
  constructor(props) {
    super(props);
    let groupToUpdate = this.props.data || {};
    this.state = {
      shouldRefresh: false,
      enableRefresh: false,
      title: groupToUpdate.name || "",
      tableValues: (groupToUpdate && groupToUpdate.permissions) || Permissions,
    };
  }
  getCheckBox = (name, key, values) => {
    return (
      <Checkbox
        name="permissions"
        checked={values}
        onChange={(event) => {
          let newObject = this.state.tableValues[key];
          newObject[name] = !values;
          let newTableValues = this.state.tableValues;
          newTableValues[key] = newObject;
          this.setState({ tableValues: newTableValues });
        }}
      />
    );
  };

  finishButtonClick = async () => {
    let db = firebase.database();
    let groupData = {};
    groupData.name = this.state.title;
    groupData.permissions = this.state.tableValues;
    let groupRef;
    if (this.props.isUpdating) {
      groupRef = db.ref(`group/${this.props.data.id}`);
      groupData.id = this.props.data.id;
    } else {
      groupRef = db.ref(`group/`).push();
      groupData.id = groupRef.key;
    }

    // TODO: Switch to firebase transaction
    try {
      let result = await Promise.all([groupRef.set(groupData)]);
      this.setState({
        ...this.state,
        open: true,
        enableRefresh: true,
        resultMessage:
          "Success! Your Place of Interest was uploaded to the WAM app.",
      });
      this.props.onSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  handleClose = (event, reason) => {
    this.setState({ open: false, shouldRefresh: this.state.enableRefresh });
  };
  goBack = () => {
    this.props.onSubmit();
  };
  render() {
    const { classes } = this.props;
    const { tableValues } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={8} sm={8}>
          <div className={classes.wizardContainer} ref="group">
            <h3 className={classes.title}>We Are Martinsville</h3>
            <h5 className={classes.subtitle}>User Admin</h5>
            <Card className={classes.card}>
              <div className={classes.wizardHeader}>
                <h3 className={classes.title}>Groups and Functions</h3>
                <h5 className={classes.subtitle}>
                  Create a User Group and Identify Functions
                </h5>
              </div>
              <form style={{ margin: "25px" }}>
                <FormLabel className={classes.labelHorizontal}>
                  User Group Name
                </FormLabel>
                <CustomInput
                  success={this.state.titleState === "success"}
                  error={this.state.titleState === "error"}
                  labelText={<span>Title</span>}
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.title,
                    inputProps: {
                      maxLength: 50,
                    },
                    onChange: (event) =>
                      this.change(event, "title", "length", 3),
                  }}
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Menu</TableCell>
                      <TableCell>Create</TableCell>
                      <TableCell>Read</TableCell>
                      <TableCell>Update</TableCell>
                      <TableCell>Archive</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(tableValues).map((key) => (
                      <TableRow>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          {this.getCheckBox(
                            "create",
                            key,
                            tableValues[key]["create"]
                          )}
                        </TableCell>
                        <TableCell>
                          {this.getCheckBox(
                            "read",
                            key,
                            tableValues[key]["read"]
                          )}
                        </TableCell>
                        <TableCell>
                          {this.getCheckBox(
                            "update",
                            key,
                            tableValues[key]["update"]
                          )}
                        </TableCell>
                        <TableCell>
                          {this.getCheckBox(
                            "archive",
                            key,
                            tableValues[key]["archive"]
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </form>
              <div className={classes.footer}>
                <div className={classes.right}>
                  <Button
                    color="info"
                    className={this.finishButtonClasses}
                    onClick={() => this.finishButtonClick()}
                  >
                    Submit
                  </Button>
                </div>
                <div className={classes.right}>
                  <Button
                    color="danger"
                    className={this.finishButtonClasses}
                    onClick={this.goBack}
                  >
                    Back
                  </Button>
                </div>
                <div className={classes.clearfix} />
              </div>
            </Card>
          </div>
        </GridItem>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={this.handleClose}
          open={this.state.open}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.resultMessage}</span>}
          action={[]}
        />
      </GridContainer>
    );
  }
}

export default withStyles(wizardStyle)(CreateGroupsForms);
