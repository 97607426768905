import React, { useState, useEffect, useRef, useCallback } from "react";
import { validImgExtentions } from "../../../../constants/Common";
import DateTimePicker from "react-datetime-picker";
import * as firebase from "firebase";
import { USER_COUNTRY, USER_ADDRESS } from "../constant";

// Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// Material Core Component
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton, Tooltip } from "@material-ui/core";

// Material UI Icon
import { Close } from "@material-ui/icons";

import "../styles.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HuntComponent = (props) => {
  const {
    switchTab,
    header,
    participants,
    handleChange,
    firstOption,
    secondOption,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    poiList,
    onHandleSelectAddress,
    selectPOI,
    cancelButtonTitle,
    cancelButtonClick,
    acceptButtonTitle,
    acceptButtonClick,
    disabled = false,
    teamList,
    selectTeam,
    selectTeamId,
    setselectTeamId,
    filterUserName,
    onHandleSelectPlayer,
  } = props;
  const [countryType, setCountryType] = useState([]);
  const [address, setAddress] = useState([]);

  const onCountryChange = (event) => {
    setCountryType(event.target.value);
  };

  const onAddressChange = (event) => {
    setAddress(event.target.value);
  };
  return (
    <GridItem item xs={11} className="hunt-create-team">
      <FormControl
        fullWidth
        component="fieldset"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <div style={{ paddingTop: 25, paddingBottom: 5 }}>
          <span className="sub-header-text">{header}</span>
        </div>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={participants}
          onChange={handleChange}
        >
          <GridItem xs={6}>
            <FormControlLabel
              value={firstOption}
              control={
                <Radio
                  /*checked={
                    participants === firstOption ||
                    firstOption === "POI Checkin"
                  }*/
                  checked={participants === firstOption}
                />
              }
              label={firstOption}
            />
          </GridItem>
          {Boolean(secondOption) && (
            <GridItem xs={6}>
              <FormControlLabel
                value={secondOption}
                control={<Radio checked={participants === secondOption} />}
                label={secondOption}
              />
            </GridItem>
          )}
        </RadioGroup>
      </FormControl>
      {//firstOption === "POI Checkin" && participants === "POI Checkin" && header === "Checkin Mode" && (//
      header === "Checkin Mode" && (
        <FormControl fullWidth>
          <InputLabel id="address">POI Name</InputLabel>
          <Select
            value={selectPOI.title}
            inputProps={{ "aria-label": "Without label" }}
            onChange={onHandleSelectAddress}
          >
            {poiList &&
              poiList.map((list, index) => (
                <MenuItem key={index} id={list.id} value={list.title}>
                  {list.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {participants === `Team` && (
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div style={{ display: "flex" }}>
            <FormControl
              fullWidth
              className=""
              style={{ marginRight: 5 }}
              disabled
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: 14 }}
              >
                User Country
              </InputLabel>
              <Select
                id="duration"
                value={countryType || []}
                onChange={onCountryChange}
              >
                {USER_COUNTRY.map((list) => (
                  <MenuItem key={list} id={list} value={list}>
                    <ListItemText primary={list} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className=""
              style={{ marginRight: 5 }}
              disabled
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: 14 }}
              >
                Virginia
              </InputLabel>
              <Select
                id="duration"
                value={countryType || []}
                onChange={onCountryChange}
              >
                {USER_COUNTRY.map((list) => (
                  <MenuItem key={list} id={list} value={list}>
                    <ListItemText primary={list} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="" style={{ marginLeft: 5 }}>
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{ fontSize: 14 }}
              >
                Martinsville
              </InputLabel>
              <Select
                id="duration"
                value={address || []}
                onChange={onAddressChange}
              >
                {USER_ADDRESS.map((list) => (
                  <MenuItem key={list} id={list} value={list}>
                    <ListItemText primary={list} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                backgroundColor: teamList.length ? "#EcE4E4" : "#ffffff",
              }}
              className="team-container"
            >
              <CustomInput
                labelText={<span>{`Selecting team member`}</span>}
                id="title"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputProps: {
                    maxLength: 100,
                  },
                  onChange: (event) => filterUserName(event),
                }}
              />
              {teamList.map((list) => {
                return (
                  <div
                    onClick={() => {
                      onHandleSelectPlayer(list.id, list);
                    }}
                    className="select-mission-team"
                  >
                    <Checkbox checked={selectTeam.indexOf(list.id) > -1} />
                    <span>{list.name}</span>
                  </div>
                );
              })}
            </div>
            <div className="selected-team-member">
              <span className="select-team-header">Selected Team</span>
              {selectTeamId &&
                Boolean(selectTeamId.length) &&
                selectTeamId.map((item) => (
                  <div className="select-team-item">
                    <span>{item.name}</span>
                    <span>
                      <Tooltip title="Remove">
                        <IconButton
                          onClick={() => {
                            onHandleSelectPlayer(item.id, item);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {header === "Start/Stop Game" && (
        <FormControl
          fullWidth
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: 15,
          }}
        >
          <GridItem xs={6}>
            <span style={{ paddingRight: 20 }}>From</span>
            <DateTimePicker
              disabled={
                !Boolean(participants) || participants === "Immediately"
                  ? true
                  : false
              }
              onChange={setFromDate}
              value={participants === "Immediately" ? new Date() : fromDate}
              required={true}
              minDate={new Date()}
            />
          </GridItem>
          <GridItem xs={6}>
            <span style={{ paddingRight: 20 }}>to</span>
            <DateTimePicker
              disabled={!Boolean(participants)}
              onChange={setToDate}
              value={toDate}
              required={true}
              minDate={participants === "Immediately" ? new Date() : fromDate}
            />
          </GridItem>
        </FormControl>
      )}
      <GridItem
        style={{ marginTop: 20, marginBottom: 20 }}
        className="button-style"
        fullWidth
      >
        <Button
          variant="contained"
          color={`danger`}
          onClick={cancelButtonClick}
          style={{ borderRadius: 10 }}
        >
          {cancelButtonTitle}
        </Button>
        <Button
          disabled={disabled}
          variant="contained"
          color={"primary"}
          onClick={acceptButtonClick}
          style={{ borderRadius: 10 }}
        >
          {acceptButtonTitle}
        </Button>
      </GridItem>
    </GridItem>
  );
};

export default HuntComponent;
