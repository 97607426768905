import React from "react";

// @material-ui/icons
import Title from "@material-ui/icons/Title";
import Description from "@material-ui/icons/Description";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import LocationCity from "@material-ui/icons/LocationCity";
import LocationOn from "@material-ui/icons/LocationOn";
import MyLocation from "@material-ui/icons/MyLocation";
import InsertPhoto from "@material-ui/icons/InsertPhoto";
import * as firebase from "firebase";
import { POIs } from "../../../constants/POI";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import PlacesInput from "components/PlacesInput/PlacesInput.jsx";
import { validImgExtentions } from "../../../constants/Common";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "../../../components/Snackbar/Snackbar";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  formControl: {
    width: "100%",
    marginTop: 10,
    color: "red",
  },
  underline: {
    "&:before": {
      borderBottom: "1px solid #C0c0c0",
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: "2px solid #C0c0c0",
    },
    "&:after": {
      borderBottom: "2px solid #4caf50",
    },
  },
  testlabel: {
    color: "#78C27B",
    opacity: 1,
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.42857,
  },
  testlabelDisable: {
    opacity: 1,
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.42857,
  },
  testlabelRed: {
    color: "red",
    opacity: 1,
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.42857,
  },
  focus: {},
  alignmentMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  alertMessage: {
    fontSize: 12,
    color: "red",
    fontWeight: "500",
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    let preset = this.props.historyData && this.props.historyData.params;
    let quizToUpdate = this.props.data || {};
    this.loadedClassificationsCount = 0;
    this.db = firebase.database();

    this.accomodationRef = this.db.ref(`map-items/${POIs.ACCOMODATION.value}/`);
    this.attractionRef = this.db.ref(`map-items/${POIs.ATTRACTION.value}/`);
    this.parkRef = this.db.ref(`map-items/${POIs.PARK.value}/`);
    this.restaurantRef = this.db.ref(`map-items/${POIs.RESTAURANT.value}/`);
    this.shoppingRef = this.db.ref(`map-items/${POIs.SHOPPING.value}/`);

    let presetFetchData = {
      address: preset && this.props.historyData.params.name.infoData.address,
      asset: preset && this.props.historyData.params.name.infoData.asset,
      classification:
        preset && this.props.historyData.params.name.infoData.classification,
      id: preset && this.props.historyData.params.name.infoData.id,
      lat: preset && this.props.historyData.params.name.infoData.latitude,
      lng: preset && this.props.historyData.params.name.infoData.longitude,
      location_title:
        preset && this.props.historyData.params.name.infoData.location_title,
      points: preset && this.props.historyData.params.name.infoData.points,
      title: preset && this.props.historyData.params.name.infoData.title,
      infoCardText: preset && this.props.historyData.params.name.fieldName,
      infoCardDescription:
        preset && this.props.historyData.params.name.descriptionState,
      infoCardFontColor: preset && this.props.historyData.params.name.fontColor,
      infoCardBackgroundColor:
        preset && this.props.historyData.params.name.backgroundColor,
      infoCardCitations: preset && this.props.historyData.params.name.citations,
      created: preset && this.props.historyData.params.name.infoData.created,
      modify: preset && this.props.historyData.params.name.infoData.modify,
      description:
        preset && this.props.historyData.params.name.infoData.description,
    };
    this.state = {
      tableValues: [{}],
      fetchData: preset ? presetFetchData : "",
      fetchDataState: preset
        ? "success"
        : this.getInitialStateVal(quizToUpdate.fetchData),
      fetchData1: JSON.stringify(quizToUpdate.fetchData) || "",
      title: preset
        ? this.props.historyData.params.name.infoData.title || ""
        : quizToUpdate.title || "",
      titleState: preset
        ? this.props.historyData.params.name.infoData.titleState || "error"
        : this.getInitialStateVal(quizToUpdate.title),
      address: preset
        ? this.props.historyData.params.name.infoData.address
        : quizToUpdate.address || "",
      addressState: preset
        ? this.props.historyData.params.name.infoData.addressState
        : this.getInitialStateVal(quizToUpdate.address),
      description: preset
        ? this.props.historyData.params.name.infoData.description || ""
        : quizToUpdate.description || "",
      descriptionState: preset
        ? this.props.historyData.params.name.infoData.descriptionState ||
          "error"
        : this.getInitialStateVal(quizToUpdate.description),
      asset: Boolean(this.props.historyData && this.props.historyData.params)
        ? this.props.historyData.params.name.infoData.asset
        : quizToUpdate.asset,
      assetState: this.getInitialStateVal(quizToUpdate.asset),
      location_title: preset
        ? this.props.historyData.params.name.infoData.location_title
        : quizToUpdate.location_title,
      poiId: preset
        ? this.props.historyData.params.name.infoData.poiId || ""
        : quizToUpdate.poiId || "",
      poiClassification: preset
        ? this.props.historyData.params.name.infoData.poiClassification || ""
        : quizToUpdate.poiClassification || "",
      latitude: preset
        ? this.props.historyData.params.name.infoData.latitude
        : quizToUpdate.lat || quizToUpdate.latitude || 0,
      latitudeState: preset
        ? "success"
        : this.getInitialStateVal(quizToUpdate.lat || quizToUpdate.latitude),
      longitude: preset
        ? this.props.historyData.params.name.infoData.longitude
        : quizToUpdate.lng || quizToUpdate.longitude || 0,
      longitudeState: preset
        ? "success"
        : this.getInitialStateVal(quizToUpdate.lng || quizToUpdate.longitude),
      id: quizToUpdate.id || "",
      assetMessage: "",
      isOpen: false,
      errorMessage: "",
      errorColor: "",
      isMenuActive: false,
      parentPOIDetails: "",
      created: preset
        ? this.props.historyData.params.name.infoData.created
        : quizToUpdate.created || Date.now(),
      modify: quizToUpdate.modify || Date.now(),
    };
    this.setAsset = this.setAsset.bind(this);
    this.choosePlace = this.choosePlace.bind(this);
    this.setAddressState = this.setAddressState.bind(this);
  }

  componentDidMount() {
    this.fetchList();
  }
  fetchList = () => {
    let dataArr = [];
    this.loadedClassificationsCount = 0;

    this.fetchPOIDataByTableRef(this.accomodationRef, dataArr);
    this.fetchPOIDataByTableRef(this.attractionRef, dataArr);
    this.fetchPOIDataByTableRef(this.parkRef, dataArr);
    this.fetchPOIDataByTableRef(this.restaurantRef, dataArr);
    this.fetchPOIDataByTableRef(this.shoppingRef, dataArr);
  };

  fetchPOIDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(this.fetchPOIData(child.val()));
        }
      });

      this.loadedClassificationsCount += 1;
      if (this.loadedClassificationsCount === 5) {
        let parentPOIIdData = {};
        let data = this.state.fetchData;
        if (this.props.data && this.props.data.poiId) {
          parentPOIIdData = dataArr.find(
            (ele) =>
              ele.id === this.props.data.poiId &&
              ele.quizIdList[0] === this.props.data.id
          );
        } else if (this.props.data && !this.props.data.poiId) {
          parentPOIIdData = dataArr.find(
            (ele) =>
              ele.lng === this.props.data.lng &&
              ele.lat == this.props.data.lat &&
              ele.quizIdList[0] === this.props.data.id
          );
        }
        if (this.state.poiId) {
          data = dataArr.find(
            (ele) =>
              ele.id === this.state.poiId &&
              ele.quizIdList[0] === this.props.data.id
          );
        } else if (this.props.data && !this.state.poiId) {
          data = dataArr.find(
            (ele) =>
              ele.lat === this.state.latitude &&
              ele.lng === this.state.longitude &&
              ele.quizIdList[0] === this.props.data.id
          );
        }
        this.setState({
          ...this.state,
          tableValues: dataArr,
          parentPOIDetails: {
            classification: Boolean(parentPOIIdData)
              ? parentPOIIdData.classification
              : "",
            parentPOIIdData: Boolean(parentPOIIdData) ? parentPOIIdData.id : "",
          },
          fetchData1: JSON.stringify(data),
          fetchData: data,
          fetchDataState:
            typeof data === "object"
              ? "success"
              : !this.state.poiId || !parentPOIIdData
              ? ""
              : "error",
        });
      }
    });
  };

  fetchPOIData = (data) => {
    return {
      id: data.id,
      title: data.title,
      address: data.address,
      points: data.points,
      classification: data.classification,
      lat: data.lat,
      lng: data.lng,
      location_title: data.location_title,
      quizIdList: data.quizIdList || [],
      asset: data.asset,
    };
  };

  getInitialStateVal(stateElement) {
    if (stateElement && stateElement !== "") {
      return "success";
    }
    return "";
  }

  sendState() {
    console.log(this.state, "STATE FINISHED");
    return this.state;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyLatLong(value) {
    const isDecimal = value.match(/^-?\d*(\.\d+)?$/) && value.length > 0;
    return value >= -90.0 && value <= 90 && isDecimal;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "decimal":
        if (this.verifyLatLong(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: Number(event.target.value) });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  isValidated() {
    if (
      this.state.titleState === "success" &&
      this.state.addressState === "success" &&
      this.state.descriptionState === "success" &&
      this.state.fetchDataState === "success" &&
      this.state.latitudeState === "success" &&
      this.state.longitudeState === "success"
    ) {
      return true;
    } else {
      if (this.state.titleState !== "success") {
        this.setState({ titleState: "error" });
      }
      if (this.state.addressState !== "success") {
        this.setState({ addressState: "error" });
      }
      if (this.state.descriptionState !== "success") {
        this.setState({ descriptionState: "error" });
      }
      if (this.state.fetchDataState !== "success") {
        this.setState({ fetchDataState: "error" });
      }
      if (this.state.latitudeState !== "success") {
        this.setState({ latitudeState: "error" });
      }
      if (this.state.longitudeState !== "success") {
        this.setState({ longitudeState: "error" });
      }
    }
    return false;
  }

  setAsset(file) {
    let valid = true;

    if (file) {
      // validate file max size of 1 MB
      if (file.size > 1024 * 1000) {
        this.setState({
          assetMessage:
            "Invalid file. File size is too large, maximum file size should be 1MB",
        });
        valid = false;
      } else {
        // validate file type
        var currentExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        var found = validImgExtentions.find(function(element) {
          return element == (currentExtension || "").toLowerCase();
        });
        if (!found) {
          this.setState({ assetMessage: "Invalid file type" });
          valid = false;
        }
      }
    }

    if (valid) {
      this.setState({ assetState: "success", assetMessage: "Valid file" });
    } else {
      this.setState({ assetState: "error" });
    }

    this.setState({ ...this.state, asset: file });
  }

  choosePlace(location_title, address, latitude, longitude) {
    this.setState({
      ...this.state,
      location_title: location_title,
      address: address,
      latitude: latitude,
      latitudeState: "success",
      longitude: longitude,
      longitudeState: "success",
    });
  }

  setAddressState(addressState) {
    this.setState({
      ...this.state,
      addressState: addressState,
    });
  }

  onHandleSelectAddress = (event) => {
    let eventData = JSON.parse(event.target.value);
    const {
      title,
      location_title,
      address,
      lat,
      lng,
      quizIdList,
      id,
      classification,
    } = eventData;
    if (
      !Boolean(quizIdList.length) ||
      (this.props.data && quizIdList.includes(this.props.data.id))
    ) {
      this.setState({
        ...this.state,
        fetchData: eventData,
        fetchData1: JSON.stringify(eventData),
        addressState: "success",
        latitudeState: "success",
        longitudeState: "success",
        location_title: title,
        address: address,
        latitude: lat,
        longitude: lng,
        poiId: id,
        poiClassification: classification,
        fetchDataState: "success",
      });
    } else {
      this.setState({
        ...this.state,
        fetchData: eventData,
        fetchData1: JSON.stringify(eventData),
        location_title: title,
        address: address,
        addressState: "error",
        latitudeState: "error",
        longitudeState: "error",
        isOpen: true,
        errorMessage:
          "Already created quizzes on this POI. \n  Please edit the quiz.",
        errorColor: "rose",
        fetchDataState: "error",
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Please enter the details of your quiz
          </h4>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            success={this.state.titleState === "success"}
            error={this.state.titleState === "error"}
            labelText={<span>Title</span>}
            id="title"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.title,
              inputProps: {
                maxLength: 50,
              },
              onChange: (event) => this.change(event, "title", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Title className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            success={this.state.descriptionState === "success"}
            error={this.state.descriptionState === "error"}
            labelText={<span>Description</span>}
            id="description"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: this.state.description,
              inputProps: {
                maxLength: 100,
              },
              onChange: (event) =>
                this.change(event, "description", "length", 3),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Description className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
          <PictureUpload
            defaultAssetUrl={this.state.asset}
            setAsset={this.setAsset}
            assetState={this.state.assetState}
            assetMessage={this.state.assetMessage}
            isOptional={true}
          />
        </GridItem>
        {Boolean(this.props.historyData && this.props.historyData.params) ? (
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <CustomInput
              success={true}
              error={
                this.state.addressState === "error" ||
                this.state.fetchDataState === "error"
              }
              labelText={<span>Select address ...</span>}
              id="address"
              formControlProps={{
                fullWidth: true,
              }}
              disabled={true}
              inputProps={{
                value: this.props.historyData.params.name.infoData.title,
                disabled: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <ArrowDropDown className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
        ) : (
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <FormControl
              className={classes.formControl}
              error={
                this.state.addressState === "error" ||
                this.state.fetchDataState === "error"
              }
            >
              <InputLabel
                id="address"
                className={
                  this.state.fetchData
                    ? classes.testlabel
                    : this.state.addressState === "error"
                    ? classes.testlabelRed
                    : classes.testlabelDisable
                }
              >
                Select address ...
              </InputLabel>
              <Select
                value={this.state.fetchData1}
                onChange={this.onHandleSelectAddress}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className={classes.underline}
                onClick={() => {
                  this.setState({
                    isMenuActive: !this.state.isMenuActive,
                  });
                }}
              >
                {this.state.tableValues.map((device, index) => (
                  <MenuItem
                    id={device.id}
                    value={JSON.stringify(device)}
                    className={classes.alignmentMenu}
                  >
                    <span>{device.title || "Location ..."}</span>
                    {this.state.isMenuActive && (
                      <span className={classes.alertMessage}>
                        {device.quizIdList && Boolean(device.quizIdList.length)
                          ? "Quiz not available"
                          : ""}
                      </span>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        {/* <GridItem xs={12} sm={12} md={12} lg={10}>
          <PlacesInput
            classes={classes}
            defaultAddress={this.state.address}
            choosePlace={this.choosePlace}
            setAddressState={this.setAddressState}
            descriptionState={this.state.addressState}
          />
        </GridItem> */}
        {/* <GridItem xs={6} sm={5}>
          <CustomInput
            success={this.state.latitudeState === "success"}
            error={this.state.latitudeState === "error"}
            labelText={<span>Latitudeasdadad</span>}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.latitude,
              type: "number",
              onChange: event => this.change(event, "latitude", "decimal"),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <MyLocation className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={6} sm={5}>
          <CustomInput
            success={this.state.longitudeState === "success"}
            error={this.state.longitudeState === "error"}
            labelText={<span>Longitude</span>}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.longitude,
              type: "number",
              onChange: event => this.change(event, "longitude", "decimal"),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <MyLocation className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem> */}
        <Snackbar
          place="tc"
          color={this.state.errorColor}
          message={this.state.errorMessage}
          open={this.state.isOpen}
          close={() =>
            this.setState({
              isOpen: false,
              errorMessage: "",
              errorColor: "",
            })
          }
          closeNotification={() =>
            this.setState({
              isOpen: false,
              errorMessage: "",
              errorColor: "",
            })
          }
        />
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step1);
