import React, { Component } from "react";
// @material-ui/core components
import { withStyles, Typography } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Datetime from "react-datetime";

// @material-ui/icons
import { MyLocation, Description } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import PlacesInput from "components/PlacesInput/PlacesInput.jsx";
import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import Select from "react-select";

const style = {
  headerText: {
    textDecoration: "underline",
    fontWeight: "bold"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class GameMakerDetails extends Component {
  constructor(props) {
    super(props);
    let { data } = props;
    this.state = {
      ...data,
      setDefaultValue: true,
      name: data.name || "",
      nameState: this.getInitialStateVal(data.name),
      email: data.email || "",
      emailState: this.getInitialStateVal(data.email),
      phoneNumber: data.phoneNumber || 0,
      phoneNumberState: this.getInitialStateVal(data.phoneNumber),
      organization: data.organization || "",
      organizationState: this.getInitialStateVal(data.organization),
      gameCardEmail: data.gameCardEmail || ""
    };
  }

  getInitialStateVal(stateElement) {
    if (stateElement && stateElement !== "") {
      return "success";
    }
    return "";
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      prevState.setDefaultValue &&
      prevState.name === "" &&
      nextProps.data &&
      nextProps.data.name !== undefined &&
      nextProps.data.name !== prevState.name
    ) {
      return {
        setDefaultValue: false,
        name: nextProps.data.name,
        nameState: "success",
        email: nextProps.data.email,
        emailState: "success",
        phoneNumber: nextProps.data.contact,
        phoneNumberState: "success",
        organization: nextProps.data.organization,
        organizationState: "success"
      };
    }
  };

  change(event, stateName, type, stateNameEqualTo, lessThen, helpText) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "decimal":
        if (this.verifyLatLong(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: Number(event.target.value) });
        break;
      case "integer":
        if (this.verifyInteger(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        // eslint-disable-next-line no-case-declarations
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
          this.setState({ [stateName]: "" });
        } else {
          this.setState({ [stateName]: value });
        }
        break;
      case "date":
        if (this.verifyDate(event.target.value, stateNameEqualTo, lessThen)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName + "HelpText"]: ""
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName + "HelpText"]: helpText
          });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "eventOrder":
        if (
          this.verifyInteger(event.target.value) &&
          this.verifyEventOrder(event.target.value)
        ) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        // eslint-disable-next-line no-case-declarations
        const value1 = parseInt(event.target.value);
        if (isNaN(value1)) {
          this.setState({ [stateName]: "" });
        } else {
          this.setState({ [stateName]: value1 });
        }
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  isValidated = () => {
    // eslint-disable-next-line no-unreachable
    if (
      this.state.nameState === "success" &&
      this.state.emailState === "success" &&
      this.state.phoneNumberState === "success" &&
      this.state.organizationState === "success"
    ) {
      return true;
    } else {
      if (this.state.nameState !== "success") {
        this.setState({ nameState: "error" });
      }
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (this.state.phoneNumberState !== "success") {
        this.setState({ phoneNumberState: "error" });
      }
      if (this.state.organizationState !== "success") {
        this.setState({ organizationState: "error" });
      }
    }
    return false;
  };

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  verifyLatLong(value) {
    const isDecimal = value.match(/^-?\d*(\.\d+)?$/) && value.length > 0;
    return value >= -90.0 && value <= 90 && isDecimal;
  }

  sendState() {
    return this.state;
  }

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader text className={classes.headerText}>
          <h4>Contact Information</h4>
        </CardHeader>
        <CardBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
              <CustomInput
                success={this.state.nameState === "success"}
                error={this.state.nameState === "error"}
                labelText={<span> Name</span>}
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.name,
                  inputProps: {
                    maxLength: 50
                  },
                  onChange: event => this.change(event, "name", "length", 3)
                }}
              />
            </GridItem>
            <GridContainer>
              <GridItem xs={8}>
                <CustomInput
                  success={this.state.emailState === "success"}
                  error={this.state.emailState === "error"}
                  labelText={<span>Email Address</span>}
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.email,
                    inputProps: {
                      maxLength: 50
                    },
                    onChange: event => this.change(event, "email", "length", 3)
                  }}
                />
                <CustomInput
                  success={this.state.phoneNumberState === "success"}
                  error={this.state.phoneNumberState === "error"}
                  labelText={<span>Contact Number</span>}
                  id="phoneNumber"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.phoneNumber,
                    inputProps: {
                      maxLength: 50
                    },
                    onChange: event =>
                      this.change(event, "phoneNumber", "length", 3)
                  }}
                />
                <CustomInput
                  success={this.state.organizationState === "success"}
                  error={this.state.organizationState === "error"}
                  labelText={<span>Organization</span>}
                  id="organization"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.organization,
                    inputProps: {
                      maxLength: 50
                    },
                    onChange: event =>
                      this.change(event, "organization", "length", 3)
                  }}
                />
                <CardHeader text className={classes.headerText}>
                  <h4>Game Card Information</h4>
                </CardHeader>
                <CustomInput
                  labelText={<span>Email Game Card</span>}
                  id="gameCardEmail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.gameCardEmail,
                    inputProps: {
                      maxLength: 50
                    },
                    onChange: event =>
                      this.change(event, "gameCardEmail", "length", 3)
                  }}
                />
              </GridItem>
              <GridItem xs={4}></GridItem>
            </GridContainer>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(style)(GameMakerDetails);
