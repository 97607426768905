import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import AnswersForm from "./AnswersForm";
import CorrectAnswerRadios from "./CorrectAnswerRadios";
export default class QuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [24, 22],
      selectedValue: null,
      selectedEnabled: "b"
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
  }
  handleChange(event) {
    this.setState({ selectedValue: event.target.value });
  }
  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value });
  }

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" text style={{}}>
          <CardText>
            <h4>Question {this.props.questionIndex + 1}</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <GridItem xs={12} sm={12} md={12} style={{ display: "flex" }}>
              <AnswersForm
                question={this.props.question}
                questionIndex={this.props.questionIndex}
                updateQuestion={this.props.updateQuestion}
                classes={classes}
              />
              <CorrectAnswerRadios
                question={this.props.question}
                questionIndex={this.props.questionIndex}
                updateQuestion={this.props.updateQuestion}
                classes={classes}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ width: "100%" }}>
              <CustomInput
                success={
                  this.props.question &&
                  this.props.question.pointsState === "success"
                }
                error={
                  this.props.question &&
                  this.props.question.pointsState === "error"
                }
                labelText={<span>Points</span>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.props.question.points,
                  type: "number",
                  onChange: event => {
                    if(event.target.value >= 0){
                    let newQuestion = { ...this.props.question };
                    newQuestion.points = parseInt(event.target.value);
                    this.props.updateQuestion(
                      newQuestion,
                      this.props.questionIndex
                    );}
                  }
                }}
              />
            </GridItem>
          </div>
        </CardBody>
      </Card>
    );
  }
}
