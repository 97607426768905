import React from "react";

// @material-ui/core components
import { withStyles } from "@material-ui/core";

// material ui icons
import { Home, Person } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import axios from "axios";

import * as firebase from "firebase";
import { secondaryFirebase } from "../..";

// style for this view
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  headerContainer: {
    display: "flex",
  },
  titleContaoiner: {
    fontWeight: "bold",
    marginBottom: "15px",
  },
  bodyContaoiner: {
    marginBottom: "15px",
  },
  dataContainer: {
    marginLeft: "15px",
  },
  userCardContainer: {
    background: "#eef0f1",
  },
  buttonContainer: {
    direction: "rtl",
    marginTop: "auto",
    marginBottom: "20px",
  },
  buttonClasses: {
    marginLeft: "10px",
  },
};

class UpdateApplicationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      userProfile: {},
      shouldRefresh: false,
      enableRefresh: false,
    };
    this.db = firebase.database();
  }

  componentDidMount = () => {
    this.db
      .ref(`users/${this.props.userId}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) this.setState({ userData: snapshot.val() });
      });
    this.db
      .ref(`profiles/${this.props.userId}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) this.setState({ userProfile: snapshot.val() });
      });
  };

  submitClick = async (archive) => {
    const { data, userId, onSubmit } = this.props;
    const { userProfile } = this.state;
    let eventsRef = this.db.ref(
      `event-sponsor-application/${userId}/${data.id}`
    );
    let eventData = { ...data };
    eventData.lastModified = new Date().valueOf();
    if (archive) eventData.status = "rejected";
    else eventData.status = "approved";
    try {
      let result = await Promise.all([eventsRef.set(eventData)]);
      console.log(result, "RESULT");
      onSubmit();
      if (!archive) {
        secondaryFirebase
          .auth()
          .createUserWithEmailAndPassword(
            data.contactInfo.email,
            "Martinsville123@"
          )
          .then((response) => {
            let userData = {
              active: true,
              adminStatus: "group",
              email: data.contactInfo.email,
              phoneNumber: this.state.userData.phoneNumber,
              firstname: userProfile.firstname || "",
              lastname: userProfile.lastname || "",
              mobileUserId: userId,
              groupid: "",
            };
            this.db.ref(`group/`).on("value", async (snapshot) => {
              snapshot.forEach((child) => {
                if (child.val() && child.val().name === "Game Card") {
                  userData.groupid = child.val().id;
                }
              });
              let userRef = this.db.ref(`users/${response.user.uid}/`);
              userData.id = response.user.uid;
              try {
                let result = await Promise.all([userRef.set(userData)]);
                console.log(result, "RESULT");
              } catch (error) {
                console.log(error);
              }
              secondaryFirebase.auth().signOut();
            });
          })
          .catch(function(error) {
            console.log(error);
          });
        let url = new URL(
          `https://us-central1-we-are-martinsville-8433f.cloudfunctions.net/sendMail?dest=
          ${data.contactInfo.email}`
        );
        return axios
          .post(url, "", { headers: { Accept: "*/*" } })
          .then((response) => console.log(response))
          .catch((error) => console.log(error));
      }
    } catch (error) {
      console.log(error);
    }
  };
  goBack = () => {
    this.props.onSubmit();
  };
  render() {
    const { classes, data } = this.props;
    const { userData, userProfile } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <div style={{ textAlign: "right" }}>
              <Button
                color="danger"
                className={this.finishButtonClasses}
                onClick={this.goBack}
              >
                Back
              </Button>
            </div>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Home />
              </CardIcon>
              <div className={classes.headerContainer}>
                <h4 className={classes.cardIconTitle}>
                  Update Events Application
                </h4>
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer className={classes.dataContainer}>
                <GridItem xs={3} className={classes.titleContaoiner}>
                  Event Name
                </GridItem>
                <GridItem xs={9} className={classes.bodyContaoiner}>
                  {data.eventName}
                </GridItem>
                <GridItem xs={3} className={classes.titleContaoiner}>
                  Event Description
                </GridItem>
                <GridItem xs={9} className={classes.bodyContaoiner}>
                  {data.eventDescription}
                </GridItem>
                <GridItem xs={3} className={classes.titleContaoiner}>
                  Event Details
                </GridItem>
                <GridItem xs={9} className={classes.bodyContaoiner}>
                  {new Date(data.eventDetails.dateTime).toDateString()}
                  <br />
                  {data.eventDetails.location}
                </GridItem>
                <GridItem xs={3} className={classes.titleContaoiner}>
                  Event Sponsor
                </GridItem>
                <GridItem xs={9} className={classes.bodyContaoiner}>
                  {data.eventSponsors}
                </GridItem>
                <GridItem xs={3} className={classes.titleContaoiner}>
                  Contact Information
                </GridItem>
                <GridItem xs={9} className={classes.bodyContaoiner}>
                  {data.contactInfo.name}
                  <br />
                  {data.contactInfo.email}
                  <br />
                  {data.contactInfo.organization}
                  <br />
                  {data.contactInfo.contact}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={9}>
                  <Card className={classes.userCardContainer}>
                    <CardHeader color="primary" icon>
                      <Person style={{ color: "black" }} />
                    </CardHeader>
                    <GridContainer className={classes.dataContainer}>
                      <GridItem xs={4} className={classes.titleContaoiner}>
                        Date Submitted
                      </GridItem>
                      <GridItem xs={8} className={classes.bodyContaoiner}>
                        {new Date(data.date).toDateString()}
                      </GridItem>
                      <GridItem xs={4} className={classes.titleContaoiner}>
                        UserName
                      </GridItem>
                      <GridItem xs={8} className={classes.bodyContaoiner}>
                        {userData.userName || userProfile.username}
                      </GridItem>
                      <GridItem xs={4} className={classes.titleContaoiner}>
                        First Name
                      </GridItem>
                      <GridItem xs={8} className={classes.bodyContaoiner}>
                        {userData.firstName || userProfile.firstname}
                      </GridItem>
                      <GridItem xs={4} className={classes.titleContaoiner}>
                        Last Name
                      </GridItem>
                      <GridItem xs={8} className={classes.bodyContaoiner}>
                        {userData.lastName || userProfile.lastname}
                      </GridItem>
                      <GridItem xs={4} className={classes.titleContaoiner}>
                        Registered Email Address
                      </GridItem>
                      <GridItem xs={8} className={classes.bodyContaoiner}>
                        {userData.email || userProfile.email}
                      </GridItem>
                      <GridItem xs={4} className={classes.titleContaoiner}>
                        Registered Phone Number
                      </GridItem>
                      <GridItem xs={8} className={classes.bodyContaoiner}>
                        {userData.phoneNumber}
                      </GridItem>
                    </GridContainer>
                  </Card>
                </GridItem>
                {!this.props.viewMode && (
                  <GridItem xs={3} className={classes.buttonContainer}>
                    <Button
                      color="info"
                      className={classes.buttonClasses}
                      onClick={() => this.submitClick(false)}
                    >
                      Send Invitation
                    </Button>
                    <Button color="info" onClick={() => this.submitClick(true)}>
                      Archive
                    </Button>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(UpdateApplicationForm);
