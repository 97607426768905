import React, { useState, useRef, useEffect, useCallback } from "react";

// Material Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Avatar from "@material-ui/core/Avatar";
import * as firebase from "firebase";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Tooltip,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";

// Material UI Icon
import Assignment from "@material-ui/icons/Assignment";

import ReactTable from "react-table";
import "../styles.scss";

const HuntsLeaderboard = ({
  param,
  handleChangeDownloadReport,
  downloadReport,
  backToMainList,
}) => {
  const search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  const getPageData = () => {
    let data = param.param;
    let array = ["05", 10, 20, 25, 50, 100, 200, 500];
    let newArray = [];
    array.forEach((num) => {
      if (Number(num) <= data.length) {
        newArray.push(num);
      }
    });
    newArray.push(array[newArray.length]);
    return newArray;
  };

  return (
    <GridContainer justify="center">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          width: "95%",
          marginBottom: 40,
        }}
      >
        <FormControl style={{ minWidth: 200 }}>
          <InputLabel id="demo-simple-select-label">Report Download</InputLabel>
          <Select
            label="Report Download"
            onChange={handleChangeDownloadReport}
            value={downloadReport}
          >
            <MenuItem value={"weekly"}>{`Weekly Report`}</MenuItem>
            <MenuItem value={"monthly"}>{`Monthly Report`}</MenuItem>
            <MenuItem value={"total"}>{`Total Report`}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Card>
        <CardHeader
          color="primary"
          icon
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4
              className={{
                ...cardTitle,
                marginTop: "15px",
                marginBottom: "0px",
              }}
              style={{
                color: "#3C4858",
              }}
            >
              Leaderboard
            </h4>
          </div>
          <CardIcon
            color="primary"
            onClick={() => backToMainList()}
            className="align-button"
          >
            Back
          </CardIcon>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={param.param || []}
            style={{
              overflow: "inherit",
            }}
            resizable={false}
            getTheadThProps={() => {
              return {
                style: {
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 400,
                  paddingRight: 10,
                  paddingLeft: 10,
                },
              };
            }}
            columns={[
              {
                Header: "User Name",
                accessor: "userName",
                filterable: search,
                headerClassName: "header-center-align",
                className: "cell-center-align",
                style: { whiteSpace: "normal" },
                minWidth: 170,
              },
              {
                Header: `First \n & last name`,
                accessor: "name",
                filterable: search,
                headerClassName: "header-center-align",
                className: "cell-center-align",
                style: { whiteSpace: "normal" },
                minWidth: 170,
              },
              {
                Header: "User Type ",
                accessor: "userType",
                filterable: search,
                headerClassName: "header-center-align",
                className: "cell-center-align",
                style: { whiteSpace: "normal" },
                minWidth: 150,
              },
              {
                Header: "Hunt name ",
                accessor: "huntName",
                filterable: search,
                headerClassName: "header-center-align",
                className: "cell-center-align",
                style: { whiteSpace: "normal" },
                minWidth: 200,
              },
              {
                Header: "Mission Completed",
                accessor: "completed",
                sortable: false,
                filterable: false,
                headerClassName: "header-center-align",
                className: "cell-center-align",
                style: { whiteSpace: "normal" },
                minWidth: 200,
              },
              {
                Header: "Leaderboard score",
                accessor: "points",
                sortable: false,
                filterable: false,
                headerClassName: "header-center-align",
                className: "cell-center-align",
                style: { whiteSpace: "normal" },
                minWidth: 200,
              },
            ]}
            minRows={0}
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
            autoResetHiddenColumns={true}
            showPageSizeOptions={true}
            pageSizeOptions={getPageData()}
            NoDataComponent={() => (
              <div
                className="rt-noData"
                style={{
                  marginTop: 150,
                }}
              >
                No rows found
              </div>
            )}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
};

export default HuntsLeaderboard;
