import React, { useState, useRef, useEffect } from "react";
import * as firebase from "firebase";
import moment from "moment";
import ReactTable from "react-table";
import "../styles.scss";
import { sortingAccordingCreation } from "../../../../utils";

// Material Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// Material UI Icon
import Assignment from "@material-ui/icons/Assignment";

// User Component
import CustomPagination from "../../../../components/CustomPagination/CustomPagination";
import ApprovedComponent from "../ApprovedComponent/ApprovedComponent.jsx";

const MissionApproved = () => {
  const [tableValues, setTableValues] = useState([]);
  const [open, setOpen] = useState({ isOpen: false, param: {} });

  const search = (filter, row) => {
    if (typeof row[filter.id] === "string") {
      let rowText = String(row[filter.id]).toUpperCase();
      let searchText = filter.value.toUpperCase();
      return rowText.indexOf(searchText) > -1;
    } else {
      let rowText = String(row[filter.id]["props"]["children"]).toUpperCase();
      let searchText = filter.value.toUpperCase();
      return rowText.indexOf(searchText) > -1;
    }
  };
  const db = useRef(firebase.database()).current;
  const pendingMissionRef = db.ref(
    `scavenger-hunt-items/pending-hunt-mission-photos/`
  );

  useEffect(() => {
    getPendingMissionList();
  }, []);

  const getPendingMissionList = () => {
    try {
      let dataArray = [];
      pendingMissionRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          Object.keys(snapshot.val()).map((key) => {
            dataArray.push(tableDataFormat(snapshot.val()[key], key));
          });
          sortingAccordingCreation(dataArray);
          setTableValues(dataArray);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const tableDataFormat = (item) => {
    return {
      timestamp: item.date,
      asset: <img src={item.asset} style={{ height: 100, width: 100 }} />,
      date: moment(item.date).format("MM-DD-YYYY hh:mm A") || "",
      description: item.description || "",
      huntId: item.huntId || "",
      huntName: item.huntName || "",
      id: item.id,
      missionId: item.missionId,
      status: (
        <span
          style={{
            color: item.status === "pending" ? "red" : "black",
            fontWeight: item.status === "pending" ? "bold" : 100,
          }}
        >
          {item.status === "pending" ? "Pending" : item.status}
        </span>
      ),
      title: item.title || "",
      username: item.username,
      type: item.type || "",
      userId: item.userId,
    };
  };

  const onClickRow = (state, rowInfo, column, instance) => {
    return {
      onClick: (e) => {
        setOpen({ isOpen: true, param: rowInfo.original });
      },
    };
  };

  const getPageData = () => {
    let data = tableValues;
    let array = ["05", 10, 20, 25, 50, 100, 200, 500];
    let newArray = [];
    array.forEach((num) => {
      if (Number(num) <= data.length) {
        newArray.push(num);
      }
    });
    newArray.push(array[newArray.length]);
    return newArray;
  };

  return (
    <GridContainer justify="center">
      <GridItem item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            icon
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4
                className={{
                  ...cardTitle,
                  marginTop: "15px",
                  marginBottom: "0px",
                }}
                style={{
                  color: "#3C4858",
                }}
              >
                Approval
              </h4>
            </div>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={tableValues || []}
              filterable
              resizable={false}
              // getTheadThProps={() => {
              //   return {
              //     style: {
              //       fontSize: 16,
              //       fontStyle: "normal",
              //       fontWeight: 400,
              //       paddingRight: 10,
              //       paddingLeft: 10,
              //     },
              //   };
              // }}
              // getTbodyProps={() => {
              //   return {
              //     style: {
              //       overflowX: "hidden",
              //       display: "block",
              //     },
              //   };
              // }}
              getTrProps={onClickRow}
              columns={[
                {
                  Header: "Scavenger Hunt",
                  accessor: "huntName",
                  filterMethod: search,
                  headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  style: { whiteSpace: "normal" },
                  //minWidth: 200,
                },
                {
                  Header: "Mission Name",
                  accessor: "title",
                  filterMethod: search,
                  // headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  // style: { whiteSpace: "normal" },
                  //minWidth: 200,
                },
                {
                  Header: "Mission Description",
                  accessor: "description",
                  filterMethod: search,
                  // headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  // style: { whiteSpace: "normal" },
                  //minWidth: 200,
                },
                {
                  Header: "User Name",
                  accessor: "username",
                  filterable: search,
                  // headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  // style: { whiteSpace: "normal" },
                  //minWidth: 150,
                },
                {
                  Header: "Date & time",
                  accessor: "date",
                  // headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  sortable: true,
                  filterable: false,
                  // style: { whiteSpace: "normal" },
                  //minWidth: 150,
                },
                {
                  Header: "Mission Proof",
                  accessor: "asset",
                  sortable: false,
                  filterable: false,
                  // headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  // style: { whiteSpace: "normal" },
                  //minWidth: 150,
                },
                {
                  Header: "Status",
                  accessor: "status",
                  filterMethod: search,
                  // headerClassName: "header-center-align",
                  // className: "cell-center-align",
                  // style: { whiteSpace: "normal" },
                  //minWidth: 120,
                },
              ]}
              // PaginationComponent={CustomPagination}
              // minRows={0}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
              // autoResetHiddenColumns={true}
              // showPageSizeOptions={true}
              // pageSizeOptions={getPageData()}
              // NoDataComponent={() => (
              //   <div
              //     className="rt-noData"
              //     style={{
              //       marginTop: 120,
              //     }}
              //   >
              //     No rows found
              //   </div>
              // )}
              // style={{
              //   height: 750,
              //   paddingLeft: 10,
              //   paddingRight: 10,
              //   paddingBottom: 20,
              // }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {open.isOpen && (
        <ApprovedComponent
          param={open.param}
          onClose={() => setOpen({ isOpen: false, param: {} })}
        />
      )}
    </GridContainer>
  );
};

export default MissionApproved;
