// Import necessary libraries and components
import React, { useState } from "react";
import * as firebase from "firebase";
import axios from "axios";

// Importing Material-UI core components
import {
  withStyles,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

// Importing core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";

// Defining style for views
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";
import { secondaryFirebase } from "../..";

// Defining AccountForm component
class AccountForm extends React.Component {
  constructor(props) {
    // Initialize the base class
    super(props);

    // Set the initial poi to update
    let groupToUpdate = this.props.data || {};

    // Initalize state properties
    this.state = {
      firstName: groupToUpdate.firstname,
      lastName: groupToUpdate.lastname,
      email: groupToUpdate.email,
      contact: groupToUpdate.phoneNumber,
      groupId: groupToUpdate.groupid,
      groupData: [],
      shouldRefresh: false,
      enableRefresh: false,
      group: {},
      firstNameState: "",
      lastNameState: "",
      emailState: "",
      contactState: "",
      groupIdState: "",
    };
    //console.log(props);
    // Initialize firebase database and group reference
    this.db = firebase.database();
    this.groupRef = this.db.ref(`group/`);
  }

  // Define React life-cycle hook to fetch data when component is mounted
  componentDidMount() {
    this.fetchList();
  }

  // Function to fetch the list
  fetchList = () => {
    let dataArr = [];
    this.fetchGroupDataByTableRef(this.groupRef, dataArr);
  };

  // Function to fetch group data by table ref.
  fetchGroupDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val()) {
          dataArr.push(
            this.fetchGroupData(child.key, child.val(), tableReference)
          );
        }
      });
      let groupValue = dataArr.find((group) => group.id === this.state.groupId);
      this.setState({ groupData: dataArr, group: groupValue });
    });
  };

  // Function to fetch group data
  fetchGroupData = (key, data) => {
    return {
      id: key,
      title: data.name,
    };
  };

  // Function to handle click on finish button
  finishButtonClick = async () => {
    this.isValidated();
    let userData = {};
    userData.firstname = this.state.firstName;
    userData.lastname = this.state.lastName;
    userData.email = this.state.email;
    userData.phoneNumber = this.state.contact;
    userData.groupid =
      this.state.group && this.state.group.id ? this.state.group.id : "";
    userData.active = true;
    //userData.adminStatus = "group";
    if (this.state.group.title === "Administrator") {
      userData.adminStatus = "project";
    } else {
      userData.adminStatus = "group";
    }
    let userRef;
    if (this.props.isUpdating) {
      userRef = this.db.ref(`users/${this.props.data.id}`);
      try {
        userData.id = this.props.data.id;
        let result = await Promise.all([userRef.set(userData)]);
        this.setState({
          ...this.state,
          open: true,
          enableRefresh: true,
          resultMessage: "Success! Your Account was uploaded to the WAM app.",
        });
        this.props.onSubmit();
      } catch (error) {
        console.log(error);
      }
    } else {
      // Form Validation
      if (
        this.state.firstNameState === "success" &&
        this.state.lastNameState === "success" &&
        this.state.emailState === "success" &&
        this.state.contactState === "success" &&
        this.state.groupIdState === "success"
      ) {
        secondaryFirebase
          .auth()
          .createUserWithEmailAndPassword(this.state.email, "Martinsville123@")
          .then(async (response) => {
            userRef = this.db.ref(`users/${response.user.uid}/`);
            userData.id = response.user.uid;
            try {
              let result = await Promise.all([userRef.set(userData)]);
              let url = new URL(
                `https://us-central1-we-are-martinsville-8433f.cloudfunctions.net/sendNewUserInvite?dest=
              ${this.state.email}`
              );
              axios
                .post(url, "", { headers: { Accept: "*/*" } })
                .then((response) => console.log(response))
                .catch((error) => console.log(error));
              this.setState({
                ...this.state,
                open: true,
                enableRefresh: true,
                resultMessage:
                  "Success! Your Account was uploaded to the WAM app.",
              });
              this.props.onSubmit();
            } catch (error) {
              console.log(error);
              this.setState({
                ...this.state,
                open: true,
                resultMessage: error.message,
              });
            }
            secondaryFirebase.auth().signOut();
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              ...this.state,
              open: true,
              resultMessage: error.message,
            });
          });
      }
      // Form validation
    }
  };

  // Function to validate the form data
  isValidated() {
    if (
      this.state.firstNameState === "success" &&
      this.state.lastNameState === "success" &&
      this.state.emailState === "success" &&
      this.state.contactState === "success" &&
      this.state.groupIdState === "success"
    ) {
      return true;
    } else {
      if (this.state.firstNameState !== "success") {
        this.setState({ firstNameState: "error" });
      }
      if (this.state.lastNameState !== "success") {
        this.setState({ lastNameState: "error" });
      }
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (this.state.contactState !== "success") {
        this.setState({ contactState: "error" });
      }
      if (this.state.groupIdState !== "success") {
        this.setState({ groupIdState: "error" });
      }
    }
    return false;
  }

  // Function to change event
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: event.target.value });
        break;
      case "integer":
        if (this.verifyInteger(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        this.setState({ [stateName]: parseInt(event.target.value) });
        break;
      default:
        this.setState({ [stateName]: event.target.value });
        break;
    }
  }

  // This function verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // Function to validate integer
  verifyInteger(value) {
    return !isNaN(parseInt(value));
  }

  // Function to handle close state
  handleClose = (event, reason) => {
    this.setState({ open: false, shouldRefresh: this.state.enableRefresh });
  };

  // Back to previous screen
  goBack = () => {
    this.props.onSubmit();
  };

  // Function to handle Cancel button
  cancelButtonClick = () => {
    this.setState({
      firstNameState: "",
      lastNameState: "",
      emailState: "",
      contactState: "",
      groupIdState: "",
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      groupId: undefined,
      group: undefined,
    });
  };

  // Render the component
  render() {
    // Destructure props
    const { classes } = this.props;

    // Return the component
    return (
      <GridContainer justify="center">
        <GridItem xs={8} sm={8}>
          <div className={classes.wizardContainer} ref="group">
            <h3 className={classes.title}>We Are Martinsville</h3>
            <h5 className={classes.subtitle}>User Admin</h5>
            <Card className={classes.card}>
              <div className={classes.wizardHeader}>
                <h3 className={classes.title}>User Accounts</h3>
                <h5 className={classes.subtitle}>
                  Create a User Account and tag User Group
                </h5>
              </div>
              <form style={{ margin: "25px" }}>
                <CustomInput
                  success={this.state.firstNameState === "success"}
                  error={this.state.firstNameState === "error"}
                  labelText={<span>First Name</span>}
                  id="firstName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.firstName,
                    inputProps: {
                      maxLength: 50,
                    },
                    onChange: (event) =>
                      this.change(event, "firstName", "length", 3),
                  }}
                />
                <CustomInput
                  success={this.state.lastNameState === "success"}
                  error={this.state.lastNameState === "error"}
                  labelText={<span>Last Name</span>}
                  id="lastName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.lastName,
                    inputProps: {
                      maxLength: 50,
                    },
                    onChange: (event) =>
                      this.change(event, "lastName", "length", 3),
                  }}
                />
                <CustomInput
                  success={this.state.emailState === "success"}
                  error={this.state.emailState === "error"}
                  labelText={<span>Email Address</span>}
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.email,
                    inputProps: {
                      maxLength: 50,
                    },
                    onChange: (event) =>
                      this.change(event, "email", "length", 3),
                  }}
                />
                <CustomInput
                  success={this.state.contactState === "success"}
                  error={this.state.contactState === "error"}
                  labelText={<span>Contact Number</span>}
                  id="contact"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.contact,
                    type: "number",
                    onChange: (event) =>
                      this.change(event, "contact", "integer", 5),
                  }}
                />
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                  error={this.state.groupIdState === "error"}
                >
                  <InputLabel
                    htmlFor="poi-classification"
                    className={classes.selectLabel}
                    style={{ textTransform: "none" }}
                  >
                    User Groups
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.group}
                    onChange={(event) => {
                      this.setState({
                        group: event.target.value,
                        groupIdState: "success",
                      });
                    }}
                    inputProps={{
                      name: "group",
                      id: "poi-classification",
                    }}
                  >
                    {Object.keys(this.state.groupData).map((key) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={this.state.groupData[key]}
                      >
                        {this.state.groupData[key].title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </form>
              <div className={classes.footer}>
                <div className={classes.right}>
                  <Button
                    color="info"
                    className={this.finishButtonClasses}
                    onClick={() => this.finishButtonClick()}
                  >
                    Submit
                  </Button>
                </div>
                <div className={classes.right}>
                  <Button
                    color="danger"
                    className={this.finishButtonClasses}
                    onClick={this.cancelButtonClick}
                  >
                    Cancel
                  </Button>
                </div>
                <div className={classes.right}>
                  <Button
                    color="danger"
                    className={this.finishButtonClasses}
                    onClick={this.goBack}
                  >
                    Back
                  </Button>
                </div>
                <div className={classes.clearfix} />
              </div>
            </Card>
          </div>
        </GridItem>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={this.handleClose}
          open={this.state.open}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.resultMessage}</span>}
          action={[]}
        />
      </GridContainer>
    );
  }
}

// Exporting the component with styles
export default withStyles(wizardStyle)(AccountForm);
