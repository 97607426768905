import React from "react";
import { Redirect } from "react-router-dom";
import { GeoFire } from "geofire";

import Snackbar from "@material-ui/core/Snackbar";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import * as firebase from "firebase";
import { POI } from "../../constants/Common.js";
import PhotoUploadForm from "./PhotoUpload/PhotoUploadForm.jsx";

class PhotoUploadWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      resultMessage: "Success!",
      shouldRefresh: false,
      enableRefresh: false,
      photoToUpdate: this.props.data
    };
  }

  handleClose = (event, reason) => {
    this.setState({ open: false, shouldRefresh: this.state.enableRefresh });
  };

  render() {
    const { dataToUpdate } = this.props;
    if (this.state.shouldRefresh) {
      return (
        <Redirect
          to={{ pathname: "/auth/lock-screen-page", params: { isFrom: POI } }}
          push={true}
        />
      );
    } else {
      return (
        <GridContainer justify="center">
          <GridItem xs={8} sm={8}>
            <Wizard
              validate
              steps={[
                {
                  stepName: "Photos Review and Approval",
                  stepComponent: PhotoUploadForm,
                  stepId: "photoUpload",
                  data:
                    dataToUpdate && dataToUpdate.title !== undefined
                      ? dataToUpdate
                      : this.state.photoToUpdate
                }
              ]}
              title="Create a Photo Upload Validation"
              subtitle="Create a Validation Guide for the Photo Upload"
              finishButtonClick={finalState =>
                this.props.handleGameCardPhotoUpload(finalState)
              }
              color={"info"}
            />
          </GridItem>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            onClose={this.handleClose}
            open={this.state.open}
            autoHideDuration={3000}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.resultMessage}</span>}
            action={[]}
          />
        </GridContainer>
      );
    }
  }
}

export default PhotoUploadWizard;
