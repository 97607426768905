import React, { useState } from "react";
import { DialogActions } from "@material-ui/core";
import "./style.scss";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Title from "@material-ui/icons/Title";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const TextFieldStyle = {
  textAreaInputLabelActive: {
    color: "#4caf50",
    opacity: 1,
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.42857,
  },
  textAreaInputLabelInactive: {
    color: "#AAAAAA",
    opacity: 1,
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 1.42857,
  },
  underlineClasses: {
    borderColor: "red",
    borderBottom: 2,
    backgroundColor: "red",
  },
  inputRoot: {
    "&.MuiInput-underline:before": {
      borderBottom: "2px solid white",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "2px solid yellow",
    },
    "&.MuiInput-underline:hover:before": {
      borderBottom: "2px solid green",
    },
  },
};

const POICardDialog = ({ open, infoCardData, onHandleClose, poiToUpdate }) => {
  const [fieldName, setFieldName] = useState(
    Boolean(poiToUpdate) ? poiToUpdate.infoCardText : ""
  );
  const [descriptionState, setDescriptionState] = useState(
    Boolean(poiToUpdate) ? poiToUpdate.infoCardDescription : ""
  );
  const [citations, setCitations] = useState(
    Boolean(poiToUpdate) && poiToUpdate.infoCardCitations
      ? poiToUpdate.infoCardCitations
      : ""
  );
  const [fontColor, setFontColor] = useState(
    Boolean(poiToUpdate) ? poiToUpdate.infoCardFontColor : "#000000"
  );
  const [backgroundColor, setBackgroundColor] = useState(
    Boolean(poiToUpdate) ? poiToUpdate.infoCardBackgroundColor : "#ffffff"
  );
  infoCardData.fontColor = fontColor;
  infoCardData.backgroundColor = backgroundColor;
  infoCardData.fieldName = fieldName;
  infoCardData.descriptionState = descriptionState;
  infoCardData.citations = citations;
  return (
    <div className="mainComponentStyle">
      <h3 className="header-text-align">{`Create a Information Card`}</h3>
      <h5 className="header-text-align">{`Create a Information Card for the WAM app`}</h5>
      <Button color="info" className="headed-button-style">
        <span className="headed-button-text-style">information card</span>
      </Button>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <div className="fieldComponentStyle">
          <CustomInput
            success={fieldName.length >= 3 ? true : false}
            labelText={<span>Field Name To Be Displayed</span>}
            id="fieldName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: fieldName,
              inputProps: {
                maxLength: 250,
              },
              onChange: (event) => {
                setFieldName(event.target.value);
                infoCardData.fieldName = event.target.value;
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Title />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl fullWidth>
          <TextField
            color="success"
            fullWidth
            multiline
            rows={10}
            label={`Description`}
            variant="standard"
            value={descriptionState}
            classes={{
              inputRoot: TextFieldStyle.inputRoot,
            }}
            InputLabelProps={{
              style:
                descriptionState.length >= 3
                  ? TextFieldStyle.textAreaInputLabelActive
                  : TextFieldStyle.textAreaInputLabelInactive,
            }}
            onChange={(event) => {
              setDescriptionState(event.target.value);
              infoCardData.descriptionState = event.target.value;
            }}
          />
        </FormControl>
        <CustomInput
          success={citations.length >= 3 ? true : false}
          labelText={<span>Citation/Information Source</span>}
          id="description"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            inputProps: {
              maxLength: 100,
            },
            value: citations,
            onChange: (event) => {
              setCitations(event.target.value);
              infoCardData.citations = event.target.value;
            },
            endAdornment: (
              <InputAdornment position="end">
                <Title />
              </InputAdornment>
            ),
          }}
        />
        <div className="colorComponent">
          <span className="colorButtonStyle">Change Font Color</span>
          <input
            type="color"
            value={fontColor}
            onChange={(event) => {
              setFontColor(event.target.value);
              infoCardData.fontColor = event.target.value;
            }}
          />
        </div>
        <div className="colorComponent">
          <span className="colorButtonStyle">Change Background Color</span>
          <input
            type="color"
            value={backgroundColor}
            onChange={(event) => {
              setBackgroundColor(event.target.value);
              infoCardData.backgroundColor = event.target.value;
            }}
          />
        </div>
      </GridItem>
      <div>
        <DialogActions>
          <Button
            disabled={
              !(
                fieldName.length >= 3 &&
                descriptionState.length >= 3 &&
                Boolean(fontColor) &&
                Boolean(backgroundColor)
              )
            }
            color="info"
            className="nextButtonStyle"
            onClick={onHandleClose}
          >
            <span className="nextButtonTextStyle">Next</span>
          </Button>
        </DialogActions>
      </div>
    </div>
  );
};

export default POICardDialog;
