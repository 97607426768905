import React, { useState, useEffect, useRef } from "react";
import "../style.scss";
import * as firebase from "firebase";
import { IconButton, Tooltip, MenuItem, Select, Menu } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Dehaze from "@material-ui/icons/Dehaze";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Switch from "@material-ui/core/Switch";
import MoreVert from "@material-ui/icons/MoreVert";
import { CSVLink } from "react-csv";
import moment from "moment";
import SurveyModal from "../SurveyModal";
import Button from "components/CustomButtons/Button.jsx";
import * as Excel from "exceljs";
import download from "downloadjs";
import { withStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

const ActionComponent = ({ row, deleteIconOnHandle, moreVertIconOnHandle }) => {
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#fff",
      "&$checked": {
        color: "#fff",
      },
      "&$checked + $bar": {
        opacity: 1,
        backgroundColor: "#1BB394",
      },
    },
    icon: {
      height: 12,
      width: 12,
      borderRadius: 6,
      padding: 0,
      position: "absolute",
      top: 21,
    },
    bar: {
      height: 20,
      width: 34,
      borderRadius: 10,
      backgroundColor: "#DC143C",
      opacity: 1,
    },
    checked: {},
  })(Switch);

  const [csvData, setCsvData] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const [fileFormat, setFileFormat] = React.useState("");
  const db = useRef(firebase.database()).current;
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchData, setSwitchData] = useState(false);
  const [showAlert, setShowAlert] = React.useState({
    boolean: false,
    message: "",
  });

  useEffect(() => {
    if (!row.original.id) return;
    downloadReport(row.original.id);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadReport = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let dataArr = [];
        let active;
        const saveSurveyRef = await (await db
          .ref(`publishedSurvey/${id}/`)
          .once("value")).val();
        if (saveSurveyRef) {
          Object.values(saveSurveyRef).map((k) => {
            active = Date.now() >= k["startTime"] && Date.now() <= k["endTime"];
            if (active) {
              k["active"] = active ? !k["active"] : false;
              setSwitchData(active ? !k["active"] : false);
            }
            k["attemptedUser"] &&
              Object.keys(k["attemptedUser"]).map((key) => {
                dataArr.push(downloadCsvPushData(k["attemptedUser"][key], k));
              });
          });
        }
        setCsvData(dataArr || []);
        resolve(dataArr);
      } catch (error) {
        reject(error);
      }
    });
  };

  const downloadCsvPushData = (userData, k) => {
    return {
      "Survey Published On": moment(k.publishDate).format("MM-DD-YYYY"),
      "Survey Completed On": moment(k.endTime).format("MM-DD-YYYY"),
      Username: userData["username"],
      ...JSON.parse(userData["data"]),
    };
  };

  const handleChangeFileFormat = (event) => {
    setFileFormat(event.target.value);
  };

  const handleOnSwitch = async (id, boolean) => {
    if (id) {
      let active;
      let publishSurveyRef = await db.ref(`publishedSurvey/${id}`);
      let saveSurveyRef = (await publishSurveyRef.once("value")).val();
      if (saveSurveyRef) {
        Object.values(saveSurveyRef).map((k) => {
          active = Date.now() >= k["startTime"] && Date.now() <= k["endTime"];
          if (active) {
            k["active"] = boolean;
            setSwitchData(boolean);
          } else {
            setShowAlert({
              boolean: true,
              message: "Survey expired. Please Publish again with future dates",
            });
          }
        });
      } else {
        setShowAlert({ boolean: true, message: "Survey is not published" });
      }
      publishSurveyRef.set(saveSurveyRef);
    }
  };

  const generateXlsxReport = async (row) => {
    downloadReport(row)
      .then((res) => {
        let dataArr = [];
        if (Boolean(res.length)) {
          let workbook = new Excel.Workbook();
          var worksheet = workbook.addWorksheet("Survey_Report");
          for (const item of res) {
            Object.keys(item).map((key) => {
              dataArr.push({
                header: key,
                key: key,
                width: 20,
              });
            });
            let jsonObject = dataArr.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
            worksheet.columns = uniqueArray;
            worksheet.addRow(item);
          }
          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data]);
            download(
              blob,
              "Survey_Report" +
                new Date()
                  .toLocaleDateString()
                  .split("/")
                  .join("") +
                ".xlsx"
            );
          });
        }
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div className="align-responsive-1200">
          <Tooltip title="Turn on/Off" arrow>
            <CustomSwitch
              checked={switchData}
              onChange={() => handleOnSwitch(row.original.id, !switchData)}
            />
          </Tooltip>
          <IconButton
            disabled={!!csvData.length ? false : true}
            onClick={() => setExportModal(true)}
          >
            <Tooltip
              title={
                !!csvData.length ? "Download Report" : "No responses recorded"
              }
              arrow
            >
              <CloudDownload />
            </Tooltip>
          </IconButton>

          <Tooltip title="Delete survey" arrow>
            <IconButton onClick={() => deleteIconOnHandle()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => moreVertIconOnHandle()}>
            <Tooltip title="Publish survey" arrow>
              <MoreVert />
            </Tooltip>
          </IconButton>
        </div>
      </div>
      <div className="align-responsive-600">
        <IconButton onClick={handleClick}>
          <Tooltip title="Expand button" arrow>
            <Dehaze />
          </Tooltip>
        </IconButton>
      </div>
      <Menu
        id="long-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip title="Turn on/Off" arrow>
            <CustomSwitch
              checked={switchData}
              onChange={() => handleOnSwitch(row.original.id, !switchData)}
            />
          </Tooltip>
          <IconButton
            disabled={!!csvData.length ? false : true}
            onClick={() => setExportModal(true)}
          >
            <Tooltip
              title={
                !!csvData.length ? "Download Report" : "No responses recorded"
              }
              arrow
            >
              <CloudDownload />
            </Tooltip>
          </IconButton>

          <Tooltip title="Delete survey" arrow>
            <IconButton onClick={() => deleteIconOnHandle()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => moreVertIconOnHandle()}>
            <Tooltip title="Publish survey" arrow>
              <MoreVert />
            </Tooltip>
          </IconButton>
        </div>
      </Menu>
      <SurveyModal
        openModal={exportModal}
        title={`Data Export`}
        content={
          <Button
            style={{
              backgroundColor: `#00ACC1`,
              borderRadius: 3,
              width: `95%`,
            }}
          />
        }
        subContain={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <span className="export-modal-text">Output File Format</span>
            <Select
              className="export-modal-select"
              value={fileFormat}
              onChange={(event) => handleChangeFileFormat(event)}
            >
              <MenuItem className="export-modal-select" value={"CSV"}>
                CSV
              </MenuItem>
              <MenuItem className="export-modal-select" value={"xlsx"}>
                xlsx
              </MenuItem>
            </Select>
          </div>
        }
        ok={
          fileFormat !== "CSV" ? (
            <Button
              disabled={fileFormat === "xlsx" ? false : true}
              style={{
                backgroundColor: `#942CAE`,
                borderRadius: 3,
                width: `50%`,
              }}
              onClick={() => {
                setExportModal(false);
                setFileFormat("");
                generateXlsxReport(row.original.id);
              }}
            >
              <span>{`Download ` + fileFormat}</span>
            </Button>
          ) : (
            <>
              <CSVLink
                data={csvData}
                filename={`Survey report-${Date.now()}.csv`}
                onClick={() => {
                  setExportModal(false);
                }}
                className="csv-Button-Style"
              >
                <span className="csv-Span-text">
                  {`Download ` + fileFormat}
                </span>
              </CSVLink>
            </>
          )
        }
        cancel={
          <Button
            style={{
              backgroundColor: `#C0C0C0`,
              borderRadius: 3,
              width: `50%`,
            }}
            onClick={() => {
              setExportModal(false);
              setFileFormat("");
            }}
          >
            <span>Cancel</span>
          </Button>
        }
      />
      {showAlert.boolean && (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={showAlert.message}
          onConfirm={() => setShowAlert({ boolean: false, message: "" })}
          onCancel={() => setShowAlert({ boolean: false, message: "" })}
        />
      )}
    </div>
  );
};
export default ActionComponent;
