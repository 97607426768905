import React from "react";

// @material-ui/core components
import { withStyles, IconButton } from "@material-ui/core";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import Snackbar from "../../components/Snackbar/Snackbar";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import PhotoGalleryTable from "components/Table/PhotoGalleryTable.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PdfIcon from "assets/img/pdf.png";
import ExcelIcon from "assets/img/excel.png";
import * as firebase from "firebase";
import Button from "components/CustomButtons/Button.jsx";
import jsPDF from "jspdf";
import download from "downloadjs";
import * as Excel from "exceljs";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { sortingAccordingCreation } from "../../utils";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

class RegularTables extends React.Component {
  static refreshPhotoData = false;
  constructor(props) {
    super(props);
    this.state = {
      photos: [{}],
      pathname: "",
      open: false,
      folderName: "",
      usersList: [],
      errorData: { errorMessage: "", errorColor: "", place: "" },
    };
    this.db = firebase.database();
    this.photosRef = this.db.ref("photos/");
    this.currentkey = "";
  }
  componentDidMount() {
    this.getPhotosData();
    this.getUsersList();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.location.pathname !== prevState.pathname) {
      PhotoGalleryTable.refreshPhotoData = true;
      return {
        pathname: nextProps.location.pathname,
      };
    }
    return null;
  };

  componentDidUpdate() {
    if (PhotoGalleryTable.refreshPhotoData) {
      PhotoGalleryTable.refreshPhotoData = false;
      this.getPhotosData();
      this.getFolderName();
    }
  }

  getPhotosData = () => {
    this.photosRef.on(
      "value",
      (snapshot) => {
        let photos = [];
        snapshot.forEach((child) => {
          if (child && child.key && child.val()) {
            child.forEach((data) => {
              if (data && data.val() && data.val().status === "approved") {
                let value = data.val();
                const feedbackObject = (
                  <div className="feedback-container">
                    <span>{value.reaction === "positive" ? "+" : "-"}</span>
                  </div>
                );
                if (
                  this.props.location.pathname === "/admin/community" &&
                  !value.eventId
                ) {
                  photos.push({
                    timestamp: new Date(value.date).getTime() || "",
                    id: value.id,
                    username: value.username,
                    title: value.title,
                    description: value.description,
                    points: value.points,
                    image: value.asset,
                    date: value.date,
                    reaction: value.reaction,
                    lat: value.lat,
                    lng: value.lng,
                    userId: value.userId,
                    parentKey: child.key,
                    feedbackObject,
                  });
                } else if (
                  this.props.location.state &&
                  this.props.location.state.id !== undefined &&
                  value.eventId !== undefined &&
                  this.props.location.state.id === value.eventId
                ) {
                  photos.push({
                    timestamp: new Date(value.date).getTime() || "",
                    id: value.id,
                    username: value.username,
                    title: value.title,
                    description: value.description,
                    points: value.points,
                    image: value.asset,
                    date: value.date,
                    reaction: value.reaction,
                    lat: value.lat,
                    lng: value.lng,
                    userId: value.userId,
                    parentKey: child.key,
                    feedbackObject,
                  });
                }
              }
            });
          }
        });
        sortingAccordingCreation(photos);
        this.setState({ photos: photos });
      },
      (error) => {
        this.setState({
          open: true,
          errorData: {
            errorMessage: error.message,
            errorColor: "rose",
            place: "tc",
          },
        });
      }
    );
  };

  getUsersList = async () => {
    let usersList = await this.db.ref("profiles").once("value");
    if (usersList.val()) {
      usersList =
        Object.keys(usersList.val()).map((key) => {
          return {
            key: key,
            ...usersList.val()[key],
          };
        }) || [];
    }
    this.setState({ usersList });
  };

  getFolderName = async () => {
    if (
      this.props.location.state &&
      this.props.location.state.id !== undefined
    ) {
      let eventData = await this.db
        .ref(`game-cards/${this.props.location.state.id}/seriesDetails/name`)
        .once("value");
      if (eventData.val()) this.setState({ folderName: eventData.val() });
    } else if (this.props.location.pathname === "/admin/community") {
      this.setState({ folderName: "Community" });
    }
  };

  generatePhotoReport = async () => {
    const { photos, folderName, usersList } = this.state;
    if (photos && photos.length <= 0) return;
    this.setState({
      open: true,
      errorData: {
        errorMessage: "Generating Report ...",
        errorColor: "info",
        place: "bl",
      },
    });

    let pdfDocument = new jsPDF("p", "mm", "a4");
    pdfDocument.setFontSize(18);
    pdfDocument.text("We are Martinsville", 80, 15);
    pdfDocument.setFontSize(12);
    pdfDocument.text(folderName, 10, 20);
    pdfDocument.line(5, 21, 205, 21);
    pdfDocument.setFontSize(10);
    let y = 23;
    let counter = 5;

    let key = 0;
    for (const photo of photos) {
      let userData = usersList.find((user) => user.key === photo.userId) || {};
      if (key !== 0 && key % 6 === 0) {
        pdfDocument.addPage();
        pdfDocument.setFontSize(18);
        pdfDocument.text("We are Martinsville", 80, 15);
        pdfDocument.setFontSize(12);
        pdfDocument.text(folderName, 10, 20);
        pdfDocument.line(5, 21, 205, 21);
        pdfDocument.setFontSize(10);
        y = 20;
        counter = 5;
      }

      if (photo.image) {
        await this.getBase64ImageFromUrl(photo.image)
          .then((result) =>
            pdfDocument.addImage(result, "png", 8, y + counter, 20, 20)
          )
          .catch((err) => {
            console.error(err);
          });
      }
      pdfDocument.setFontType("bold");
      pdfDocument.text("Reaction : ", 30, y + counter + 10);
      pdfDocument.setFontType("normal");
      pdfDocument.text(photo.reaction || "", 50, y + counter + 10);

      pdfDocument.setFontType("bold");
      pdfDocument.text("User Name : ", 100, y + counter);
      pdfDocument.setFontType("normal");
      pdfDocument.text(photo.username || "", 140, y + counter);

      pdfDocument.setFontType("bold");
      pdfDocument.text("Title : ", 8, y + counter + 25);
      pdfDocument.setFontType("normal");
      pdfDocument.text(photo.title || "", 40, y + counter + 25);

      pdfDocument.setFontType("bold");
      pdfDocument.text("User Type : ", 100, y + counter + 5);
      pdfDocument.setFontType("normal");
      pdfDocument.text(userData.type || "", 140, y + counter + 5);

      pdfDocument.setFontType("bold");
      pdfDocument.text("Description : ", 8, y + counter + 30);
      pdfDocument.setFontType("normal");
      pdfDocument.text(photo.description || "", 40, y + counter + 30);

      pdfDocument.setFontType("bold");
      pdfDocument.text("User Location : ", 100, y + counter + 10);
      pdfDocument.setFontType("normal");
      pdfDocument.text(userData.address || "", 140, y + counter + 10);

      pdfDocument.setFontType("bold");
      pdfDocument.text("Photo Location : ", 100, y + counter + 30);
      pdfDocument.setFontType("normal");
      pdfDocument.text(photo.lat + "/" + photo.lng, 140, y + counter + 30);

      pdfDocument.setFontType("bold");
      pdfDocument.text("Generation : ", 100, y + counter + 15);
      pdfDocument.setFontType("normal");
      pdfDocument.text(userData.generation || "", 140, y + counter + 15);

      pdfDocument.setFontType("bold");
      pdfDocument.text("Points : ", 100, y + counter + 20);
      pdfDocument.setFontType("normal");
      pdfDocument.text(
        (photo.points && photo.points.toString()) || "0",
        140,
        y + counter + 20
      );

      pdfDocument.setFontType("bold");
      pdfDocument.text("Submitted Date/Time : ", 100, y + counter + 25);
      pdfDocument.setFontType("normal");
      pdfDocument.text(
        new Date(photo.date).toDateString(),
        140,
        y + counter + 25
      );
      pdfDocument.line(5, y + counter + 35, 205, y + counter + 35);

      counter += 45;
      key++;
    }

    pdfDocument.save(
      "WAM Photos_" +
        folderName +
        "_" +
        new Date()
          .toLocaleDateString()
          .split("/")
          .join("") +
        ".pdf"
    );
    this.setState({
      open: false,
      errorData: {
        errorMessage: "",
        errorColor: "",
        place: "",
      },
    });
  };

  getBase64ImageFromUrl = async (imageUrl) => {
    const res = await axios.get(imageUrl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  };

  generateTestReport = async () => {
    const { photos, folderName, usersList } = this.state;
    if (photos && photos.length <= 0) return;
    this.setState({
      open: true,
      errorData: {
        errorMessage: "Generating Report ...",
        errorColor: "info",
        place: "bl",
      },
    });
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet("Photo Report");

    worksheet.columns = [
      { header: "User Name", key: "userName", width: 15 },
      { header: "User Type", key: "userType", width: 12 },
      { header: "Generation", key: "generation", width: 20 },
      { header: "User Location", key: "userLocation", width: 30 },
      { header: "Title", key: "title", width: 20 },
      { header: "Description", key: "description", width: 30 },
      { header: "Reaction", key: "reaction", width: 10 },
      { header: "Photo Location", key: "photoLocation", width: 40 },
      { header: "Submitted Date/Time", key: "dateTime", width: 20 },
      { header: "Points", key: "points", width: 10 },
      { header: "Image", key: "image", width: 20 },
    ];
    let key = 1;
    for (const photo of photos) {
      let userData = usersList.find((user) => user.key === photo.userId) || {};
      worksheet.addRow({
        userName: photo.username,
        userType: userData.type,
        generation: userData.generation,
        userLocation: userData.address,
        title: photo.title,
        description: photo.description,
        reaction: photo.reaction || "",
        photoLocation: photo.lat + "/" + photo.lng,
        dateTime: new Date(photo.date).toDateString(),
        points: photo.points || 0,
      });
      worksheet.getCell("K" + (key + 1)).value = {
        text: folderName + "_" + key,
        hyperlink: photo.image,
      };
      key++;
    }
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: this.blobType });
      download(
        blob,
        "WAM Photos_" +
          folderName +
          "_" +
          new Date()
            .toLocaleDateString()
            .split("/")
            .join("") +
          ".xlsx"
      );
      this.setState({
        open: false,
        errorData: {
          errorMessage: "",
          errorColor: "",
          place: "",
        },
      });
    });
  };

  onCloseBar = () => {
    this.setState({
      open: false,
      errorData: {
        errorMessage: "",
        errorColor: "",
        place: "",
      },
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridContainer
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <div>
            <IconButton size="small" onClick={this.generatePhotoReport}>
              <img src={PdfIcon} style={{ width: "30px", height: "30px" }} />
            </IconButton>
            <IconButton size="small" onClick={this.generateTestReport}>
              <img src={ExcelIcon} style={{ width: "25px", height: "25px" }} />
            </IconButton>
          </div>
        </GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Photo Gallery</h4>
            </CardHeader>
            <CardBody>
              <PhotoGalleryTable data={this.state.photos} />
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place={this.state.errorData.place}
          color={this.state.errorData.errorColor}
          open={this.state.open}
          message={
            <span style={{ fontSize: 18 }}>
              {this.state.errorData.errorMessage}
            </span>
          }
          close={this.onCloseBar}
          closeNotification={this.onCloseBar}
        />
      </GridContainer>
    );
  }
}

export default withStyles(style)(RegularTables);
