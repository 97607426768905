export const Permissions = {
  "Photos Review and Approval": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Photo Gallery": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Quiz Wizard": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Quiz Master List": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Place of Interest": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "POI Master List": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Groups and Functions": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "User Accounts": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  Applications: {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Game Card": {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  Approval: {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  Leaderboard: {
    create: false,
    read: false,
    update: false,
    archive: false
  },
  "Game Card List": {
    create: false,
    read: false,
    update: false,
    archive: false
  }
};
