import React, { useState, useRef } from "react";
import DateTimePicker from "react-datetime-picker";
import Button from "components/CustomButtons/Button.jsx";
// Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// Material Core Component
import { IconButton, Tooltip } from "@material-ui/core";
import * as firebase from "firebase";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// Material UI Icon
import { Close } from "@material-ui/icons";
import { Snackbar } from "@material-ui/core";
// style sheet
import "../styles.scss";

export const EditHunt = ({ close, mission = false, data, accept }) => {
  const db = useRef(firebase.database()).current;
  const handleOnSave = (err) => {
    if (err) {
      console.log("alert");
    }
    console.log("success");
  };
  const [deleteRow, setDeleteRow] = useState({
    isOpen: false,
    message: "",
  });
  const [date, setDate] = useState(new Date(data.startTime));
  const [endDate, setEndDate] = useState(new Date(data.endTime));
  const [radio, setRadio] = useState(data.gameplayTime);
  const [disabled, setDisabled] = useState(true);

  // Update Schduler
  const onHandleHuntUpdate = async (e, data) => {
    e.preventDefault();
    let scavengerRef;
    scavengerRef = db.ref(`scavenger-hunt-items/hunts-list/${data.id}`);
    let pointsSnapshot = await scavengerRef.once("value");
    let objectResult = {};
    if (pointsSnapshot) {
      let huntData = pointsSnapshot.val();
      objectResult = {
        ...huntData,
        startTime: Date.parse(new Date(date).toUTCString()),
        endTime: Date.parse(new Date(endDate).toUTCString()),
        gameplayTime: data.gameplayTime,
        isVisiable: true,
      };
      let second = Date.parse(new Date(endDate).toUTCString());
      let first = Date.parse(new Date(date).toUTCString());
      if (!Boolean(date) || !Boolean(endDate) || second < first) {
        setDeleteRow({
          isOpen: true,
          message: "Please select From and To date",
        });
        return;
      } else {
        close();
        scavengerRef.set(objectResult, handleOnSave);
        accept();
      }
    } else {
      console.log("Not completed. ");
    }
  };

  // On Change event Handler
  const handeleRadioChange = (event) => {
    if (event.target.value === "Immediately") {
      setRadio((data.gameplayTime = event.target.value));
      setDisabled(true);
    }
    if (event.target.value === "Scheduled") {
      setRadio((data.gameplayTime = event.target.value));
      setDisabled(false);
    }
  };

  return (
    <GridContainer
      item
      xs={8}
      justify="center"
      className="create-hunt-details"
      style={{ paddingTop: 21, marginTop: 45 }}
    >
      <span className="close-button">
        <Tooltip title="close">
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Tooltip>
      </span>
      <GridItem item xs={11} style={{ marginBottom: !mission ? 40 : 0 }}>
        <div className="header-container">
          <span className="heading-text sanju">Edit Scavenger Hunt</span>
          <span className="heading-sub-text">{data.huntName}</span>
        </div>
      </GridItem>
      <div className="MuiGrid-item-837 MuiGrid-grid-xs-11-875 GridItem-grid-933">
        <FormControl
          fullWidth
          component="fieldset"
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <div style={{ paddingTop: 25, paddingBottom: 5 }}>
            <span className="sub-header-text" />
          </div>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group r-gorup"
            defaultValue="Immediately"
            value={radio}
          >
            <FormControlLabel
              value="Immediately"
              control={<Radio checked={data.gameplayTime === "Immediately"} />}
              onChange={handeleRadioChange}
              label="Immediately"
            />
            <FormControlLabel
              value="Scheduled"
              control={<Radio checked={data.gameplayTime === "Scheduled"} />}
              onChange={handeleRadioChange}
              label="Scheduled"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <FormControl
        fullWidth
        style={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: 15,
        }}
      >
        <GridItem xs={6}>
          <span style={{ paddingRight: 20 }}>From</span>
          <DateTimePicker
            disabled={disabled}
            onChange={(date) => setDate(date)}
            value={date}
            selected={data.startTime}
            required={true}
            minDate={new Date()}
          />
        </GridItem>
        <GridItem xs={6}>
          <span style={{ paddingRight: 20 }}>to</span>
          <DateTimePicker
            onChange={(endDate) => setEndDate(endDate)}
            value={endDate}
            selected={data.endTime}
            required={true}
            minDate={date ? date : new Date()}
          />
        </GridItem>
      </FormControl>

      <GridItem
        style={{ marginTop: 20, marginBottom: 20 }}
        className="button-style"
        fullWidth
      >
        <Button
          variant="contained"
          color={`danger`}
          onClick={close}
          style={{ borderRadius: 10 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"primary"}
          onClick={(e) => onHandleHuntUpdate(e, data)}
          style={{ borderRadius: 10 }}
        >
          Create
        </Button>
      </GridItem>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        message={deleteRow.message}
        open={deleteRow.isOpen}
        onClose={() => setDeleteRow({ isOpen: false, message: "" })}
        autoHideDuration={5000}
      />
    </GridContainer>
  );
};
export default EditHunt;
