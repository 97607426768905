import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import avatar from "assets/img/success.png";

import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx";
import { POI, QUIZ } from "../../constants/Common";

class LockScreenPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      shouldRedirect: false
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleContinue = () => {
    this.setState({ shouldRedirect: true });
  }

  render() {
    const { classes } = this.props;

    let routeValue= "/admin/wizard";
    
    if(this.props.location && this.props.location.params && this.props.location.params.isFrom)
    {
        switch(this.props.location.params.isFrom)
        {
            case POI:
              routeValue= "/admin/places-of-interest";
              break;
            case QUIZ:
              routeValue= "/admin/wizard";
              break;
        }
    }


    return (
 
     this.state.shouldRedirect ? <Redirect to={ routeValue }/> : 
      (<div className={classes.container}>
        <form>
          <Card
            profile
            className={
              classes.customCardClass + " " + classes[this.state.cardAnimaton]
            }
            style={{paddingTop: "50px"}}
          > 
           <div style={{marginTop: "20px"}} >
            <CardAvatar profile className={classes.cardAvatar}  >
           
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
              
            </CardAvatar>
            </div>
            <CardBody profile>
              <h4 className={classes.cardTitle}>Success!</h4>
              
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button color="info" round onClick={() => this.handleContinue()}>
                Continue
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
          )

    
    );
  }
}

LockScreenPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(lockScreenPageStyle)(LockScreenPage);
