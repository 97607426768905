import React from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocationOn from "@material-ui/icons/LocationOn";
import { DialogContent, Dialog } from "@material-ui/core";
import FullScreenMap from "../../views/Maps/FullScreenMap";
import RegularButton from "../CustomButtons/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

class PlacesInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.defaultAddress || "",
      defaultAddress: "",
      openGoogleMap: false,
      latitute: 0,
      longitude: 0,
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.defaultAddress !== prevState.defaultAddress) {
      return {
        address: nextProps.defaultAddress,
        defaultAddress: nextProps.defaultAddress,
      };
    }
  };

  handleChange = (address) => {
    this.setState({ address });
    this.props.setAddressState("error");
  };

  handleSelect = (address) => {
    let locationTitle = "";
    geocodeByAddress(address)
      .then((results) => {
        let cutOffIndex = results[0].formatted_address.indexOf(",");
        locationTitle = results[0].formatted_address.substring(0, cutOffIndex);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.props.choosePlace(
          locationTitle,
          this.state.address,
          latLng.lat,
          latLng.lng
        );
        this.props.setAddressState("success");
      })
      .catch((error) => {
        console.error("Error", error);
        this.props.setAddressState("error");
      });
    this.setState({ address });
  };

  onHandleMap = () => {
    this.setState({ openGoogleMap: true });
  };

  getLocationData = (data) => {
    this.onHandleMarkerClick(data);
    this.setState({ address: data.address });
  };

  onHandleSave = () => {
    this.setState({ openGoogleMap: false });
  };

  onHandleCancle = () => {
    this.setState({ openGoogleMap: false });
    this.props.choosePlace("", "", "", "");
    this.setState({ address: "" });
  };

  onHandleMarkerClick = (data) => {
    let cutOffIndex = data.address.indexOf(",");
    let locationTitle = data.address.substring(0, cutOffIndex);
    this.props.choosePlace(locationTitle, data.address, data.lat, data.lng);
    this.props.setAddressState("success");
  };
  render() {
    return (
      <>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <CustomInput
                labelText={<span>Search places ...</span>}
                success={this.props.descriptionState === "success"}
                error={this.props.descriptionState === "error"}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  ...getInputProps({
                    className: "location-search-input",
                  }),
                  endAdornment: this.props.classes && (
                    <InputAdornment
                      onClick={this.onHandleMap}
                      position="end"
                      className={this.props.classes.inputAdornment}
                    >
                      <LocationOn
                        className={this.props.classes.inputAdornmentIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {this.state.openGoogleMap && (
          <Dialog
            fullWidth={true}
            fullHeight={true}
            open={this.state.openGoogleMap}
            onClose={() => this.setState({ openGoogleMap: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Toolbar style={{ justifyContent: "space-between" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.onHandleCancle}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <RegularButton
                autoFocus
                onClick={this.onHandleSave}
                style={{ backgroundColor: "#00acc1" }}
              >
                save
              </RegularButton>
            </Toolbar>
            <DialogContent>
              <FullScreenMap getLocationData={this.getLocationData} />
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

export default PlacesInput;
