import React from "react";
import "../style.scss";

const TabButton = ({ name = "", handleOnClick, icon }) => {
  return (
    <div className="button-root-container" onClick={handleOnClick}>
      <span />
      {icon}
      <span className="button-text">{name}</span>
    </div>
  );
};

export default TabButton;
