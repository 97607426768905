import React from "react";
import Table from "components/Table/Table.jsx";
import Button from "@material-ui/core/Button";
import {
  Link,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import * as firebase from "firebase";

import { GeoFire } from "geofire";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AlertModal from "../AlertModal.js";
import "./style.scss";

const styles = {
  tablePhoto: {
    width: "150px",
    height: "150px",
  },
};

class PhotosTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: {},
      postScoreModal: false,
      alertModal: false,
      isValidPoint: true,
      pointsForPost: 1,
    };
    this.acceptPhoto = this.acceptPhoto.bind(this);
    this.rejectPhoto = this.rejectPhoto.bind(this);
    this.db = firebase.database();
    this.pendingPhotosRef = this.db.ref("pending-photos/");
    this.rejectedPhotosRef = this.db.ref("rejected-photos/");
    this.photosRef = this.db.ref("photos/");
    this.currentkey = "";
  }

  componentDidMount() {
    this.pendingPhotosRef.on("value", (snapshot) => {
      let photos = {};
      snapshot.forEach((child) => {
        photos[child.key] = child.val();
      });
      this.setState({ photos: photos });
    });
  }

  // Transaction docs: https://firebase.google.com/docs/reference/node/firebase.database.Reference.html#transaction
  // returning nothing will abort a transaction, returning 0 will cause it to repeat, and returning a value will overwrite it
  acceptPhoto(key) {
    this.currentkey = key;
    this.pendingPhotosRef.child(key).transaction((transactionPhoto) => {
      if (!transactionPhoto) {
        return 0;
      }
      if (transactionPhoto) {
        //this.photosRef.child(key).set(transactionPhoto);

        this.uploadPostToDatabase(transactionPhoto);

        return null;
      }
    });
  }

  handlePointsSubmit = async (key) => {
    if (this.state.pointsForPost <= 0) {
      this.setState({ isValidPoint: false });
      return;
    }

    try {
      let postRef = this.db.ref(`map-items/photos/${key}`);
      let postRefVal = await postRef.once("value");
      let userId;
      if (postRefVal) {
        userId = postRefVal.val() ? postRefVal.val().userId : "";
      } else {
        console.log("unable to take userId from profile ");
        return;
      }

      let approvedPostRef = this.db.ref(
        `user-stats/${userId}/approvedPosts/${key}/`
      );
      let userPointsRef = this.db.ref(`profiles/${userId}/points/`);

      let userPointsVal = await userPointsRef.once("value");
      let score = 0;

      if (userPointsVal && userPointsVal.val()) {
        score = parseInt(userPointsVal.val());
      }

      let postScore = parseInt(this.state.pointsForPost);

      let userPoints = score + postScore;

      //update in leaderboard
      let profileRef = this.db.ref(`profiles/${userId}/`);
      let profileData = await profileRef.once("value");
      let leaderboardRef = this.db.ref(`leaderboard/${userId}/`);

      if (profileData) {
        let data = profileData.val();
        let leaderObj = {
          username: data.username,
          color: data.color,
          points: userPoints,
        };
        leaderboardRef.set(leaderObj);
      }

      let approvedPostData = {
        userId: userId,
        postId: key,
        points: postScore,
        approvedDate: Date.now(),
      };
      approvedPostRef.set(approvedPostData);
      userPointsRef.set(userPoints);

      let userApprovedRef = this.db.ref(`photos/${userId}/${key}/points`);
      userApprovedRef.set(postScore);

      this.handleClose("postScoreModal");
      this.setState({ isScoreAdded: true });
      this.handleOpen("alertModal");
    } catch (error) {
      console.log("Error on assiging points to post", error);
      this.handleClose("postScoreModal");
      this.setState({ isScoreAdded: false });
      this.handleOpen("alertModal");
    }
  };

  uploadPostToDatabase = async (post) => {
    let newPostRef = this.db.ref(`map-items/photos/${post.id}`);

    var firebaseRef = firebase.database().ref("geo-search/");
    var geoFire = new GeoFire(firebaseRef);

    post.status = "approved";

    let geoFireResult = await geoFire.set(`i:${post.id}t:photos`, [
      parseFloat(post.lat),
      parseFloat(post.lng),
    ]);

    let userApprovedRef = this.db.ref(
      `photos/${post.userId}/${post.id}/status`
    );

    try {
      this.setState({
        successApproving: true,
        resultMessage: "Success! The photo was approved.",
      });

      userApprovedRef.set("approved");

      //Add points to post
      this.handleOpen("postScoreModal");

      return await newPostRef.set(post);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  rejectPhoto(key) {
    this.pendingPhotosRef.child(key).transaction((transactionPhoto) => {
      if (!transactionPhoto) {
        return 0;
      }
      if (transactionPhoto) {
        this.rejectedPhotosRef.child(key).set(transactionPhoto);

        let userStatusRef = this.db.ref(
          `photos/${transactionPhoto.userId}/${key}/status`
        );
        userStatusRef.set("rejected");

        return null;
      }
    });
  }

  handleOpen(modal) {
    this.setState({ [modal]: true });
  }

  handleClose(modal) {
    this.setState({ [modal]: false });
  }

  handleOnChange = (event) => {
    if (event && event.target && event.target.value) {
      this.setState({ pointsForPost: event.target.value, isValidPoint: true });
    }
  };

  render() {
    const { classes } = this.props;
    let photosList = [];
    Object.keys(this.state.photos)
      .reverse()
      .forEach((key) => {
        const photo = this.state.photos[key];
        const imageObject = (
          <Link href={photo.asset}>
            <img src={photo.asset} className={classes.tablePhoto} />
          </Link>
        );
        const feedbackObject = (
          <div className="feedback-container">
            <span>{photo.reaction === "positive" ? "+" : "-"}</span>
          </div>
        );
        const actionButtons = (
          <div>
            <Button onClick={() => this.acceptPhoto(key)}>Approve</Button>
            <Button onClick={() => this.rejectPhoto(key)}>Reject</Button>
          </div>
        );
        let photoInfo = [
          photo.username,
          photo.title,
          photo.description,
          feedbackObject,
          imageObject,
          actionButtons,
        ];
        photosList.push(photoInfo);
      });

    return (
      <div>
        <Table
          tableHeaderColor="primary"
          tableHead={[
            "User",
            "Title",
            "Description",
            "Feedback",
            "Image",
            "Action",
          ]}
          tableData={photosList}
          coloredColls={[0]}
          colorsColls={["primary"]}
        />

        <Dialog
          open={this.state.postScoreModal}
          onClose={() => this.handleClose("postScoreModal")}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">Award Points</DialogTitle>
          <DialogContent style={{ minWidth: "350px" }}>
            <CustomInput
              success={this.state.isValidPoint}
              error={!this.state.isValidPoint}
              labelText={<span>Points</span>}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: this.state.pointsForPost,
                type: "number",
                onChange: this.handleOnChange,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handlePointsSubmit(this.currentkey)}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <AlertModal
          open={this.state.alertModal}
          handleClose={() => this.handleClose("alertModal")}
          title={"Info"}
          message={
            this.state.isScoreAdded
              ? "User score has been updated"
              : "Failed to update user score"
          }
          buttonText={"OK"}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PhotosTable);
