import React from "react";

// @material-ui/core components
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormLabel,
  Snackbar,
} from "@material-ui/core";

// material ui icons
import { Check, Clear } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
// style for this view
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";

class GroupDetailsList extends React.Component {
  constructor(props) {
    super(props);
    let groupToUpdate = this.props.data || {};
    this.state = {
      shouldRefresh: false,
      enableRefresh: false,
      title: groupToUpdate.name || "",
      tableValues: (groupToUpdate && groupToUpdate.permissions) || {},
    };
  }
  getCheckBox = (values) => {
    if (values) {
      return <Check />;
    } else {
      return <Clear />;
    }
  };
  goBack = () => {
    this.props.onSubmit();
  };
  render() {
    const { classes } = this.props;
    const { tableValues } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={8} sm={8}>
          <div className={classes.wizardContainer} ref="group">
            <h3 className={classes.title}>We Are Martinsville</h3>
            <h5 className={classes.subtitle}>User Admin</h5>
            <Card className={classes.card}>
              <div className={classes.wizardHeader}>
                <h3 className={classes.title}>Groups and Functions</h3>
                <h5 className={classes.subtitle}>
                  Create a User Group and Identify Functions
                </h5>
              </div>
              <div className={classes.right}>
                <Button
                  color="danger"
                  className={this.finishButtonClasses}
                  onClick={this.goBack}
                >
                  Back
                </Button>
              </div>
              <div style={{ margin: "25px" }}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.state.title}
                </FormLabel>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Menu</TableCell>
                      <TableCell>Create</TableCell>
                      <TableCell>Read</TableCell>
                      <TableCell>Update</TableCell>
                      <TableCell>Archive</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(tableValues).map((key) => (
                      <TableRow>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          {this.getCheckBox(tableValues[key]["create"])}
                        </TableCell>
                        <TableCell>
                          {this.getCheckBox(tableValues[key]["read"])}
                        </TableCell>
                        <TableCell>
                          {this.getCheckBox(tableValues[key]["update"])}
                        </TableCell>
                        <TableCell>
                          {this.getCheckBox(tableValues[key]["archive"])}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </div>
        </GridItem>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={this.handleClose}
          open={this.state.open}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.resultMessage}</span>}
          action={[]}
        />
      </GridContainer>
    );
  }
}

export default withStyles(wizardStyle)(GroupDetailsList);
