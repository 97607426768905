import React, { Component } from "react";
import { withStyles, Paper, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
class GameCardModal extends Component {
  handleClose = () => {
    this.props.handelModalCallback();
  };

  render() {
    const { classes, open } = this.props;
    return (
      <React.Fragment>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={this.handleClose}
        >
          <Paper className={classes.paper}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  marginTop: "10px",
                  marginRight: "10px"
                }}
                onClick={this.handleClose}
              >
                <Close />
              </div>
              <div
                style={{
                  height: "40rem",
                  overflowY: "auto",
                  overflowX: "hidden",
                  marginTop: "10px"
                }}
              >
                {this.props.children}
              </div>
            </div>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  }
}

const styles = {
  paper: {
    maxWidth: "50rem",
    position: "absolute",
    left: "26vw",
    top: "6vh"
  }
};

export default withStyles(styles)(GameCardModal);
