import React from "react";

// Core Component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";

// Material Core Component
import { IconButton, Tooltip } from "@material-ui/core";

// Material UI Icon
import { Close } from "@material-ui/icons";

// Firebase function
import { approvedMission, rejectMission } from "../huntsFirebase";

const ApprovedComponent = ({ param, onClose }) => {
  const { huntName, title, asset, status } = param;
  let { children } = status.props;
  let disable = children === "Approved" || children === "Rejected";

  const onHandleApprovedMission = async (param) => {
    onClose();
    await approvedMission(param);
  };

  const onHandleRejectedMission = async (param) => {
    onClose();
    await rejectMission(param);
  };

  return (
    <GridContainer
      item
      xs={6}
      style={{ marginTop: 45 }}
      className="approved-container"
    >
      <Card>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: 30,
            paddingLeft: 30,
            paddingTop: 15,
          }}
        >
          <span className="close-button">
            <Tooltip title="Cancel">
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Tooltip>
          </span>
          <span style={{ paddingTop: 5, paddingBottom: 5 }}>
            {`Mission Proof`}
          </span>
          <Button
            style={{
              backgroundColor: `#00ACC1`,
              borderRadius: 3,
              width: "120%",
              marginLeft: "-10%",
              height: 40,
            }}
          >
            <span style={{ paddingTop: 5, paddingBottom: 5 }}>
              {`${huntName}`}
            </span>
          </Button>
          <span
            style={{ paddingTop: 5, paddingBottom: 5, fontWeight: "normal" }}
          >
            {`${title}`}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: 50,
              paddingBottom: 100,
              justifyContent: "center",
            }}
          >
            <img src={asset.props.src} style={{ height: 140, width: 120 }} />
          </div>
        </div>
        <GridItem
          item
          xs={12}
          sm={12}
          style={{ marginTop: 20, marginBottom: 20 }}
          className="button-style"
        >
          <Button
            disabled={disable}
            variant="contained"
            color={`danger`}
            onClick={() => onHandleRejectedMission(param)}
            style={{ borderRadius: 10 }}
          >
            {`Rejected`}
          </Button>
          <Button
            disabled={disable}
            variant="contained"
            color={"primary"}
            onClick={() => onHandleApprovedMission(param)}
            style={{ borderRadius: 10 }}
          >
            {`Approved`}
          </Button>
        </GridItem>
      </Card>
    </GridContainer>
  );
};
export default ApprovedComponent;
