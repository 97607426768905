import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
// @material-ui/icons
import Home from "@material-ui/icons/Home";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import * as firebase from "firebase";
import UpdateApproval from "../Forms/ApprovalForm";
import { sortingAccordingCreation } from "../../utils";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    marginRight: "auto",
  },
  titleContainer: {
    display: "flex",
  },
  actionContainer: {
    textAlign: "center",
    display: "flex",
  },
};

class ApprovalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableValues: [{}],
      updateApproval: false,
      viewApproval: false,
      approvalToUpdate: {},
      groupData: [],
      deleteConfirmationModal: false,
    };
    this.db = firebase.database();

    this.gameCardsRef = this.db.ref(`game-cards/`);
  }
  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    let dataArr = [];

    this.fetchApprovalDataByTableRef(this.gameCardsRef, dataArr);
  };

  fetchApprovalDataByTableRef = (tableReference, dataArr) => {
    tableReference.on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.val() && child.val().status !== "saved") {
          dataArr.push(this.fetchApprovalData(child.key, child.val()));
        }
      });
      sortingAccordingCreation(dataArr);
      this.setState({ tableValues: dataArr });
    });
  };

  fetchApprovalData = (key, data) => {
    return {
      timestamp: data.seriesDetails.fromDate || "",
      id: key,
      name: data.seriesDetails.name,
      description: data.seriesDetails.description,
      sponsor: data.seriesDetails.sponsor,
      dateDuration:
        new Date(data.seriesDetails.fromDate).toDateString() +
        " to " +
        new Date(data.seriesDetails.toDate).toDateString(),
      headquarter: data.seriesDetails.headquarter,
      gameMaker:
        data.gameMaker.name +
        " " +
        data.gameMaker.email +
        " " +
        data.gameMaker.organization +
        " " +
        data.gameMaker.phoneNumber,
      seriesDetails: data.seriesDetails,
      gameMakerDetails: data.gameMaker,
      eventDetails: data.eventDetails,
      status:
        data.status === "pending"
          ? "For Approval"
          : data.status === "approved"
          ? "Approved"
          : "Rejected",
      actions:
        data.status === "pending" ? (
          <div>
            <div className="actions-center">
              <Button
                onClick={() => {
                  this.setState({
                    updateApproval: true,
                    approvalToUpdate: data,
                    userId: key,
                  });
                }}
                color="warning"
                className="edit"
              >
                edit
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="actions-center">
              <Button
                onClick={() => {
                  this.setState({
                    viewApproval: true,
                    approvalToUpdate: data,
                  });
                }}
                color="primary"
                className="edit"
              >
                view
              </Button>
            </div>
          </div>
        ),
    };
  };

  onSubmit = () => {
    this.fetchList();
    this.setState({ viewApproval: false, updateApproval: false });
  };

  search = (filter, row) => {
    let rowText = String(row[filter.id]).toUpperCase();
    let searchText = filter.value.toUpperCase();
    return rowText.indexOf(searchText) > -1;
  };

  render() {
    const { classes } = this.props;
    const {
      updateApproval,
      viewApproval,
      approvalToUpdate,
      userId,
    } = this.state;
    return (
      <React.Fragment>
        {updateApproval || viewApproval ? (
          <UpdateApproval
            userId={userId}
            onSubmit={this.onSubmit}
            data={approvalToUpdate}
            viewMode={viewApproval}
          />
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <Home />
                  </CardIcon>
                  <div className={classes.titleContainer}>
                    <h4 className={classes.cardIconTitle}>Approval</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.tableValues || []}
                    filterable
                    columns={[
                      {
                        Header: "Series Name",
                        accessor: "name",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Series Descripton",
                        accessor: "description",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Series Sponsor(s)",
                        accessor: "sponsor",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Date Duration",
                        accessor: "dateDuration",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Series Headquarters",
                        accessor: "headquarter",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Game Maker",
                        accessor: "gameMaker",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        filterMethod: this.search,
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ApprovalList);
